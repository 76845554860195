import React from "react";
import { useTranslation } from "react-i18next";

// MUI
import { Box, Stack, Button, ButtonGroup } from "@mui/material";

// Recoil
import { constSelector, useRecoilValue } from "recoil";
import { userState } from "recoil/globalStates";
import { useAuth0 } from "@auth0/auth0-react";

// Values
import { baseDomain, infoEmail } from "constants/values";

export default function Footer() {
  const { isAuthenticated } = useAuth0();
  const { t, i18n } = useTranslation();
  const user = useRecoilValue(
    isAuthenticated ? userState : constSelector(null)
  );

  const termsPath =
    i18n.language === "fi" ? "fdd-kayttoehdot" : "fdd-terms-and-conditions";

  return (
    <Box
      sx={{
        backgroundColor: "background.default",
      }}
    >
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={1}
      >
        <Button
          variant="text"
          color="secondary"
          size="small"
          href={`mailto:${infoEmail}`}
          target="_blank"
          rel="noreferrer"
          sx={{ pb: 2, display: { xs: "block", sm: "none" } }}
        >
          {infoEmail}
        </Button>
        <ButtonGroup
          variant="text"
          color="secondary"
          size="small"
          sx={{ pb: 2, display: { xs: "none", sm: "block" } }}
        >
          <Button href={`mailto:${infoEmail}`} target="_blank" rel="noreferrer">
            {infoEmail}
          </Button>
          <Button
            href="/terms"
            target="_blank"
            rel="noreferrer"
          >
            {t("termsAndConditions")}
          </Button>
          <Button
            href="/privacy"
            target="_blank"
            rel="noreferrer"
          >
            {t("privacyPolicy")}
          </Button>
        </ButtonGroup>
      </Stack>
    </Box>
  );
}
