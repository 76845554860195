import React from "react";
import { useTranslation } from "react-i18next";

import { useRecoilValue } from "recoil";
import { dbStatsState } from "recoil/publicStates";

import { Box, Link, Paper, Typography, Grid } from "@mui/material";

// Images
import FDCLogo from "images/fdc_logo.png";

export default function InfoBox() {
  const { t } = useTranslation();
  const stats = useRecoilValue(dbStatsState);

  return (
    <Paper sx={{ mb: 5, p: 3 }}>
      <Grid
        container
        sx={{ mb: 6 }}
        alignItems="center"
        justifyContent="space-between"
        spacing={3}
      >
        <Grid item xs={12} sm={9}>
        <Link href="https://functionalbreeding.org" target="_blank" rel="noopener noreferrer">
           <Box
            sx={{ py: 1, height: 100 }}
            component="img"
            src={FDCLogo}
            alt="Functional Dog Collaborative logo"
          />
          </Link>
          <Typography variant="h1">{t("homeHeader")}</Typography>
          <Typography variant="body2">{t("homeText")}</Typography>
        </Grid>
      </Grid>

      <Grid container>
      {/*<Grid item xs={6} sm={2}>
          <Typography variant="body2">{t("Registries")}</Typography>
          <Typography variant="h4">{stats.organization}</Typography>
        </Grid>
        <Grid item xs={6} sm={2}>
          <Typography variant="body2">{t("dogsRegistered")}</Typography>
          <Typography variant="h4">{stats.dog}</Typography>
        </Grid>
        <Grid item xs={6} sm={2}>
          <Typography variant="body2">{t("littersRegistered")}</Typography>
          <Typography variant="h4">{stats.litter}</Typography>
        </Grid>
        <Grid item xs={6} sm={2}>
          <Typography variant="body2">{t("healthTestResults")}</Typography>
          <Typography variant="h4">{stats.healthtest}</Typography>
        </Grid>
        <Grid item xs={6} sm={2}>
          <Typography variant="body2">{t("geneticTestResults")}</Typography>
          <Typography variant="h4">{stats.geneticspanel}</Typography>
        </Grid>
        Grid item xs={6} sm={2}>
          <Typography variant="body2">{t("competitionResults")}</Typography>
          <Typography variant="h4">{stats.competitionresult}</Typography>
          </Grid> */}
      </Grid>
    </Paper>
  );
}
