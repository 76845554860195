import React from "react";

import { Grid } from "@mui/material";

// Local
import DogOwner from "../formComponents/dogOwner";
import DogInformation from "../formComponents/dogInformation";
import DogParents from "../formComponents/dogParents";
import DogRegistry from "../formComponents/dogRegistry";

export default function DogRegistrationForm() {
  return (
    <>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <DogRegistry />
        </Grid>
        <Grid item xs={12}>
          <DogOwner />
        </Grid>
        <Grid item xs={12}>
          <DogParents />
        </Grid>
        <Grid item xs={12}>
          <DogInformation />
        </Grid>
      </Grid>
    </>
  );
}
