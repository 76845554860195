import React from "react";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

// MUI
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { GeneticResultResults } from "./GeneticResultRows";
import GeneticResultSource from "./GeneticResultRows";

export default function GeneticsInfo({ dog }) {
  const { t } = useTranslation();
  const error = " ";
  const geneticTestPanels = dog.geneticresults;
  const breeds = dog.ancestry.breeds;

  const geneticsHeaders =
    geneticTestPanels && geneticTestPanels?.length > 0 ? (
      <TableRow>
        <TableCell>{t("Date Retrieved")}</TableCell>
        <TableCell>{t("source")}</TableCell>
        <TableCell>{t("results")}</TableCell>
      </TableRow>
    ) : null;

  const geneticsMarkup =
    geneticTestPanels && geneticTestPanels?.length > 0 ? (
      geneticTestPanels.map((result, index) => (
        <TableRow key={index} hover>
          <TableCell>
            {dayjs(result?.dateRetrieved).format("MM.DD.YYYY") || error}
          </TableCell>
          <TableCell>{result?.url || error}</TableCell>
          { /* <GeneticResultSource result={result} /> 
          <TableCell>
            {result?.results.map((r, i) => (
             <GeneticResultResults key={i} result={r} /> 
            ))}
          </TableCell> */ }
        </TableRow>
      ))
    ) : (
      <TableRow>
        <TableCell colSpan={4}>{t("noResults")}</TableCell>
      </TableRow>
    );

  return (
    <>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>{geneticsHeaders}</TableHead>
          <TableBody>{geneticsMarkup}</TableBody>
        </Table>
      </TableContainer>
      {dog?.breedtest?.date ? (
        <TableContainer component={Paper} sx={{ mt: 3 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell
                  colSpan={7}
                  variant="head"
                  sx={{ bgcolor: "lightGray.default" }}
                >
                  {t("breedTest")}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{t("date")}</TableCell>
                <TableCell variant="body">
                  {dog?.breedtest?.date
                    ? dayjs(dog?.breedtest?.date).format("MM.DD.YYYY")
                    : error}
                </TableCell>
                <TableCell>{t("laboratory")}</TableCell>
                <TableCell variant="body">
                  {dog?.breedtest?.source || error}
                </TableCell>
                <TableCell>{t("additionalInfo")}</TableCell>
                <TableCell variant="body">
                  {dog?.breedtest?.additionalInfo || " "}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell colSpan="3" variant="head">
                  {t("breed")}
                </TableCell>
                <TableCell colSpan="4" variant="head">
                  {t("percentage")}
                </TableCell>
              </TableRow>
              {!!breeds &&
                breeds.map((b, i) => (
                  <TableRow key={i} hover>
                    <TableCell colSpan="3">
                      {b.rotu ? b.rotu : b.name ? b.name : error}
                    </TableCell>
                    <TableCell colSpan="4">
                      {b.tulos ? b.tulos : b.percentage ? b.percentage : error}{" "}
                      %
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : null}
    </>
  );
}
