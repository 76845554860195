import React, { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useTranslation } from "react-i18next";

// Components
import MyDogsList from "components/usersOwnedDogsComponents/myDogsList";
import DogView from "components/usersOwnedDogsComponents/dogView";

// MUI
import { Grid, Paper, Divider, Typography, Box, Button } from "@mui/material";
import DrawerMenu from "components/usersOwnedDogsComponents/drawerMenu";
import { ChevronRight } from "@mui/icons-material";
import { useRecoilValue } from "recoil";
import { usersDogsState } from "recoil/privateStates";

function MyDogs() {
  const { t } = useTranslation();
  const { isAuthenticated } = useAuth0();

  //const [sortingAsc, setSortingAsc] = useState(true);
  const [drawerOpen, setDrawerOpen] = useState(false);

  const ownedDogs = useRecoilValue(usersDogsState);
  const [selectedDog, setSelectedDog] = useState(ownedDogs[0]);

  // if dog is selected in child component, do this
  const handleSelection = (dog) => {
    setSelectedDog(dog);
    setDrawerOpen(false);
  };

  /*   const handleSort = () => {
    let sortedDogs = [];
    if (sortingAsc) {
      setSortingAsc(false);
      sortedDogs = sortDogsByCreationDate(ownedDogs, false);
    } else {
      setSortingAsc(true);
      sortedDogs = sortDogsByCreationDate(ownedDogs, true);
    }
    setOwnedDogs(sortedDogs);
  }; */

  return (
    <>
      {isAuthenticated && (
        <>
          <Box display={{ xs: "flex", sm: "flex", md: "none" }} sx={{ mb: 4 }}>
            <DrawerMenu
              dogs={ownedDogs}
              handleSelection={handleSelection}
              /*               sortingAsc={sortingAsc}
              handleSort={handleSort} */
              open={drawerOpen}
              setOpen={setDrawerOpen}
            />
          </Box>
          <Box display={{ xs: "none", sm: "none", md: "block" }}>
            <Divider textAlign="left" sx={{ mb: 4 }}>
              <Typography variant="h2">{t("myDogs")}</Typography>
            </Divider>
          </Box>
          {ownedDogs?.length ? (
            <Paper>
              <Grid
                container
                direction={{ xs: "column", sm: "row" }}
                justifyContent="center"
              >
                <Grid
                  item
                  xs={12}
                  md={4}
                  sx={{
                    display: { xs: "none", sm: "none", md: "block" },
                  }}
                >
                  {/* <Button
                      endIcon={sortingAsc ? <UpIcon /> : <DownIcon />}
                      onClick={handleSort}
                    >
                      {sortingAsc ? t("sortAscending") : t("sortDescending")}
                    </Button> */}
                  <MyDogsList
                    dogs={ownedDogs}
                    handleSelection={handleSelection}
                  />
                </Grid>
                <Grid item xs={12} md={8}>
                  <DogView selectedDog={selectedDog} />
                </Grid>
              </Grid>
            </Paper>
          ) : ownedDogs.length === 0 ? (
            <>
              <Paper sx={{ p: 2 }}>
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  flexDirection="column"
                >
                  <Typography
                    variant="body"
                    align="center"
                    sx={{ p: 2, display: "block" }}
                  >
                    {t("No dogs found")}
                  </Typography>
                  <Button
                    variant="text"
                    endIcon={<ChevronRight />}
                    href="/dog-registration"
                  >
                    {t("registerYourFirstDog")}
                  </Button>
                </Box>
              </Paper>
            </>
          ) : (
            <>
              <Typography
                variant="body"
                align="center"
                sx={{ p: 2, display: "block" }}
              >
                {t("loading")}
              </Typography>
            </>
          )}
        </>
      )}
    </>
  );
}

export default MyDogs;
