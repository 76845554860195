import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

// Recoil
import { useRecoilValue } from "recoil";
import { organizationsState } from "recoil/publicStates";

import {
  Typography,
  Divider,
  Paper,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import { getOrganizationById } from "api/publicRoutes";
import { Business } from "@mui/icons-material";

export default function OrganizationsData({ userPerson }) {
  const { t } = useTranslation();
  const orgs = userPerson.organizations;

  return (
    <>
      <Paper sx={{ mt: 2 }}>
        <List>
          <ListItem>
            <ListItemText>
              <Typography
                variant="h3"
                sx={{ display: "flex", alignItems: "flex-start" }}
              >
                <Business sx={{ mr: 1 }} /> {t("organizations")}
              </Typography>
            </ListItemText>
          </ListItem>
          <Divider />
          {orgs && orgs.length > 0 ? (
            orgs.map((org, i) => (
                            <ListItem key={i}>
                <ListItemText>
                  <Link to={`/organization/${org?.organization?.prefix}`}
                        target="_blank"
                        rel="noopener noreferrer">
                    {org?.organization?.name}
                  </Link>
                </ListItemText>
                <ListItemText>Role: {org?.role}</ListItemText>
              </ListItem>
            ))
          ) : (
            <Typography variant="body" sx={{ p: 2 }}>
              {t("noOrganizations")}
            </Typography>
          )}
        </List>
      </Paper>
    </>
  );
}
