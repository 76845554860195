import React, { useState } from "react";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";

// Recoil
import {
  useRecoilState,
  useRecoilValue,
  useSetRecoilState,
} from "recoil";
import {
  accessTokenState,
  loadingState,
  resultProvidersState,
  snackBarState,
} from "recoil/globalStates";

// MUI
import {
  Divider,
  Grid,
  TextField,
  Typography,
} from "@mui/material";

// Components
import AdminAttachmentList from "components/adminDashboardComponents/adminAttachmentList";

function AdminDogVerification ({
  dog,
  formMethods
}) {

  const { t, i18n } = useTranslation();

  const accessToken = useRecoilValue(accessTokenState);
  const setLoading = useSetRecoilState(loadingState);
  const [initialDog, setInitialDog] = useState(dog);

  const {
    control,
    watch,
    setValue,
    formState: { errors, isSubmitting, isDirty },
  } = formMethods;

  const formdata = watch();
 
  return (
    <Grid container direction="row" spacing={3} alignItems="flex-start">

      {/* ATTACHMENTS and PROFILE PHOTO */ }

      <Grid item xs={12}>
        <Divider textAlign="left" sx={{ my: 2 }}>
          <Typography variant="h5">{t("Attachments")}</Typography>
        </Divider>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={true} container justifyContent="flex-start">
            <AdminAttachmentList parentDoc={initialDog} accessToken={accessToken} setValue={setValue} />
          </Grid>
        </Grid>
      </Grid>

      {/* NOTES */ }

      <Grid item xs={12}>
        <Divider textAlign="left" sx={{ my: 2 }}>
          <Typography variant="h5">{t("Notes")}</Typography>
        </Divider>
        <Grid item xs={12} sm={6} sx={{ mb: 2 }}>
          <Controller
          name="additionalInfo"
          control={control}
          defaultValue={initialDog?.additionalInfo || ""}
          render={({ field }) => (
            <TextField
              {...field}
              label="Notes"
              multiline
              rows={4}
              fullWidth
              variant="outlined"
              value={field.value}
            />
          )}
        />
        </Grid>
      </Grid>
    </Grid> );
}

export default AdminDogVerification;
