import React from "react";
import { useAuth0 } from "@auth0/auth0-react";

import { useTranslation } from "react-i18next";

// Recoil
import { useRecoilValue } from "recoil";
import {
  usersDogsState,
  usersPendingDogsState,
  usersPendingLittersState,
} from "recoil/privateStates";

// MUI
import { Grid, Paper, Typography, Button, Stack } from "@mui/material";

// Local
import PendingLittersList from "components/usersRegistrationsComponents/pendingLittersList";
import PendingDogsList from "components/usersRegistrationsComponents/pendingDogsList";

function Registrations() {
  const { t } = useTranslation();
  const { isAuthenticated } = useAuth0();

  const dogs = useRecoilValue(usersDogsState);
  const pendingDogs = useRecoilValue(usersPendingDogsState);
  const pendingLitters = useRecoilValue(usersPendingLittersState);

  return (
    <>
      {isAuthenticated && (
        <Paper sx={{ p: 3 }}>
          <Grid
            container
            direction={{ xs: "column", sm: "row" }}
            spacing={{ xs: 2, md: 4 }}
            justifyContent="center"
          >
            <Grid item xs={12} sm={6}>
              <Typography variant="h2">{t("registrationStatuses")}</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Stack
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                spacing={3}
              >
                <Button
                  href="/litter-registration"
                  variant="outlined"
                  color="primary"
                >
                  {t("registerLitter")}
                </Button>
                <Button
                  href="/dog-registration"
                  variant="outlined"
                  color="primary"
                >
                  {t("registerDog")}
                </Button>
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <PendingLittersList pendingLitters={pendingLitters} />
            </Grid>
            <Grid item xs={12}>
              <PendingDogsList pendingDogs={pendingDogs} />
            </Grid>
            <Grid item xs={12}>
              { /* TODO: <PendingDogsList pendingDogs={pendingDogs} /> */ }
            </Grid>
          </Grid>
        </Paper>
      )}
    </>
  );
}

export default Registrations;
