import { Alert, AlertTitle } from "@mui/material";
import React from "react";
export function AdminNotFound({ docType }) {
  return (
    <Alert severity="info">
      <AlertTitle>{docType} not found</AlertTitle>
      {
        "The document you are looking for was not found. It either doesn't exist or you don't have sufficient permissions to access it."
      }
    </Alert>
  );
}
