import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useAuth0 } from "@auth0/auth0-react";

import {
  Controller,
  useForm,
} from "react-hook-form";

// Recoil
import { constSelector, useRecoilValue } from "recoil";
import { userState } from "recoil/globalStates";

import {
  Box,
  Button,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TextField,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Add, Delete, Edit, Launch } from "@mui/icons-material";

function NewPuppyForm({ puppy, setPuppiesMod, index }) {
  const { t } = useTranslation();
  const methods = useForm({ mode: "all" });
  const { control } = methods;

  return (
    <>
      <TableCell>
        <Controller
          name={`puppies[${index}].microchipNumber`}
          control={control}
          defaultValue={puppy.microchipNumber}
          render={({ field }) => (
            <TextField
              {...field}
              fullWidth
              label="Microchip Number"
            />
          )}
        />
      </TableCell>
      <TableCell>
        <Controller
          name={`puppies[${index}].name`}
          control={control}
          defaultValue={puppy.name}
          render={({ field }) => (
            <TextField
              {...field}
              fullWidth
              label="Name"
            />
          )}
        />
      </TableCell>
      <TableCell>
        <Controller
          name={`puppies[${index}].sex`}
          control={control}
          defaultValue={puppy.sex}
          render={({ field }) => (
            <RadioGroup {...field} row>
              <FormControlLabel
                value="male"
                control={<Radio />}
                label="Male"
              />
              <FormControlLabel
                value="female"
                control={<Radio />}
                label="Female"
              />
            </RadioGroup>
          )}
        />
      </TableCell>
    </>
  );
};

function ExistingPuppy ({ puppy, index, removePuppy }) {
  const { t } = useTranslation();
  return (
    <>
  <TableCell>{puppy.microchipNumber}</TableCell>
  <TableCell>{puppy.name}</TableCell>
  <TableCell>{t(puppy.sex)}</TableCell>
  </>
  );
};


export default function PuppiesList({ litter, formType }) {
  const { t } = useTranslation();
  const { isAuthenticated } = useAuth0();
  const user = useRecoilValue(
    isAuthenticated ? userState : constSelector(null)
  );
  const puppies = litter.puppies;
  const orgAndMore = litter.organizations?.[0]; //FIXME

  const [puppiesMod, setPuppiesMod] = useState(puppies || []);

const addPuppy = () => {
  const newPuppy = { id: Date.now(), new: true, name: null, microchipNumber: null, sex: "male" };
  setPuppiesMod([...puppiesMod, newPuppy]);
};

const removePuppy = (index) => {
  const updatedPuppies = puppiesMod.filter((_, i) => i !== index);
  setPuppiesMod(updatedPuppies);
};

  return (
    <>

    {puppies?.length > 0 ? (
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Edit</TableCell>
              <TableCell>View</TableCell>
              <TableCell>Microchip no</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Sex</TableCell>
              {formType === "tempLitter" && <TableCell>Remove</TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {puppiesMod.map((puppy, i) => (
              <TableRow key={i}>
                <TableCell>
                  <IconButton
                    href={`/admin/dog/${puppy._id}`}
                    target="_blank"
                  >
                    <Edit />
                  </IconButton>
                </TableCell>
                <TableCell>
                  {orgAndMore && (
                    <IconButton
                      href={`/${orgAndMore.organization.prefix}/dog/${puppy._id}`}
                      target="_blank"
                    >
                      <Launch />
                    </IconButton>
                  )}
                </TableCell>
                {puppy.new ?
                   <NewPuppyForm puppy={puppy} setPuppiesMod={setPuppiesMod} index={i} /> :
                   <ExistingPuppy puppy={puppy} index={i} removePuppy={removePuppy} />
                }
                {formType === "tempLitter" || user?.role === "admin" ? (
                  <TableCell>
                    <IconButton onClick={() => removePuppy(i)}>
                      <Delete />
                    </IconButton>
                  </TableCell>
                ) : null}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    ) : (
      <Typography variant="body">No puppies</Typography>
    )}
    { /* 
    <Box display="flex" justifyContent="flex-end" mt={2}>
      <Button
        variant="outlined"
        startIcon={<Add />}
        onClick={addPuppy}
      >
        {t("addPuppy")}
      </Button>
    </Box> */ }
  </>
  
);
}
