import React from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

// Recoil
import { getOrgByPrefixState, organizationLittersState } from "recoil/publicStates";
import { useRecoilValue } from "recoil";

// MUI
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Typography, Paper } from "@mui/material";

// Local
import { litterColumns } from "components/datagridColumns/LitterColumns";
import OrganizationInfoBox from "components/organizationComponents/OrganizationInfoBox";

export default function LitterList() {
  const { t } = useTranslation();

  const { organizationPrefix } = useParams();
  const organization = useRecoilValue(getOrgByPrefixState(organizationPrefix));

  if (!organization) {
    return (
      <Paper square sx={{ p: 2 }}>
        <Typography variant="body">{t("No organization found with abbreviation")} <i>{organizationPrefix}</i></Typography>
      </Paper>
    );
  }

  const litters = useRecoilValue(organizationLittersState(organization));

  const handleRowClick = (params) => {
    window.location = `/${organization.prefix}/litter/${params.row._id}`;
  };

  return (
    <>
    <OrganizationInfoBox org={organization}/>
    
    <DataGrid
      autoHeight
      pageSize={50}
      getRowId={(row) => row._id}
      onRowClick={handleRowClick}
      rows={litters}
      columns={litterColumns}
      components={{ Toolbar: GridToolbar }}
      disableSelectionOnClick
    />
    </>
  );
}
