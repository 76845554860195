import React, { useState } from "react";

import { getCertificatesColumns } from "components/adminDashboardComponents/adminDatagridColumns";

// Components
import FormModal from "components/adminDashboardComponents/formModal";

// MUI
import { Typography, Grid, Button, Box, Stack } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Add from "@mui/icons-material/Add";

import CertificateForm from "../forms/certificateForm";

import { adminAllCertsState } from "recoil/adminStates";
import { useRecoilValue } from "recoil";
import { useTranslation } from "react-i18next";

function AdminAllCertificates() {
  const { t } = useTranslation();

  const [modalOpen, setModalOpen] = useState(false);

  const results = useRecoilValue(adminAllCertsState);

  const handleRowClick = (params) => {
    window.location = `/admin/certificate/${params.row._id}`;
  };

  const handleModalOpen = () => {
    setModalOpen(true);
  };
  const handleModalClose = () => {
    if (confirm(t("confirmModalClose"))) {
      setModalOpen(false);
    }
  };

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Stack
            direction={{ xs: "column", sm: "row" }}
            justifyContent={{ xs: "center", sm: "space-between" }}
            alignItems="center"
            alignContent={"center"}
            spacing={{ xs: 2, sm: 0 }}
          >
            <Typography variant="h2">Certificates</Typography>
            <Button
              variant="outlined"
              startIcon={<Add />}
              onClick={handleModalOpen}
            >
              Add Certificate
            </Button>
          </Stack>
        </Grid>
        <Grid item xs={3}>
          <Box display="flex" justifyContent="flex-end"></Box>
        </Grid>
        <Grid item xs={12}>
          <DataGrid
            autoHeight
            pageSize={50}
            onRowClick={handleRowClick}
            rows={results}
            getRowId={(row) => row._id}
            columns={getCertificatesColumns("certificate")}
            components={{ Toolbar: GridToolbar }}
            disableSelectionOnClick
          />
        </Grid>
      </Grid>
      <FormModal open={modalOpen} onClose={handleModalClose}>
        <CertificateForm action="add" handleModalClose={handleModalClose} />
      </FormModal>
    </>
  );
}

export default AdminAllCertificates;
