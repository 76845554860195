import { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { atom, selector, useRecoilValue } from "recoil";

import { getCurrentUser } from "api/privateRoutes";
import { getKruvalues, getResultProviders } from "api/publicRoutes";

export const snackBarState = atom({
  key: "snackBarState",
  default: {
    message: "",
    severity: "success",
    open: false,
  },
});

export const loadingState = atom({
  key: "loadingState",
  default: false,
});

export const showSubmitState = atom({
  key: "showSubmitState",
  default: false,
});

// KRUVALUES
export const kruValuesState = selector({
  key: "kruValuesState",
  get: async () => {
    const options = { limit: 0 };
    const res = await getKruvalues(options);
    return res;
  },
});

// ResultProviders
export const resultProvidersState = selector({
  key: "resultProvidersState",
  get: async () => {
    const options = { limit: 0 };
    const res = await getResultProviders(options);
    return res;
  },
});

// CURRENT USER & ACCESS TOKEN
export const accessTokenForCurrentUserState = selector({
  key: "accessTokenForCurrentUserState",
  get: async () => {
    const { getAccessTokenSilently, loginWithRedirect } = useAuth0();
    const authorizationParams = {
      audience: `${process.env.REACT_APP_AUTH_DOMAIN}/api/v2/`,
      scope: "read:current_user",
    };
    try {
      return await getAccessTokenSilently();
    } catch (e) {
      loginWithRedirect({ authorizationParams });
    }
  },
});

export const accessTokenState = atom({
  key: "accessTokenState",
  default: accessTokenForCurrentUserState,
});

export const userState = selector({
  key: "userState",
  get: async ({ get }) => {
    const accessToken = get(accessTokenState);
    const response = await getCurrentUser({ accessToken });
    if (response.error) {
      throw response;
    }
    return response;
  },
});

// If user has logged in, return the user; else return null
export function useAuthenticatedUser() {
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();
  const [authenticatedUser, setAuthenticatedUser] = useState(null);

  useEffect(() => {
    const fetchUserData = async () => {
      if (isAuthenticated) {
        try {
          const accessToken = await getAccessTokenSilently();
          const authenticatedUser = await getCurrentUser({ accessToken });
          setAuthenticatedUser(authenticatedUser);
        } catch (error) {
          console.error("Error fetching user data:", error);
          setAuthenticatedUser(null);
        }
      } else {
        setAuthenticatedUser(null);
      }
    };

    fetchUserData();
  }, [isAuthenticated, getAccessTokenSilently]);

  return authenticatedUser;
}
