import React, { Component } from "react";
import dayjs from "dayjs";
import i18next from "i18next";
import { styled } from "@mui/material/styles";
// Icons
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import FemaleIcon from "@mui/icons-material/Female";
import MaleIcon from "@mui/icons-material/Male";

// MUI
import {
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  ListItemButton,
  Divider,
  Collapse,
  Chip,
  Paper,
  Typography,
} from "@mui/material";

const StyledListButton = styled(ListItemButton)(({ theme }) => ({
  padding: 0,
}));

export default class ListLitterCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
  }

  handleClick = () => {
    if (this.state.open === false) {
      this.setState({
        open: true,
      });
    } else {
      this.setState({
        open: false,
      });
    }
  };

  render() {
    const t = i18next.t;
    const {
      litter: { dam, sire, dateOfBirth, puppies = [] },
    } = this.props;

    const uroksia = puppies.reduce(
      (total, puppy) => (total += puppy.sex === "male"),
      0
    );
    const narttuja = puppies.reduce(
      (total, puppy) => (total += puppy.sex === "female"),
      0
    );

    // Mapping each puppy for the dropdown menu items
    const puppiesMarkup = puppies.map((puppy, i) => (
      <div key={i}>
        <StyledListButton onClick={() => this.props.handleSelection(puppy)}>
          <ListItem>
            <ListItemAvatar sx={{ pr: 2 }}>
              <Chip
                icon={puppy?.sex === "female" ? <FemaleIcon /> : <MaleIcon />}
                label={t(puppy?.sex)}
                variant="outlined"
              />
            </ListItemAvatar>
            <ListItemText primary={puppy?.name} secondary={puppy?.regNumber} />
          </ListItem>
        </StyledListButton>
        <Divider />
      </div>
    ));

    return (
      <div>
        <StyledListButton onClick={() => this.handleClick()}>
          <ListItem
            secondaryAction={
              <Typography
                variant="body2"
                sx={{ display: "flex", alignItems: "center" }}
              >
                {dayjs(dateOfBirth).format("MM.DD.YYYY") || "?"}
                {this.state.open ? <ExpandLess /> : <ExpandMore />}
              </Typography>
            }
          >
            <ListItemText
              primary={
                <Typography variant="h6">
                  {sire?.name || t("noData")} <br />
                  {dam?.name || t("noData")}
                </Typography>
              }
              secondary={
                <Typography variant="caption">
                  {`${uroksia || 0} ${t("males").toLowerCase()}, ${
                    narttuja || 0
                  }
                  ${t("females").toLowerCase()}`}
                </Typography>
              }
            />
          </ListItem>
        </StyledListButton>
        <Divider />

        <Collapse in={this.state.open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {puppiesMarkup}
          </List>
        </Collapse>
      </div>
    );
  }
}
