import React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";

// Recoil
import { useRecoilValue, useResetRecoilState } from "recoil";
import { litterByIdState } from "recoil/publicStates";
import { kruValuesState } from "recoil/globalStates";
import { litterRegistrationState } from "recoil/registrationStates";

// MUI
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import {
  Paper,
  Typography,
  Box,
  IconButton,
  TableBody,
  TableRow,
  TableCell,
  Table,
  TableContainer,
} from "@mui/material";
import { CheckCircleOutlined, Launch } from "@mui/icons-material";

// Local
import { displayDeclaredTypes, getKruValueLabel } from "constants/helpers";
import { countries } from "constants/countries";

export default function LitterPaymentSuccess() {
  const { t, i18n } = useTranslation();
  const { id } = useParams();
  const language = i18n.language;
  const kruValues = useRecoilValue(kruValuesState);
  const litter = useRecoilValue(litterByIdState(id));
  const resetFormData = useResetRecoilState(litterRegistrationState);

  resetFormData();


  return (
    <Paper sx={{ p: 4 }}>
      <Grid container alignItems="center" spacing={6}>
        <Grid item xs={12} sm={9}>
          <Typography variant="h2" sx={{ mb: 2 }}>
	      { litter.__t === "Pending" ?
      		t("Litter registration submitted") :
		      t("Litter registration successful") }
          </Typography>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Box display="flex" justifyContent="center">
            <CheckCircleOutlined
              color="primary"
              sx={{
                fontSize: { xs: "80px", sm: "100px" },
                textAlign: "center",
              }}
            />
          </Box>
        </Grid>
        {litter && (
          <Grid item xs={12}>
            <Typography variant="h3">{t("summary")}</Typography>
            <TableContainer>
              <Table sx={{ minWidth: 200 }}>
                <TableBody>
                  <TableRow>
                    <TableCell variant="head">{t("registry")}</TableCell>
                    <TableCell>
                      {litter.organizations[0]?.organization.name || ""}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell variant="head">{t("Litter name")}</TableCell>
                    <TableCell>{litter.name || "unnamed"}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell variant="head">{t("dob")}</TableCell>
                    <TableCell>
                      {dayjs(litter.dateOfBirth).format("MM.DD.YYYY") || ""}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell variant="head">{t("countryOfOrigin")}</TableCell>
                    <TableCell>
                      {countries.find(
                        (c) => c.code === litter.puppies[0].countryOfOrigin
                      )[language] || ""}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell variant="head">{t("sire")}</TableCell>
                    <TableCell>
                      {litter.sire.name || ""} {litter.sire.regNumber || ""}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell variant="head">{t("dam")}</TableCell>
                    <TableCell>
                      {litter.dam.name || ""} {litter.dam.regNumber || ""}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                      <TableCell variant="head">{t("Breed or mix")}</TableCell>
                      <TableCell>
                        {displayDeclaredTypes(litter, kruValues, language)}
                      </TableCell>
                    </TableRow>
                  <TableRow>
                    <TableCell colSpan={4} variant="head">
                      {t("puppies2")}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
              <Table>
                <TableBody>
                  {litter.puppies.map((puppy, i) => (
                    <TableRow key={i}>
                      <TableCell>{puppy.name || ""}</TableCell>
                      <TableCell>{puppy.microchipNumber || ""}</TableCell>
                      <TableCell>{t(puppy.sex) || ""}</TableCell>
                      <TableCell>{puppy.organizations[0].regNumber || "registration pending"}</TableCell>
                      <TableCell>
                      {puppy.organizations[0].regNumber &&
                       <IconButton
                        href={`/${litter.organizations[0].organization.prefix}/dog/${puppy.organizations[0].regNumber}`}
                        target="_blank">
                          <Launch />
                        </IconButton> }
                      </TableCell>
                      <TableCell>{puppy.coat.color ? getKruValueLabel(puppy.coat.color, language) : "-"}</TableCell>
                      <TableCell>
                        {puppy.coat.modifiers?.length > 0 ? puppy.coat.modifiers.map((value, i) => {
                          return (
                            getKruValueLabel(value, language) +
                            (puppy.coat.modifiers.length - 1 === i ? "" : ", ")
                          );
                         }) : "-"}
                      </TableCell>
                      <TableCell>
                      {puppy.coat.markings?.length > 0 ? puppy.coat.markings.map((value, i) => {
                          return (
                            getKruValueLabel(value, language) +
                            (puppy.coat.markings.length - 1 === i ? "" : ", ")
                          );
                         }) : "-"}
                      </TableCell>
                      <TableCell>{puppy.coat.type ? getKruValueLabel(puppy.coat.type, language) : "-"}</TableCell>
                      <TableCell>
                      {puppy.coat.qualities?.length > 0 ? puppy.coat.qualities.map((value, i) => {
                          return (
                            getKruValueLabel(value, language) +
                            (puppy.coat.qualities.length - 1 === i ? "" : ", ")
                          );
                         }) : "-"}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        )}
      </Grid>
    </Paper>
  );
}
