import React from "react";
import { Autocomplete, TextField } from "@mui/material";
import { Controller } from "react-hook-form";

export default function ParentAutocomplete({
  label,
  name,
  options,
  value,
  control,
}) {
  return (
    <Controller
      name={name}
      render={({ field: { onChange, ...props } }) => (
        <Autocomplete
          fullWidth
          autoComplete
          options={options}
          getOptionLabel={(option) => `${option.name} ${option.regNumber || ""}`}
          renderOption={(props, option) => (
            <li {...props} key={option._id}>
              {option.name} {option.regNumber}
            </li>
          )}
          isOptionEqualToValue={(option, value) => option._id == value._id}
          noOptionsText={"no data"}
          renderInput={(params) => <TextField {...params} label={label} />}
          onChange={(e, data) => onChange(data)}
          {...props}
        />
      )}
      onChange={([, data]) => data}
      defaultValue={value || null}
      control={control}
    />
  );
}
