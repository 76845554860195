import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { Auth0ProviderWithNavigate } from "./auth0";
import "./i18n/i18n";

// Theming
import { CssBaseline } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./theme";
import "./index.css";

// MUI DatePicker Localization
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

// App component
import App from "./App";
import { RecoilRoot } from "recoil";

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <RecoilRoot>
      <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <BrowserRouter>
            <Auth0ProviderWithNavigate>
              <CssBaseline />
              <App />
            </Auth0ProviderWithNavigate>
          </BrowserRouter>
        </LocalizationProvider>
      </ThemeProvider>
    </RecoilRoot>
  </React.StrictMode>
);
