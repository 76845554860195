import React, { useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

// Recoil
import { useRecoilState, useRecoilValue } from "recoil";
import { userState } from "recoil/globalStates";
import { stripeOrganizations } from "recoil/publicStates";
import { dogRegistrationState } from "recoil/registrationStates";

// MUI
import {
  Button,
  Grid,
  Divider,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { Launch } from "@mui/icons-material";

// Local
import { compareRole, createHandleInputChange, getUserOrg } from "constants/helpers";

export default function dogRegistry() {
  const user = useRecoilValue(userState);
  const { t } = useTranslation();
  const organizations = useRecoilValue(stripeOrganizations);
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const [formData, setFormData] = useRecoilState(dogRegistrationState);
  const registry = formData.registry;

  const handleInputChange = createHandleInputChange(setFormData);

  useEffect(() => {
    getUserOrg(user) && setFormData({ ...formData, registry: getUserOrg(user) });
  }, []);

  if (!compareRole(user.role, "manager") && user.organizations.length != 1) {
    throw new Error(
      "Registration currently only supports users belonging to a single organization."
    );
  }
  
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sx={{ mb: 2 }}>
        <Divider textAlign="left" sx={{ mb: 2 }}>
          <Typography variant="h2">{t("registry")}</Typography>
        </Divider>
      </Grid>
      <Grid item xs={6} sx={{ mb: 2 }}>
        <FormControl fullWidth required>
          <InputLabel id="registry-label">{t("registry")}</InputLabel>
          <Controller
            name="registry"
            control={control}
            defaultValue={formData.registry}
            rules={{
              required: true,
            }}
            render={({ field }) => (
              <Select
                {...field}
                labelId="registry-label"
                id="registry-select"
                disabled={user.role !== "admin"}
                label={t("registry")}
                value={formData.registry}
                onChange={(e) => handleInputChange(e, field)}
                renderValue={(selected) => (selected ? selected.name : "")}
                error={errors.registry && true}
              >
                {organizations.map((org) => (
                  <MenuItem key={org._id} value={org}>
                    {org.name}
                  </MenuItem>
                ))}
              </Select>
            )}
          />
          {errors.registry && (
            <FormHelperText error>{t("required")}</FormHelperText>
          )}
        </FormControl>
        </Grid>
      <Grid item xs={6} sx={{ mb: 2 }}>
        {registry && (
          <Button href={registry.url} target="_blank" endIcon={<Launch />}>
            {t("registryGuidelines")}
          </Button>
        )}
      </Grid>
    </Grid>
  );
}
