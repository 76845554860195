import React from "react";
import i18next from "i18next";

// MUI
import { Typography, Paper, Link, Stack } from "@mui/material";
import DogAvatar from "elements/DogAvatar";
import { getLinkDisplay, getOrgWithReg } from "constants/helpers";
// import { gridColumnsTotalWidthSelector } from "@mui/x-data-grid";

// NOTE: will need tested when we have dogs in multiple orgs but not in the current org
export const imageNode = ({ node, organization, allOrganizations }) => {
  const { nodeDatum, toggleNode } = node;
    const thisOrg = getOrgWithReg(nodeDatum.organizations, organization);

    let regLinkDisplays = thisOrg ?
          [getLinkDisplay(nodeDatum, organization)] :
          nodeDatum.organizations.map(org => getLinkDisplay(nodeDatum, org.organization));

     // Populate org prefix(es) for external dogs. 
      if (! thisOrg) {
        regLinkDisplays = regLinkDisplays.map((regLinkDisplay) => {
          if (!regLinkDisplay.prefix) {
            let externalOrg = allOrganizations.find(org => org._id === regLinkDisplay.org);
            regLinkDisplay.prefix = externalOrg.prefix;
          }
          return regLinkDisplay;
        });
      }

    return (
      <foreignObject x="-40" y="-40" width="200" height="100">
        <Paper>
          <Stack direction="row" alignItems="center" sx={{ p: 1 }}>
            <DogAvatar
              primaryImage={nodeDatum.primaryImage}
              dogId={nodeDatum._id}
              alt={nodeDatum.name}
            />
            <Stack direction="column">
                {thisOrg ? (
                <>
                  <Typography
                    component={Link}
                    href={`/${regLinkDisplays[0].prefix}/dog/${regLinkDisplays[0].link}`}
                    variant="h5"
                    sx={{ ml: 1, fontSize: "0.8rem" }}
                  >
                    {nodeDatum.name}
                  </Typography>
                  <Typography variant="caption" sx={{ ml: 1 }}>
                    {regLinkDisplays[0].display}
                  </Typography>
                </>
              ) : (
                <>
                  <Typography variant="h5" sx={{ ml: 1, fontSize: "0.8rem" }}>
                    {nodeDatum.name} (external)
                  </Typography>
                  {regLinkDisplays.map((regLinkDisplay, i) => (
                    <Typography
                      key={i}
                      component={Link}
                      href={`/${regLinkDisplay.prefix}/dog/${regLinkDisplay.link}`}
                      variant="caption"
                      sx={{ ml: 1 }}
                    >
                      {regLinkDisplay.display}
                    </Typography>
                  ))}
                </>
              )}
            </Stack>
          </Stack>{" "}
        </Paper>
      </foreignObject>
    );
  };

export const smallImageNode = ({ node, organization }) => {
  
  const { nodeDatum, toggleNode } = node;
  const thisOrg = getOrgWithReg(nodeDatum.organizations, organization);
  let regLinkDisplay = thisOrg ? getLinkDisplay(nodeDatum, organization) : null;

  return (
  <foreignObject x="0" y="0" width="110" height="200">
    <Stack direction="column">
      <Typography variant="h5" sx={{ ml: 1, fontSize: "0.8rem" }}>
        {nodeDatum.name}
      </Typography>
      <Typography variant="caption" sx={{ ml: 1 }}>
        {regLinkDisplay?.display || ""}
      </Typography>
    </Stack>
  </foreignObject>
);
};
