import React from "react";
import { Button, Stack, Typography } from "@mui/material";

export default function AcceptButton({
  action,
  formType,
  pendingRegistration,
  handleAccept,
}) {

  return (
    pendingRegistration && (
      <>
        <Stack
          direction={{ xs: "column", sm: "row" }}
          justifyContent="flex-end"
          alignItems="center"
          spacing={{ xs: 1, sm: 3 }}
        >
          {pendingRegistration &&
           <Button variant="contained" onClick={handleAccept}>
             Accept registration
           </Button>
          }
        </Stack>
      </>
    )
  );
}
