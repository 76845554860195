import React from "react";
import { styled } from "@mui/material/styles";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

// import { populatedOrgsArrayState } from "recoil/publicStates";
// import { useRecoilValue } from "recoil";

// MUI
import Typography from "@mui/material/Typography";
import {
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  ListItemButton,
  Divider,
  Chip,
  Paper,
  Stack,
  Tooltip,
} from "@mui/material";

import DogAvatar from "./DogAvatar";

import { getLinkDisplay } from "constants/helpers";

const StyledListButton = styled(ListItemButton)(({ theme }) => ({
  padding: 0,
}));

export function DogCard({ dog, organization, showAddedDate=true }) {
  let { t } = useTranslation();
  
  if (! organization) {
    return (
    <Paper square sx={{ p: 2 }}>
      <Typography variant="body">{t("No organization specified for this dog.")}</Typography>
    </Paper>
    );
  }

  const regLinkDisplay = getLinkDisplay(dog, organization);
 
  return (
    <Paper sx={{ mb: 3 }}>
      <List disablePadding>
        {showAddedDate ? (
          <>
            <ListItem
              secondaryAction={
                <Typography variant="caption">
                  {t("Added on")}{" "}
                  {dayjs(dog?.createdAt).format("MM.DD.YYYY") || "?"}
                </Typography>
              }
            >
              { /* <ListItemText
                primary={
                  <Tooltip title={t("registry")}>
                    <Typography variant="body">
		                  {dog.organizations ?
		                  dog.organizations.map(
                        o => o.organization ? o.organization.name : ''
                        ).join(", ") : ""}
                    </Typography>
                  </Tooltip>
                }
              /> */ }
            </ListItem>
          </>
        ) : null}
        <StyledListButton href={`/${organization.prefix}/dog/${regLinkDisplay.link}`}>
          <ListItem
            secondaryAction={
              dog ? (
                <Stack
                  direction={{ xs: "column", md: "row" }}
                  spacing={{ xs: 1, md: 2 }}
                  sx={{ display: { xs: "none", md: "flex" } }}
                >
                  <Chip size="small" variant="outlined" label={t(dog?.sex)} />
                  <Chip
                    size="small"
                    variant="outlined"
                    label={`${t("DOB")} ${dayjs(dog?.dateOfBirth).format(
                      "MM.DD.YYYY"
                    )}`}
                  />
                </Stack>
              ) : null
            }
          >
            <ListItemAvatar sx={{ display: { xs: "none", sm: "flex" } }}>
              <DogAvatar
                primaryImage={dog?.primaryImage}
                dogId={dog?._id}
                alt={dog?.name}
              />
            </ListItemAvatar>
            <ListItemText
              sx={{ pl: { xs: 0, sm: 2 } }}
              primary={
                <Typography variant="h5">
                  {dog?.name || t("Not disclosed")}
                </Typography>
              }
              secondary={regLinkDisplay.display}
            />
          </ListItem>
        </StyledListButton>
      </List>
    </Paper>
  );
}
