import React from "react";
import { Autocomplete, TextField } from "@mui/material";
import { Controller } from "react-hook-form";

export default function DogAutocomplete({
  label,
  name,
  options,
  value,
  required,
  error,
  helperText,
  control,
}) {
  return (
    <Controller
      name={name}
      render={({ field: { onChange, ...props } }) => (
        <Autocomplete
          fullWidth
          required={required}
          options={options}
          getOptionLabel={(option) =>
            `${option.name} ${option.regNumber || option.microchipNumber}`
          }
          renderOption={(props, option) => (
            <li {...props} key={option._id}>
              {option.name} {option.regNumber || option.microchipNumber}
            </li>
          )}
          isOptionEqualToValue={(option, value) => option._id == value}
          renderInput={(params) => (
            <TextField
              {...params}
              label={label}
              required={required}
              error={error}
              helperText={helperText || ""}
              InputProps={{
                ...params.InputProps,
              }}
            />
          )}
          onChange={(e, data) => onChange(data)}
          {...props}
        />
      )}
      onChange={([, data]) => data}
      defaultValue={value || null}
      control={control}
    />
  );
}
