import React from "react";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

// Recoil
import { useRecoilValue } from "recoil";
import { kruValuesState } from "recoil/globalStates";
import {
  populatedCertsArrayState,
} from "recoil/publicStates";

import {
  getKruValueLabel,
  getAncestries,
  getNameByLang,
  getOrgAndMore }
  from "constants/helpers";

// MUI
import {
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  Paper,
} from "@mui/material";

import { error } from "constants/texts";

// import { get } from "react-hook-form";

export default function RegistrationInfo({ dog, organization }) {
  const { t, i18n } = useTranslation();
  const language = i18n.language;

  const kruValues = useRecoilValue(kruValuesState);

  const certs = useRecoilValue(populatedCertsArrayState(dog.certificates));
  const orgWithReg = getOrgAndMore(dog, organization);

  const ancestries = kruValues.filter((i) => i.category === "ancestry");
  const registries = kruValues.filter((i) => i.category === "registries");
      
  return (
	<TableContainer component={Paper}>
        <Table>
          <TableBody>
            <TableRow hover>
              <TableCell variant="head">{t("registry")}</TableCell>
              <TableCell>
                { orgWithReg.organization.name }
              </TableCell>
              <TableCell variant="head">{t("registrationClass")}</TableCell>
              <TableCell>
                {getKruValueLabel(orgWithReg.regClass, language) || error}
              </TableCell>
            </TableRow>
            <TableRow hover>
              <TableCell variant="head">{t("registrationNumber")}</TableCell>
              <TableCell>{orgWithReg.regNumber || error}</TableCell>
              <TableCell variant="head">{t("registrationDate")}</TableCell>
              <TableCell colSpan={3}>
                {dog.createdAt
                  ? dayjs(dog.createdAt).format("MM.DD.YYYY")
                  : error}
              </TableCell>
            </TableRow>

            {dog.regOther &&
              dog.regOther.map((entry, index) => (
                <><TableRow hover key={index + "-reg"}>
                  <TableCell variant="head">{t("External registry")}</TableCell>
                  <TableCell>
                  {getNameByLang(registries.find((r) => r._id === entry.regRegistry), language)}
                  </TableCell>
                  <TableCell variant="head">
                    {t("Registered breed")}
                  </TableCell>
                  <TableCell>
                      {getNameByLang(ancestries.find((a) => a._id === entry.regBreed), language)}
                  </TableCell>
                </TableRow><TableRow hover key={index + "-num"}>
                    <TableCell />
                    <TableCell />
                    <TableCell variant="head">{t("Registration number")}</TableCell>
                    <TableCell>
                      {entry.regNumber}
                    </TableCell>
                  </TableRow></>
              ))}


            <TableRow hover></TableRow>
            <TableRow hover></TableRow>
            { /*<TableRow hover>
              {dog.regOther?.length ? (
                <>
                  <TableCell variant="head">
                    {t("externalRegistries")}
                  </TableCell>
                  <TableCell colSpan="3">
                    {dog?.regOther
                      .map((reg) => Object.values(reg).join(" - "))
                      .join(", ")}
                  </TableCell>
                </>
              ) : null}
            </TableRow>
            <TableRow hover>
              <TableCell variant="head">{t("certificates")}</TableCell>
              <TableCell>
                {certs
                  ? certs.map((cert) => cert.certificate.name).join(", ")
                  : error}
              </TableCell>
              </TableRow> */}
          </TableBody>
        </Table>
      </TableContainer>
	    );
	    }
	    
