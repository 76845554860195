import React, { Component } from "react";
import i18next from "i18next";

import { Tooltip, Grow, Box, Typography } from "@mui/material";

import CancelIcon from "@mui/icons-material/Cancel";
import VerifiedIcon from "@mui/icons-material/Verified";

class Checkmark extends Component {
  render() {
    return (
      <Tooltip
        TransitionComponent={Grow}
        TransitionProps={{ timeout: 600 }}
        title={
          <Box sx={{ p: 1 }}>
            <Typography variant="body" sx={{ fontWeight: 300 }}>
              {i18next.t("breederHasCertTooltip")}
            </Typography>
          </Box>
        }
      >
        <VerifiedIcon
          alt="Certified"
          style={{
            fill: "#007466",
            verticalAlign: "bottom",
            marginLeft: "5px",
          }}
        />
      </Tooltip>
    );
  }
}

class Errormark extends Component {
  render() {
    return (
      <Tooltip
        TransitionComponent={Grow}
        TransitionProps={{ timeout: 600 }}
        title={
          <Box sx={{ p: 1 }}>
            <Typography variant="body" sx={{ fontWeight: 300 }}>
              {i18next.t("breederHasNoCertTooltip")}
            </Typography>
          </Box>
        }
      >
        <CancelIcon
          alt="Not certified"
          style={{
            fill: "#d52525",
            verticalAlign: "bottom",
            marginLeft: "5px",
          }}
        />
      </Tooltip>
    );
  }
}

export { Checkmark, Errormark };
