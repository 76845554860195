import React, { useEffect } from "react";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";

// Recoil
import { useRecoilState, useRecoilValue } from "recoil";
import {
  accessTokenState,
  kruValuesState,
  userState,
} from "recoil/globalStates";
import { litterRegistrationState } from "recoil/registrationStates";

import { countFemales, countMales } from "constants/helpers";

// MUI
import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Divider,
  FormControl,
  FormGroup,
  Grid,
  Typography,
  TextField,
  IconButton,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormLabel,
  Stack,
  Paper,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

// Local
import { checkIfMicrochipExists } from "api/privateRoutes";
import { countries } from "constants/countries";
import {
  createHandleInputChange,
  getBreeds,
  getMixes,
  getTypes } from "constants/helpers";
import { CoatRadio } from "components/registrationComponents/coatRadio.js";
import { CoatCheckbox } from "components/registrationComponents/coatCheckbox.js";
  
export default function LitterPuppies() {
  const { t, i18n } = useTranslation();
  const kruValues = useRecoilValue(kruValuesState);
  const [formData, setFormData] = useRecoilState(litterRegistrationState);
  const handleInputChange = createHandleInputChange(setFormData);

  const user = useRecoilValue(userState);
  const accessToken = useRecoilValue(accessTokenState);

  const language = i18n.language;

  const breedingPrefix = user?.breedingPrefix || "";

  const methods = useFormContext();
  const {
    control,
    trigger,
    setError,
    clearErrors,
    formState: { errors },
  } = methods;

  const validateDateOfBirth = (e, field) => {
    let parsedDate = dayjs(e.target.value);
    if (!dayjs(parsedDate).isValid()) {
      setError("dateOfBirth", {
        type: "custom",
        message: t("Invalid date") + " (mm/dd/yyyy)",
      });
    } else if (dayjs(parsedDate).isAfter(dayjs())) {
      setError(name, {
        type: "custom",
        message: t("Date cannot be in the future"),
      });
    } else {
      clearErrors("dateOfBirth");
    }
  };

  const { puppies, declaredBreed, declaredMix, declaredType1, declaredType2 } = formData;
  // Set country of origin default to user's country
  let defaultCountry = countries.find((c) => c.code === user.country);
  useEffect(() => {
    if (!formData.countryOfOrigin) {
      setFormData({
        ...formData,
        ["countryOfOrigin"]: defaultCountry,
      });
  }
  }, []);

  const breeds = getBreeds(kruValues, language);
  const mixes = getMixes(kruValues, language);
  const types = getTypes(kruValues, language);

  /* Lots of declared type stuff */
// Map dog.declaredTypes from kruValue ids to kruValue objects
const kruAncestry = kruValues.filter((value) => value.category === "ancestry");

useEffect(() => {
  const invalid = validateDeclaredTypes();
}, [declaredBreed, declaredMix, declaredType1, declaredType2]);

const validateDeclaredTypes = () => {
  const declared = [declaredBreed, declaredMix, declaredType1, declaredType2].filter(Boolean);
  const clearAllErrors = () => {
    clearErrors("declaredBreed");
    clearErrors("declaredMix");
    clearErrors("declaredType");
  };

  if (0 <= declared.length && declared.length <= 1) {
    clearAllErrors();
    return;
  }

  if (declared.length == 2 && [declaredType1, declaredType2].filter(Boolean).length == 2) {
     clearAllErrors();
    if (declaredType1._id === declaredType2._id) {
      setError("declaredType", { message: t("The two types must not be the same.") });
      return;
    }
    return;
  }

  const msg = t("Breeding description must be a single breed OR a single mix OR 1-2 types.");
  declaredBreed ?
    setError("declaredBreed", { message: msg }) :
    clearErrors("declaredBreed");
  
  declaredMix ?
    setError("declaredMix", { message: msg }) :
    clearErrors("declaredMix");

  (declaredType1 || declaredType2) ?
    setError("declaredType", { message: msg }) :
    clearErrors("declaredType");
  };

  const addPuppy = () => {
    setFormData({
      ...formData,
      puppies: [
        ...formData.puppies,
        {
          name: breedingPrefix || "",
          microchipNumber: "",
          sex: "male",
          color: null,
          whitemarkings: [],
          spots: [],
          othermarkings: [],
          alocusmodifiers: [],
          merlemodifiers: [],
          othermodifiers: [],
          coattype: null,
          coatcurliness: null,
          coatqualities: [],      
        }
      ]
    });
    clearErrors("puppies");
  };

  const removePuppy = (i) => {
    setFormData({
      ...formData,
      puppies: formData.puppies.filter((_, index) => index !== i)
    });
  };

  const checkMicrochipNumber = (e) => {
    if (e.target.value && e.target.value !== "") {
      checkIfMicrochipExists(e.target.value, { accessToken }).then(
        (response) => {
          if (response === true) {
            setError(e.target.name, {
              type: "custom",
              message: t("checkMicrochipNumber"),
            });
          } else {
            clearErrors(e.target.name);
          }
        }
      );
      clearErrors(e.target.name);
    }
  };

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} sx={{ mb: 2 }}>
          <Divider textAlign="left" sx={{ mb: 2 }}>
            <Typography variant="h2">{t("litterInformation")}</Typography>
          </Divider>
        </Grid>
        <Grid item xs={12} sm={6} sx={{ mb: 2 }}>
          <Controller
            name="name"
            control={control}
            defaultValue={formData.name}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                value={formData.name}
                onChange={(e) => handleInputChange(e, field)}
                label={t("Litter name")}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} sm={6} sx={{ mb: 2 }}>
          <Controller
            name="dateOfBirth"
            control={control}
            defaultValue={formData.dateOfBirth}
            rules={{
              required: t("required"),
            }}
            render={({ field: { ref, onChange, name, value, ...field }, fieldState: { error } }) => (
              <DatePicker
                {...field}
                required
                inputRef={ref}
                label={t("Date of birth")}
                disableFuture
                value={value}
                onChange={(date) => {
                  const formattedDate = dayjs(date).format('MM/DD/YYYY');
                  handleInputChange(
                    {
                      target: {
                        name: name,
                        value: formattedDate,
                      }
                    },
                    field
                  );
                  onChange(formattedDate);
                }}
                error={errors?.dateOfBirth && true}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    required
                    value={formData.dateOfBirth}
                    name={name}
                    error={errors?.dateOfBirth && true}
                    helperText={errors && errors?.dateOfBirth?.message}
                    onBlur={(e) => {
                      handleInputChange(e, field);
                      validateDateOfBirth(e, field, clearErrors);
                    }} 
                  />
               )}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} sx={{ mb: 2 }}>
          <Controller
            name="countryOfOrigin"
            rules={{
              required: true,
            }}
            render={({ field: { onChange, ...props } }) => (
              <Autocomplete
                options={countries}
                value={formData.countryOfOrigin || defaultCountry}
                getOptionLabel={(option) =>
                  option[language] ? option[language] : ""
                }
                renderOption={(props, option) => (
                  <li {...props} key={option.code}>
                    {option[language]} {option.code}
                  </li>
                )}
                placeholder={t("countryOfOrigin")}
                isOptionEqualToValue={(option, value) =>
                  option.code == value.code
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t("countryOfOrigin")}
                    value={formData.countryOfOrigin}
                  />
                )}
                onChange={(e, data) => {
                  onChange(data);
                  setFormData({
                    ...formData,
                    ["countryOfOrigin"]: data
                  });
                }}
                {...props}
              />
            )}
            onChange={([, data]) => data}
            defaultValue={
              formData.countryOfOrigin || defaultCountry
            }
            control={control}
          />
        </Grid>

{/* HIDING UNTIL SCHEMA UPDATE
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <Controller
              name="stats.deadPuppies"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  type="number"
                  label={t("countOfDeadPuppies")}
                />
              )}
            />
            <FormHelperText>{t("voluntaryDataInfoText")}</FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <InputLabel id="mating">{t("mating")}</InputLabel>
            <Controller
              name="stats.mating"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <Select {...field} fullWidth label={t("mating")}>
                  <MenuItem value="natural">{t("natural")}</MenuItem>
                  <MenuItem value="artificialInsemination">
                    {t("artificialInsemination")}
                  </MenuItem>
                </Select>
              )}
            />
            <FormHelperText>{t("voluntaryDataInfoText")} </FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <InputLabel id="birth">{t("birth")}</InputLabel>
            <Controller
              name="stats.birth"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <Select {...field} fullWidth label={t("birth")}>
                  <MenuItem value="natural">{t("natural")}</MenuItem>
                  <MenuItem value="cesareanSection">
                    {t("cesareanSection")}
                  </MenuItem>
                  <MenuItem value="laborDifficulties">
                    {t("laborDifficulties")}
                  </MenuItem>
                </Select>
              )}
            />
            <FormHelperText>{t("voluntaryDataInfoText")} </FormHelperText>
          </FormControl>
        </Grid>
*/}

        <Grid item xs={12}>
        <Divider textAlign="left" sx={{ my: 2 }}>
          <Typography variant="h5">{t("Breeding Description (Breed or Mix)")}</Typography>
          </Divider>
        </Grid>
        <Grid item xs={12} sm={6}>
        <Box mb={2}>
        <Typography variant="body">{t("Select breed (for example, \"Labrador Retriever\" or \"Goldendoodle\"")}:</Typography>
        </Box>
        <Controller
          name="declaredBreed"
          control={control}
          defaultValue={formData.declaredBreed || ""}
          render={({ field: { onChange, ...props } }) => (
            <Autocomplete
              fullWidth
              freeSolo
              label="Breed"
              options={breeds}
              getOptionLabel={(option) =>
                option[language] ? option[language].name : ""
              }
              value={formData.declaredBreed}
              onChange={(e, data) => {
                onChange(data);
                setFormData({
                  ...formData,
                  ["declaredBreed"]: data
                });
              }}
              isOptionEqualToValue={(option, value) =>
                option._id === value._id
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t("Breed")}
                  error={!!errors.declaredBreed}
                  helperText={errors.declaredBreed?.message}
                />
              )}
              {...props}
            />
          )}
        />
        </Grid>

        <Grid item xs={12} sm={6}>
        <Box mb={2}>
          <Typography variant="body">{t("OR, select mix (for example, \"Labradoodle\" or \"Pomsky\"):")}</Typography>
        </Box>
        <Controller
          name="declaredMix"
          control={control}
          defaultValue={formData.declaredMix || ""}
          render={({ field: { onChange, ...props } }) => (
            <Autocomplete
              fullWidth
              freeSolo
              label={t("Mix")}
              options={mixes}
              value={formData.declaredMix}
              getOptionLabel={(option) =>
                option[language] ? option[language].name : ""
              }
              onChange={(e, data) => {
                onChange(data);
                setFormData({
                  ...formData,
                  ["declaredMix"]: data
                });
              }}
              isOptionEqualToValue={(option, value) =>
                option._id === value._id
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t("Mix")}
                  error={!!errors.declaredMix}
                  helperText={errors.declaredMix?.message}
                />
              )}
              {...props}
            />
          )}
        />
        </Grid>

        <Grid item xs={12} sm={6}>
        <Box mb={2}>
          <Typography variant="body">
            {t("OR, for mixes without a widely-recognized name, select up to two types that best describe the breed content:")}
            </Typography>
        </Box>
        <Grid item xs={12}>
        <Box mb={2}>
        <Controller
          key="declaredType1"
          name="declaredType1"
          control={control}
          defaultValue={formData.declaredType1 || ""}
          render={({ field: { onChange, ...props } }) => (
            <Autocomplete
              fullWidth
              freeSolo
              label="Type"
              value={formData.declaredType1}
              options={types}
              getOptionLabel={(option) =>
                option[language] ? option[language].name : ""
              }
              onChange={(e, data) => {
                onChange(data);
                setFormData({
                  ...formData,
                  ["declaredType1"]: data
                });
              }}
              isOptionEqualToValue={(option, value) =>
                option._id === value._id
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t("Type")}
                  error={!!errors.declaredType}
                  helperText={errors.declaredType?.message}
                />
              )}
              {...props}
            />
          )}
        />
        </Box>
        <Box mb={2}>
        <Controller
          key="declaredType2"
          name="declaredType2"
          control={control}
          defaultValue={formData.declaredType2 || ""}
          render={({ field: { onChange, ...props } }) => (
            <Autocomplete
              fullWidth
              freeSolo
              label="Type"
              value={formData.declaredType2}
              options={types}
              getOptionLabel={(option) =>
                option[language] ? option[language].name : ""
              }
              onChange={(e, data) => {
                onChange(data);
                setFormData({
                  ...formData,
                  ["declaredType2"]: data
                });
              }}
              isOptionEqualToValue={(option, value) =>
                option._id === value._id
              }
              renderInput={(params) => (
                <TextField {...params} label={t("Type")} />
              )}
              {...props}
            />
          )}
        />
        </Box>
        </Grid>
        </Grid>

        {/*<Grid item xs={12}>
          <Typography variant="h5" sx={{ my: 1 }}>
            {t("microchipCertificate")}
          </Typography>
          <Typography variant="body" sx={{ my: 2 }}>
            {t("microchipCertificateText")}
          </Typography>
          </Grid> */}
        <Grid item xs={12}>
          <Divider textAlign="left" sx={{ mt: 2 }}>
            <Typography variant="h2">{t("puppies2")}</Typography>
          </Divider>
        </Grid>
        <Grid item xs={12}>
          <Stack justifyContent="space-between" direction="row"></Stack>
          <Grid item xs={12}>
            {puppies.map((puppy, i) => (
              <Paper variant="outlined" key={"puppy"+i} sx={{ mb: 2 }}>
                <Grid container>
                  <Grid item xs={12}>
                    <Stack justifyContent="space-between" direction="row">
                      <Typography variant="h4">
                        {`${t("puppy")} ${i + 1}`}
                      </Typography>
                      <IconButton
                        onClick={() => {
                          if (confirm(t("deletePuppyConfirmation"))) {
                            removePuppy(i);
                            trigger();
                          }
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Stack>
                  </Grid>
                  <Grid item xs={5}>
                    <Controller
                      name={`puppies[${i}].name`}
                      control={control}
                      defaultValue={puppies[i].name || breedingPrefix}
                      rules={{
                        required: t("required"),
                      }}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          fullWidth
                          required
                          value={puppies[i].name || breedingPrefix}
                          label={t("puppysName")}
                          onChange={(e) => handleInputChange(e, field)}
                          error={errors?.puppies?.[i]?.puppyName && true}
                          helperText={
                            errors?.puppies?.[i] &&
                            errors?.puppies?.[i]?.puppyName?.message
                          }
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Controller
                      name={`puppies[${i}].microchipNumber`}
                      control={control}
                      defaultValue={puppies[i].microchipNumber || null}
                      rules={{
                        required: t("required"),
                        onBlur: (e) => checkMicrochipNumber(e),
                      }}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          fullWidth
                          required
                          label={t("microchipNumber")}
                          onChange={(e) => handleInputChange(e, field)}
                          error={errors?.puppies?.[i]?.microchipNumber && true}
                          helperText={
                            errors?.puppies?.[i] &&
                            errors?.puppies?.[i]?.microchipNumber?.message
                          }
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <FormLabel>{t("sex")}</FormLabel>
                    <Controller
                      name={`puppies[${i}].sex`}
                      control={control}
                      defaultValue={puppies[i].sex || "male"}
                      rules={{
                        required: t("required"),
                      }}
                      render={({ field }) => (
                        <RadioGroup
                          {...field}
                          row
                          onChange={(e) => handleInputChange(e, field)}
                        >
                          <FormControlLabel
                            value="male"
                            control={<Radio />}
                            label={
                              <Typography variant="body">
                                {t("male")}
                              </Typography>
                            }
                          />
                          <FormControlLabel
                            value="female"
                            control={<Radio />}
                            label={
                              <Typography variant="body">
                                {t("female")}
                              </Typography>
                            }
                          />
                        </RadioGroup>
                      )}
                    />
                  </Grid>

                  <Grid item xs={12} sm={12}>
          <Divider textAlign="left" sx={{ my: 2 }}>
            <Typography variant="h5">{t("Coat")}</Typography>
          </Divider>
        </Grid>

        <Grid item xs={6}>
        <FormControl component="fieldset" fullWidth>
          <FormLabel component="legend">
            <Typography variant="h5">{t("Base color")}</Typography>
          </FormLabel>
          <Controller
                      name={`puppies[${i}].color`}
                      control={control}
            defaultValue={puppies[i].color}
            render={({ field }) => (
            <RadioGroup
              {...field}
              onChange={(e) => handleInputChange(e, field)}
            >
              <FormGroup sx={{ border: '1px solid black', padding: 2, borderRadius: 1, marginBottom: 2 }}>
                <FormLabel component="legend">
                  <Typography variant="body">Reds</Typography>
                </FormLabel>
                <CoatRadio
                  category="coat.colors.pheomelanin"
                  kruValues={kruValues}
                  language={language}
                />
              </FormGroup>

              <FormGroup sx={{ border: '1px solid black', backgroundColor: 'lightgrey', padding: 2, borderRadius: 1, marginBottom: 2 }}>
                <FormLabel component="legend">
                  <Typography variant="body">Brown/Black</Typography>
                </FormLabel>
                <CoatRadio
                  category="coat.colors.eumelanin"
                  kruValues={kruValues}
                  language={language}
                />
                </FormGroup>
                
                <FormGroup sx={{ border: '1px solid black', padding: 2, borderRadius: 1, marginBottom: 2 }}>
                  <FormLabel component="legend"><Typography variant="body">Dilutes</Typography></FormLabel>
                  <CoatRadio
                    category="coat.colors.dilute"
                    kruValues={kruValues}
                    language={language}
                  />
                </FormGroup>
                
                <FormGroup sx={{ border: '1px solid black', backgroundColor: 'lightgrey', padding: 2, borderRadius: 1, marginBottom: 2 }}>
                  <FormControlLabel
                    key="unknown"
                    value="coat.colors.unknown"
                    control={<Radio />}
                    label={<Typography variant="body">Unknown</Typography>}
                  />
                </FormGroup>
            </RadioGroup>
            )}
          />
          </FormControl>
          </Grid>

        <Grid item xs={6}>
          <FormControl component="fieldset" fullWidth>
            <FormLabel component="legend">
              <Typography variant="h5">{t("Markings")}</Typography>
            </FormLabel>
            <Controller
                      name={`puppies[${i}].whitemarkings`}
                      control={control}
              defaultValue={puppies[i].whitemarkings}
              render={({ field }) => (
              <RadioGroup
                {...field}
                onChange={(e) => handleInputChange(e, field)}
              >
              <FormGroup sx={{ border: '1px solid black', padding: 2, borderRadius: 1, marginBottom: 2 }}>
                <FormLabel component="legend"><Typography variant="body">White markings</Typography></FormLabel>
                <CoatRadio
                    category="coat.markings.white"
                    kruValues={kruValues}
                    language={language}
                  />
              </FormGroup>
              </RadioGroup>
            )}
          />
        
        <Controller
                      name={`puppies[${i}].spots`}
                      control={control}
            defaultValue={puppies[i].spots}
            render={({ field }) => (
              <RadioGroup
                {...field}
                onChange={(e) => handleInputChange(e, field)}
              >
              <FormGroup sx={{ border: '1px solid black', padding: 2, borderRadius: 1, marginBottom: 2, backgroundColor: 'lightgrey' }}>
                <FormLabel component="legend"><Typography variant="body">Spots</Typography></FormLabel>
                <CoatRadio
                    category="coat.markings.spots"
                    kruValues={kruValues}
                    language={language}
                  />
              </FormGroup>
              </RadioGroup>
            )}
          />
        
          <Controller
                      name={`puppies[${i}].othermarkings`}
                      control={control}
            defaultValue={puppies[i].othermarkings}
            render={({ field }) => (
              <FormGroup sx={{ border: '1px solid black', padding: 2, borderRadius: 1, marginBottom: 2 }}>
                <FormLabel component="legend"><Typography variant="body">Other</Typography></FormLabel>
                <CoatCheckbox
                  category="coat.markings.other"
                  kruValues={kruValues}
                  language={language}
                  field={field}
                  handleInputChange={handleInputChange}
                />
              </FormGroup>
            )}
          />
          </FormControl>
        </Grid>

        <Grid item xs={6}>
          <FormControl component="fieldset" fullWidth>
            <FormLabel component="legend">
              <Typography variant="h5">{t("Modifiers")}</Typography>
            </FormLabel>
            <Controller
                      name={`puppies[${i}].alocusmodifiers`}
                      control={control}
            defaultValue={puppies[i].alocusmodifiers}
            render={({ field }) => (
              <RadioGroup
                {...field}
                onChange={(e) => handleInputChange(e, field)}
              >
              <FormGroup sx={{ border: '1px solid black', padding: 2, borderRadius: 1, marginBottom: 2 }}>
                <FormLabel component="legend"><Typography variant="body">A Locus</Typography></FormLabel>
                <CoatRadio
                    category="coat.modifiers.alocus"
                    kruValues={kruValues}
                    language={language}
                  />

                </FormGroup>
                </RadioGroup>
              )}
             />

            <Controller
                      name={`puppies[${i}].merlemodifiers`}
                      control={control}
              defaultValue={puppies[i].merlemodifiers}
              render={({ field }) => (
              <RadioGroup
                {...field}
                onChange={(e) => handleInputChange(e, field)}
              >
              <FormGroup sx={{ border: '1px solid black', backgroundColor: 'lightgrey', padding: 2, borderRadius: 1, marginBottom: 2 }}>
              <FormLabel component="legend"><Typography variant="body">Merle</Typography></FormLabel>
              <CoatRadio
                  category="coat.modifiers.merle"
                  kruValues={kruValues}
                  language={language} />
                </FormGroup>
                </RadioGroup>
              )}
            />

            <Controller
                      name={`puppies[${i}].othermodifiers`}
                      control={control}
              defaultValue={puppies[i].othermodifiers}
              render={({ field }) => (
                <FormGroup sx={{ border: '1px solid black', padding: 2, borderRadius: 1, marginBottom: 2 }}>
                <FormLabel component="legend"><Typography variant="body">Other</Typography></FormLabel>
                <CoatCheckbox
                    category="coat.modifiers.other"
                    kruValues={kruValues}
                    language={language}
                    field={field}
                    handleInputChange={handleInputChange}
                  />
                </FormGroup>
              )}
            />
        </FormControl>
        </Grid>

        <Grid item xs={6}>
          <FormControl fullWidth>
            <FormLabel>
              <Typography variant="h5">{t("Coat type")}</Typography>
            </FormLabel>
            <Controller
                      name={`puppies[${i}].coattype`}
                      control={control}
              defaultValue={puppies[i].coattype}
              render={({ field }) => (
                <RadioGroup
                {...field}
                onChange={(e) => handleInputChange(e, field)}
              >
                <FormGroup sx={{ border: '1px solid black', padding: 2, borderRadius: 1, marginBottom: 2 }}>
                <CoatRadio
                    category="coat.types"
                    kruValues={kruValues}
                    language={language}
                  />

                </FormGroup>
                </RadioGroup>
                )}
            />
          </FormControl>

          <FormControl fullWidth>
            <FormLabel>
              <Typography variant="h5">{t("coatQualities")}</Typography>
            </FormLabel>
            <Controller
                      name={`puppies[${i}].coatcurliness`}
                      control={control}
              defaultValue={puppies[i].coatcurliness}
              render={({ field }) => (
                <RadioGroup
                  {...field}
                  onChange={(e) => handleInputChange(e, field)}
                >
                  <FormGroup sx={{ border: '1px solid black', padding: 2, borderRadius: 1, marginBottom: 2 }}>
                  <FormLabel component="legend"><Typography variant="body">Curliness</Typography></FormLabel>
                  <CoatRadio
                    category="coat.qualities.curliness"
                    kruValues={kruValues}
                    language={language}
                  />

                  </FormGroup>
                  </RadioGroup>
              )}
            />
            <Controller
                      name={`puppies[${i}].coatqualities`}
                      control={control}
              defaultValue={puppies[i].coatqualities}
              render={({ field }) => (
                <FormGroup sx={{ border: '1px solid black', backgroundColor: 'lightgrey', padding: 2, borderRadius: 1, marginBottom: 2 }}>
                <FormLabel component="legend"><Typography variant="body">{t("Other coat qualities")}</Typography></FormLabel>
                <CoatCheckbox
                    category="coat.qualities.other"
                    kruValues={kruValues}
                    language={language}
                    field={field}
                    handleInputChange={handleInputChange}
                  />
                </FormGroup>
              )}
            />
          </FormControl>
        </Grid>
                </Grid>
              </Paper>
            ))}{" "}
          </Grid>
          {errors?.puppies?.root && (
            <Grid item xs={12}>
              <Alert severity="error">{errors.puppies.root.message}</Alert>
            </Grid>
          )}
          <Grid item xs={12}> {/* Hidden Controller for validation */}
            <Controller
              name="puppies"
              control={control}
              rules={{
                validate: () => formData.puppies.length > 0 || "At least one puppy must be added."
              }}
              render={({ field }) => (
                <input type="hidden" {...field} value={formData.puppies.length} />
              )}
            />
            {errors.puppies && (
              <Typography color="error">
                {errors.puppies.message}
              </Typography>
            )}
            <Stack direction="row" spacing={3} justifyContent="space-between">
              <Button
                variant="outlined"
                startIcon={<AddIcon />}
                onClick={addPuppy}
              >
                {t("addPuppy")}
              </Button>
              <Stack spacing={3} direction="row">
                <Typography variant="body">
                  {t("males")} {countMales(puppies)}
                </Typography>
                <Typography variant="body">
                  {t("females")} {countFemales(puppies)}
                </Typography>
                <Typography variant="body">
                  {t("total")} {puppies?.length || 0}
                </Typography>
              </Stack>
            </Stack>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
