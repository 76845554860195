import React from "react";
import dayjs from "dayjs";
import { getKruValueLabel } from "constants/helpers";
import { useTranslation } from "react-i18next";

import { Link, Typography, Tooltip } from "@mui/material";

// Images
import ProfImg from "images/placeholder.png";
import { imageServer } from "constants/values";
import { countries } from "constants/countries";
import { getLinkDisplay } from "constants/helpers";

export function GetDogColumns( { organization } ) {
  const { t, i18n } = useTranslation();
  const language = i18n.language;

  if (! organization ) {
    throw new Error("Organization not specified in DogColumns");
  }

  const dogColumns = [
    { field: "_id", headerName: "ID", hide: true },
    {
      field: "image",
      headerName: t("photo"),
      width: 80,
      renderCell: (params) => (
          <img
            alt={t("photoDesc")}
            src={
              params.row?.primaryImage
                ? imageServer + "/images/thumbs/" + params.row.primaryImage
                : ProfImg
            }
            style={{ width: "55px", marginTop: "10%" }}
          />
      ),
    },
    {
      field: "regNumber",
      headerName: t("regNumber"),
      width: 150,
      renderCell: (params) => (
        <Typography>{getLinkDisplay(params.row, organization)?.display}</Typography>
      ),
    },
    {
      field: "name",
      headerName: t("dogsName"),
      minWidth: 270,
      renderCell: (params) => (
        <Link
          style={{
            textDecoration: "none",
            color: "#004569",
          }}
          href={`/${organization.prefix}/dog/${getLinkDisplay(params.row, organization)?.link}`}
        >
          <Tooltip
            title={
              <React.Fragment>
                <Typography variant="body2">{params.value}</Typography>
              </React.Fragment>
            }
          >
            <Typography
              variant="body2"
              style={{
                fontWeight: 600,
              }}
            >
              {params.value}
            </Typography>
          </Tooltip>
        </Link>
      ),
    },
    {
      field: "sex",
      headerName: t("sex"),
      renderCell: (params) => t(params.value),
      width: 90,
    },

    {
      field: "dateOfBirth",
      headerName: t("dob"),
      renderCell: (params) => dayjs(params.value).format("MM.DD.YYYY"),
      width: 120,
    },

    {
      field: "countryOfOrigin",
      headerName: t("countryOfOrigin"),
      width: 140,
      renderCell: (params) => {
        if (params.value !== undefined) {
          const found = countries.find(
            (c) => c.code === params.value?.toUpperCase()
          );
          if (found) {
            return (
              <Typography
                variant="body2"
                style={{
                  textOverflow: "ellipsis !important",
                  overflow: "hidden !important",
                }}
              >
                {
                  countries.find((c) => c.code === params.value.toUpperCase())[
                    language
                  ]
                }
              </Typography>
            );
          }
        }
      },
    },
    {
      field: "microchipNumber",
      headerName: t("microchipNumber"),
      width: 170,
    },
    {
      field: "color",
      headerName: t("color"),
      width: 180,
      valueFormatter: (params) => {
        if (params.value == null) {
          return "";
        }
        const mappedValues = params?.value?.map((value, i) => {
          return getKruValueLabel(value, language);
        });
        const joinedValues = mappedValues.join(", ");
        return joinedValues;
      },
    },
    {
      field: "coat",
      headerName: t("coat"),
      width: 180,
      valueFormatter: (params) => {
        if (params.value == null) {
          return "";
        }
        const mappedValues = params?.value?.map((value, i) => {
          return getKruValueLabel(value, language);
        });
        const joinedValues = mappedValues.join(", ");
        return joinedValues;
      },
    },
  ];
  return dogColumns;
}
