import React from "react";
import { ArrowDropDown } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";
import { constSelector, useRecoilValue } from "recoil";
import { userState } from "recoil/globalStates";

export default function JsonPreviewWidget({ formdata }) {
  const { isAuthenticated } = useAuth0();
  const user = useRecoilValue(
    isAuthenticated ? userState : constSelector(null)
  );

  return user && user.role === "admin" ? (
    <Accordion>
      <AccordionSummary expandIcon={<ArrowDropDown />}>
        <Typography variant="body">Changes preview (raw JSON)</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography variant="caption">
          This preview is visible to admins only and meant for debugging
          purposes.
        </Typography>
        <Box sx={{ maxWidth: "50vw", my: 4 }}>
          {formdata
            ? JSON.stringify(formdata, null, "\t")
            : "No changes to update"}
        </Box>
      </AccordionDetails>
    </Accordion>
  ) : null;
}
