import React from "react";
import { useTranslation } from "react-i18next";
import { Typography, Divider, Grid } from "@mui/material";

// MUI

export default function Privacy() {
  const { t } = useTranslation();
  return (
    <>
      <Grid container direction={{ xs: "column", md: "row" }} spacing={5}>
        <Grid item xs={7}>
          <Divider textAlign="left" sx={{ mb: 2 }}>
            <Typography variant="h2">{t("Privacy Notice")}</Typography>
          </Divider>
          <Typography>
            <p>
              This Privacy Notice explains how The Functional Dog Collaborative (“FDC”, “we”, “us” or “our”) collects, uses, discloses, and otherwise processes personal information (as defined below) in connection with our websites (the “Sites”) and other websites we own and operate that link to this Privacy Notice, and the related content, platform, services, products, and other functionality offered on or through our online services (collectively, the “Services”).
            </p>
            <p>
              <b>What is Personal Information?</b>
            </p>
            <p>
              The term “personal information” in this Privacy Notice means information that identifies, relates to, describes, is reasonably capable of being associated with, or could reasonably be linked, directly or indirectly, to an individual. It does not include aggregated or de-identified information that is maintained in a form that is not reasonably capable of being associated with or linked to an individual.
            </p>
            <p>
              <b>Our Collection of Personal Information</b>
            </p>
            <p>
              Sometimes we collect personal information automatically when an individual interacts with our Services and sometimes, we collect personal information directly from an individual. At times, we may collect personal information about an individual from other sources and third parties.
            </p>
            <p>
              <b>Personal Information Collected from Individuals</b>
            </p>
            <p>
              We may collect the following personal information submitted to us by individuals through the Services:
            </p>
            <ul>
              <li>
                Contact Information, including first and last name, middle initial, suffixes and prefixes, email address, mailing address, phone number and communication preferences.
              </li>
              <li>
                Inquiry and Communications Information, including information provided in custom messages sent through the forms, to one of our email addresses, or via phone. This includes online applications and registrations. This also includes information provided in order to subscribe to, or contact information provided on our Services.
              </li>
              <li>
                Account Information, including first and last name, middle initial, suffixes and prefixes, email address, username, profile information, registration number(s), dog breed(s), name and associated details, litter information, account balances, payment and purchase history information, awards, and any other information you provide to us. Please note we utilize a third-party provider to process payments on our behalf and do not accept payment directly through our Services.
              </li>
              <li>
                Donation Information, including first and last name, donation type, donation amount, phone number, email address, billing information and payment history.
              </li>
              <li>
                Event Information, including seminar information, registration information, organization name, and contact information.
              </li>
              <li>
                Dog and Litter Registration Information, including first name, last name, mailing address, email address, dam information, sire information, litter information, name and address of individuals to whom you have transferred a dog or litter, dam lease information, and any information provided during the registration process and/or through the use of FDC’s online recordkeeping services.
              </li>
            </ul>
            <p>
              <b>Personal Information Automatically Collected</b>
            </p>
            <p>
              As is true of most digital platforms, we and our third-party providers may also collect personal information from an individual’s device, browsing actions and website usage patterns automatically when visiting or interacting with our Services, which may include log data (such as internet protocol (IP) address, operating system, browser type, browser id, the URL entered and the referring page/campaign, date/time of visit, the time spent on our Services and any errors that may occur during the visit to our Services), analytics data (such as the electronic path taken to our Services, through our Services and when exiting our Services, as well as usage and activity on our Services) and location data (such as general geographic location based on the log data we or our third-party providers collect). Please note that our systems may also record personal information that you type into our Sites and other online services even if you do not choose to submit it.
            </p>
            <p>
              We and our third-party providers may use (i) cookies or small data files that are stored on an individual’s computer and (ii) other, related technologies, such as web beacons, pixels, embedded scripts, location-identifying technologies and logging technologies (collectively, “cookies”) to automatically collect this personal information.
            </p>
            <p>
              To manage cookies, an individual may change their browser settings to: (i) notify them when they receive a cookie, so the individual can choose whether or not to accept it; (ii) disable existing cookies; or (iii) automatically reject cookies. Please note that doing so may negatively impact an individual’s experience using our Services, as some features and offerings may not work properly or at all. Depending on an individual’s device and operating system, the individual may not be able to delete or block all cookies. In addition, if an individual wants to reject cookies across all browsers and devices, the individual will need to do so on each browser on each device they actively use. An individual may also set their email options to prevent the automatic downloading of images that may contain technologies that would allow us to know whether they have accessed our email and performed certain functions with it.
            </p>
            <p>
              <b>Personal Information from Third Parties</b>
            </p>
            <p>
              We also obtain personal information from third parties; which we often combine with personal information we collect either automatically or directly from an individual.
            </p>
            <p>
              We may receive the same categories of personal information as described above from the following third parties:
            </p>
            <ul>
              <li>
                Organization Affiliates: If you are affiliated with an FDC-affiliated organization, the organization may provide us with your contact information and certain other information you choose to share with that organization.
              </li>
              <li>
                Event Information Providers: Generally, we obtain information about FDC-affiliated conferences and other events from the individuals or organizations that run the events.
              </li>
              <li>
                Service Providers: Our service providers that perform services solely on our behalf, such as survey and marketing providers and payment processors, collect personal information and often share some or all of this information with us.
              </li>
              <li>
                Information Providers: We may from time to time obtain information from third-party information providers to correct or supplement personal information we collect. For example, we may obtain updated contact information from third-party information providers to reconnect with an individual.
              </li>
            </ul>
            <p>
              <b>Our Use of Personal Information</b>
            </p>
            <p>
              We use personal information we collect to:
            </p>
            <ul>
              <li>
                Fulfill or meet the reason the information was provided, such as to fulfill our contractual obligations, to deliver the Services you have requested and to process transactions, including facilitating your applications, registrations and evaluations;
              </li>
              <li>
                Manage our organization and its day-to-day operations;
              </li>
              <li>
                Communicate with individuals, including via email, text message, social media and/or telephone calls;
              </li>
              <li>
                Request individuals to complete surveys about our organization, organizations we work with, and Services;
              </li>
              <li>
                For marketing and advertising purposes, including to market to you or offer you through email, direct mail, phone or text message information and updates on products or services we think that you may be interested in;
              </li>
              <li>
                Administer, improve and personalize our Services, including by recognizing an individual and remembering their information when they return to our Services;
              </li>
              <li>
                Process payment for our Services;
              </li>
              <li>
                Facilitate customer benefits and services, including customer support;
              </li>
              <li>
                Identify and analyze how individuals use our Services;
              </li>
              <li>
                Conduct research and analytics on our customers and user base and our Services;
              </li>
              <li>
                Improve and customize our Services to address the needs and interests of our customer base and other individuals we interact with;
              </li>
              <li>
                Test, enhance, update and monitor the Services, or diagnose or fix technology problems;
              </li>
              <li>
                Help maintain the safety, security and integrity of our property and Services, technology assets and business;
              </li>
              <li>
                To enforce our Terms of Use, to resolve disputes, to carry out our obligations and enforce our rights, and to protect our business interests and the interests and rights of third parties;
              </li>
              <li>
                Prevent, investigate or provide notice of fraud or unlawful or criminal activity;
              </li>
              <li>
                Comply with contractual and legal obligations and requirements;
              </li>
              <li>
                To fulfill any other purpose for which you provide personal information; and
              </li>
              <li>
                For any other lawful purpose, or other purpose that you consent to.
              </li>
            </ul>
            <p>
              Where you choose to contact us, we may need additional information to fulfill the request or respond to inquiries. We may provide you with additional privacy-related information where the scope of the inquiry/request and/or personal information we require fall outside the scope of this Privacy Notice. In that case, the additional privacy notice will govern how we may process the information provided at that time.
            </p>
            <p>
              <b>Our Disclosure of Personal Information</b>
            </p>
            <p>
              We disclose personal information in the following ways:
            </p>
            <ul>
              <li>
                Marketing Providers: We may coordinate and share personal information with our marketing providers in order to communicate with individuals about the Services we make available.
              </li>
              <li>
                Customer Service and Communication Providers: We may share personal information with third parties who assist us in providing our customer services and facilitating our communications with individuals that submit inquiries.
              </li>
              <li>
                Other Service Providers: We may engage other third-party service providers to perform business or operational services for us or on our behalf, such as website hosting, infrastructure provisioning, IT services, analytics services, payment processing services and administrative services.
              </li>
              <li>
                Ad Networks and Advertising Relationships: We may work with third-party ad networks and advertising organizations to deliver advertising and personalized content on our Services, on other websites and services, and across other devices.
              </li>
              <li>
                Dog Owners or Prospective Dog Owners: With your permission, we display pedigree, health, and genetic information regarding registered dogs and litters.
              </li>
              <li>
                Legal Obligations and Rights: We may disclose personal information to third parties, such as legal advisors and law enforcement.
              </li>
            </ul>
            <p>
              <b>Control Over Your Information</b>
            </p>
            <p>
              You may control your information in the following ways:
            </p>
            <ul>
              <li>
                Access to Your Device Information: You may control the Services&apos; access to your device information through your “Settings” app on your device.
              </li>
              <li>
                Email Communications Preferences: You can stop receiving promotional email communications from us by clicking on the “unsubscribe” link provided in such communications.
              </li>
              <li>
                Push Notifications: You can stop receiving push notifications from us by changing your preferences in the iOS or Android notifications settings menu.
              </li>
              <li>
                Modifying or Deleting Your Information: If you have any questions about reviewing, modifying, or deleting your information, you can contact us directly at <a href="mailto:info@functionalbreeding.org">info@functionalbreeding.org</a>.
              </li>
              <li>
                Cookies: For information about our and our third-party relationships&apos; use of cookies and related technologies to collect information automatically, and any choices you may have in relation to cookies, please see the Information We Collect Automatically section above.
              </li>
              <li>
                Online Ads: To learn more about interest-based advertising and how you may be able to opt out of some of this advertising, you may wish to visit the Digital Advertising Alliance&apos;s (DAA) resources and/or the Network Advertising Initiative&apos;s (NAI) online resources.
              </li>
            </ul>
            <p>
              If you want to opt out of receiving online interest-based advertisements on mobile apps, please follow the instructions at http://www.aboutads.info/appchoices.
            </p>
            <p>
              Please note that when you opt out of receiving interest-based advertisements, this does not mean you will no longer see advertisements from us or on our online services. It means that the online ads that you do see from DAA program participants should not be based on your interests. We are not responsible for the effectiveness of, or compliance with, any third-parties&apos; opt-out options or programs or the accuracy of their statements regarding their programs. In addition, third parties may still use cookies to collect information about your use of our online services, including for analytics and fraud prevention as well as any other purpose permitted under the DAA&apos;s Principles.
            </p>
            <p>
              <b>Children&apos;s Personal Information</b>
            </p>
            <p>
              Our Services are not directed to, and we do not intend to, or knowingly, collect or solicit personal information from children under the age of 13. If an individual is under the age of 13, they should not use our Services or otherwise provide us with any personal information either directly or by other means. If a child under the age of 13 has provided personal information to us, we encourage the child&apos;s parent or guardian to contact us to request that we remove the personal information from our systems. If we learn that any personal information we collect has been provided by a child under the age of 13, we will promptly delete that personal information.
            </p>
            <p>
              <b>Links to Third-Party Websites or Services</b>
            </p>
            <p>
              Our Services may include links to third-party websites, plug-ins and applications. Except where we post, link to or expressly adopt or refer to this Privacy Notice, this Privacy Notice does not apply to, and we are not responsible for, any personal information practices of third-party websites and online services or the practices of other third parties. To learn about the personal information practices of third parties, please visit their respective privacy notices.
            </p>
            <p>
              <b>State-Specific Disclosures</b>
            </p>
            <ul>
              <li>
                California - Your California Privacy Rights: If you are a California resident, California Civil Code Section 1798.83 - also known as California&apos;s “Shine the Light” - permits you to request information regarding the disclosure of personal information to third parties for their direct marketing purposes during the immediately preceding calendar year.
              </li>
              <li>
                Nevada: If you are a resident of the State of Nevada, Chapter 603A of the Nevada Revised Statutes permits a Nevada resident to opt out of future sales of certain covered information that a website operator has collected or will collect about the resident.
              </li>
              <li>
                Colorado: If you are a resident of the State of Colorado, Colorado Revised Statutes §§ 6-1-1301 (effective 7/1/2023) - also known as the Colorado Privacy Act - provides you the right to access, correct, and delete your personal data, and the right to opt out of the sale, collection and use of your personal data.  To request access, correction, or deletion of your personal data, or to opt out of the sale, collection and use of your personal data, please contact us at <a href="mailto:info@functionalbreeding.org">info@functionalbreeding.org</a>.
              </li>
            </ul>
          </Typography>
        </Grid>
      </Grid>
    </>
  );
}
