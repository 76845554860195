import React from "react";
import { useTranslation } from "react-i18next";

// Recoil
import { useRecoilValue } from "recoil";
import { organizationsState } from "recoil/publicStates";

// MUI
import { Typography, Divider, Grid } from "@mui/material";

import { allOrganizations } from "constants/helpers";

// Components
import InfoBox from "components/homeComponents/InfoBox";
import OrganizationInfoBox from "components/organizationComponents/OrganizationInfoBox";

export default function Home() {
  const { t } = useTranslation();

  const allOrgs = useRecoilValue(organizationsState);
  
  return (
    <>
      <InfoBox />
      <Divider textAlign="left" sx={{ mb: 2, pt: 2, pb: 2 }}>
          <Typography variant="h2">{t("Member registries")}</Typography>
        </Divider>
      <Grid container direction={{ xs: "column", md: "row" }} spacing={5}>
        {allOrgs.map((org, index) => (
          <Grid item xs={12} md={6} key={index}>
            <OrganizationInfoBox org={org} />
          </Grid>
        ))}
      </Grid>
    </>
  );
}
