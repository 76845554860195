import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { Button, Link, ListItemText, Menu } from "@mui/material";
import { FormatListBulleted } from "@mui/icons-material";

import StyledMenuItem from "elements/styledMenuItem";
import { useAuth0 } from "@auth0/auth0-react";

export default function ManageMenu() {
  const { t } = useTranslation();
  const { isAuthenticated, loginWithRedirect } = useAuth0();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const items = [
    <StyledMenuItem key={0} divider component={Link} href="/mydogs">
      <ListItemText>{t("myDogs")}</ListItemText>
    </StyledMenuItem>,
    <StyledMenuItem key={1} divider component={Link} href="/mylitters">
      {t("myLitters")}
    </StyledMenuItem>,
    <StyledMenuItem key={2} component={Link} href="/registrations">
      {t("registrations")}
    </StyledMenuItem>,
  ];

  const handleClick = (event) => {
    if (isAuthenticated) {
      setAnchorEl(event.currentTarget);
    } else {
      loginWithRedirect();
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        name="manage"
        color="white"
        onClick={handleClick}
        startIcon={<FormatListBulleted />}
      >
        {t("manage")}
      </Button>
      <Menu id="manage" anchorEl={anchorEl} open={open} onClose={handleClose}>
        {items}
      </Menu>
    </>
  );
}
