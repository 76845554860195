import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

// import { useRecoilValue } from "recoil";

// MUI
import Typography from "@mui/material/Typography";
import {
  List,
  ListItem,
  ListItemText,
  ListItemButton,
  Paper,
} from "@mui/material";

const StyledListButton = styled(ListItemButton)(() => ({
  padding: 0,
}));

export function BreederCard({ breeder, organization, showAddedDate }) {
  let { t } = useTranslation();

  return (
    <Paper sx={{ mb: 3 }}>
      <List disablePadding>
        <StyledListButton href={breeder.breederId ? `/${organization.prefix}/breeder/${breeder.breederId}` : null}>
          <ListItem>
            <ListItemText
              sx={{ pl: { xs: 0, sm: 2 } }}
              primary={
                <Typography variant="h5">
                  {breeder?.name || t("Not disclosed")}
                </Typography>
              }
              secondary={breeder.breedingPrefix || t("No kennel name")}
            />
          </ListItem>
        </StyledListButton>
      </List>
    </Paper>
  );
}
