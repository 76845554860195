import React, { useState } from "react";
import { t } from "i18next";

// Components
import { getLitterColumns } from "components/adminDashboardComponents/adminDatagridColumns";

// Routes
import { createNewLitter } from "api/adminRoutes";

// MUI
import { Button, Grid, Stack, Typography } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { ChevronRight } from "@mui/icons-material";

// Recoil
import { snackBarState } from "recoil/globalStates";
import { adminAllUsersState, adminAllLittersState } from "recoil/adminStates";
import { useRecoilState, useRecoilValue } from "recoil";

export default function AdminAllLitters() {
  const [snackbarState, setSnackbarState] = useRecoilState(snackBarState);
  const [modalOpen, setModalOpen] = useState(false);

  const litters = useRecoilValue(adminAllLittersState);
  const users = useRecoilValue(adminAllUsersState);

  const handleRowClick = (params) => {
    window.location = `/admin/litter/${params.row._id}`;
  };

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Stack
            direction={{ xs: "column", sm: "row" }}
            justifyContent={{ xs: "center", sm: "space-between" }}
            alignItems="center"
            alignContent={"center"}
            spacing={{ xs: 2, sm: 0 }}
          >
            <Typography variant="h2">Registered litters</Typography>{" "}
            <Button
              variant="outlined"
              color="primary"
              endIcon={<ChevronRight />}
              href="/litter-registration"
            >
              {t("Add Registered Litter")}
            </Button>
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <DataGrid
            autoHeight
            pageSize={50}
            onRowClick={handleRowClick}
            rows={litters}
            getRowId={(row) => row._id}
            columns={getLitterColumns("litter")}
            components={{ Toolbar: GridToolbar }}
            disableSelectionOnClick
          />
        </Grid>
      </Grid>
    </>
  );
}
