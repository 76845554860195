import React from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import dayjs from "dayjs";

// Recoil
import { useRecoilState, useRecoilValue } from "recoil";
import { kruValuesState, userState } from "recoil/globalStates";
import { treedogAdditionState } from "recoil/registrationStates";

// MUI
import {
  Button,
  Divider,
  Grid,
  Typography,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
  TableContainer,
} from "@mui/material";
import { Launch } from "@mui/icons-material";

// Local
import {
  createHandleInputChange,
  getKruValueLabel,
  getNameByLang,
} from "constants/helpers";

export default function TreedogSummary() {
  const { t, i18n } = useTranslation();
  const language = i18n.language;

  const kruValues = useRecoilValue(kruValuesState);
  const [formData, setFormData] = useRecoilState(treedogAdditionState);
  const { attachments } = formData;
  const methods = useFormContext();
  const user = useRecoilValue(userState);
  
  let owner = formData.owner;
  if (owner === undefined || owner === null) {
    owner = user;
  }

  const pedCertAttach =
    attachments.pedigreeCert && attachments.pedigreeCert.some(item => Object.keys(item).length > 0);
  const miscAttach = attachments.misc && attachments.misc.some(item => Object.keys(item).length > 0);
  const imageAttach = attachments.profileImage && attachments.profileImage.some(item => Object.keys(item).length > 0);;

  const declaredTypes = () => {
    const breeds = kruValues.filter((i) => i.category === "ancestry" && i._id.startsWith("breeds"));
    const typeName = (type) => {
      return getNameByLang(type, breeds, language);
    };

    if (formData.declaredBreed) {
      return typeName(formData.declaredBreed);
    } else if (formData.declaredMix) {
      return typeName(formData.declaredMix);
    } else if (formData.declaredType1 && formData.declaredType2) {
      return `${typeName(formData.declaredType1)} / ${typeName(formData.declaredType2)} mix`;
    } else if (formData.declaredType1) {
      return `${typeName(formData.declaredType1)} mix`;
    }
    return "-";
  };

  const modifiers = []
  .concat(formData.alocusmodifiers || [])
  .concat(formData.merlemodifiers || [])
  .concat(formData.othermodifiers || []);
const markings = []
  .concat(formData.whitemarkings || [])
  .concat(formData.spots || [])
  .concat(formData.othermarkings || []);
const coatQualities = []
  .concat(formData.coatqualities || [])
  .concat(formData.coatcurliness || []);


  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="h2">{t("summary")}: {formData.name}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Divider textAlign="left" sx={{ my: 3 }}>
          <Typography variant="h3">{t("registry")}</Typography>
        </Divider>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="body1">{formData.registry?.name}</Typography>
      </Grid>
      <Grid item xs={6}>
        <Button
          href={formData.registry?.url}
          target="_blank"
          endIcon={<Launch />}
        >
          {t("registryGuidelines")}
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Divider textAlign="left" sx={{ my: 3 }}>
          <Typography variant="h3">{t("Treedog information")}</Typography>
        </Divider>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{t("dob")}</TableCell>
                <TableCell>{t("countryOfOrigin")}</TableCell>
                <TableCell>{t("microchipNumber")}</TableCell>
                <TableCell>{t("Breed")}</TableCell>
                <TableCell>{t("height")}</TableCell>
                <TableCell>{t("weight")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>
                  {formData.dateOfBirth ? dayjs(formData.dateOfBirth).format("MM.DD.YYYY") : t("notProvided")}                </TableCell>
                <TableCell>
                  {formData.countryOfOrigin?.[language] || "-"}
                </TableCell>
                <TableCell>
                  {formData.microchipNumber}
                </TableCell>
                <TableCell>
                  {declaredTypes()}
                </TableCell>
                <TableCell>
                  {formData.height || "-"}
                </TableCell>
                <TableCell>
                  {formData.weight || "-"}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>

      <Grid item xs={12}>
      <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{t("color")}</TableCell>
                <TableCell>{t("Modifiers")}</TableCell>
                <TableCell>{t("markings")}</TableCell>
                <TableCell>{t("coatType")}</TableCell>
                <TableCell>{t("coatQualities")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>
                  {getKruValueLabel(formData.color, language) || "-"}
                </TableCell>
                <TableCell>
                  {(modifiers &&
                    modifiers.map((v, i) => {
                      return (
                        <div key={i}>
                          {getKruValueLabel(v, language)}
                          <br />
                        </div>
                      );
                    })) ||
                    "-"}
                </TableCell>
                <TableCell>
                  {(markings &&
                    markings.map((v, i) => {
                      return (
                        <div key={i}>
                          {getKruValueLabel(v, language)}
                          <br />
                        </div>
                      );
                    })) ||
                    "-"}
                </TableCell>
                <TableCell>
                  {getKruValueLabel(formData.coattype, language) || "-"}
                </TableCell>
                <TableCell>
                  {(coatQualities &&
                    coatQualities.map((v, i) => {
                      return (
                        <div key={i}>
                          {getKruValueLabel(v, language)}
                          <br />
                        </div>
                      );
                    })) ||
                    "-"}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        </Grid>

      <Grid item xs={12}>
        <Divider textAlign="left" sx={{ my: 3 }}>
          <Typography variant="h3">{t("attachments")}</Typography>
        </Divider>
        <TableContainer>
          <Table>
            {pedCertAttach || miscAttach || imageAttach ? (
              <TableBody>
                {pedCertAttach && (
                  <TableRow>
                    <TableCell>{t("Pedigree")}</TableCell>
                    <TableCell>
                      {attachments.pedigreeCert.map((a, i) => (
                        <p key={i}>{a.name}</p>
                      )) || "-"}
                    </TableCell>
                  </TableRow>
                )}
                {imageAttach > 0 && (
                  <TableRow>
                    <TableCell>{t("Images")}</TableCell>
                    <TableCell>
                      {attachments.profileImage.map((a, i) => (
                        <p key={i}>{a.name}</p>
                      )) || "-"}
                    </TableCell>
                  </TableRow>
                )}
                {miscAttach && (
                  <TableRow>
                    <TableCell>{t("otherAttachments")}</TableCell>
                    <TableCell>
                      {attachments.misc.map((a, i) => (
                        <p key={i}>{a.name}</p>
                      )) || "-"}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            ) : (
              <TableBody>
                <TableRow>
                  <TableCell>{t("noAttachments")}</TableCell>
                </TableRow>
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </Grid>
      
      </Grid>
  );
}
