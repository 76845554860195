import React from 'react';

// Recoil
import { useRecoilState } from 'recoil';
import { snackBarState } from 'recoil/globalStates';

import { Snackbar, Alert } from '@mui/material';

export const SnackbarManager = () => {
  const [snackbarState, setSnackbarState] = useRecoilState(snackBarState);

  return (
    <Snackbar
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      open={snackbarState.open}
      onClose={() => setSnackbarState({ ...snackbarState, open: false })}
    >
      <Alert
        severity={snackbarState.severity}
        onClose={() => setSnackbarState({ ...snackbarState, open: false })}
      >
        {snackbarState.message}
      </Alert>
    </Snackbar>
  );
};
