import React from "react";
import { useTranslation } from "react-i18next";

// Components
import PendingLittersWidget from "./dashboardWidgets/pendingLitters";
import PendingDogsWidget from "./dashboardWidgets/pendingDogs";

// MUI
import { Grid, Divider, Typography } from "@mui/material";

function AdminDashboard() {
  const { t } = useTranslation();
  return (
    <Grid
      container
      direction={{ xs: "column", sm: "row" }}
      spacing={1}
      justifyContent="center"
    >
      <Grid item xs={12}>
        <Divider textAlign="left" sx={{ mb: 2 }}>
          <Typography variant="h2">{t("admindashboard")}</Typography>
        </Divider>
      </Grid>
      <Grid item xs={12}>
        <PendingLittersWidget />
        <PendingDogsWidget />
      </Grid>
    </Grid>
  );
}

export default AdminDashboard;
