import React from 'react';
import { FormControlLabel, Checkbox, Typography } from '@mui/material';
import { getNameByLang } from 'constants/helpers';

export const CoatCheckbox = ({ category, kruValues, language, field, handleInputChange }) => {
    const items = kruValues
    .filter(item => item.category === category && !item.aliasFor)
    .map(item => {
      const aliases = kruValues
        .filter(aliasItem => aliasItem.aliasFor === item._id)
        .map(aliasItem => getNameByLang(aliasItem, language))
        .join(", ");
      
      return {
        value: item._id,
        key: getNameByLang(item, language),
        label: `${getNameByLang(item, language).charAt(0).toUpperCase() + getNameByLang(item, language).slice(1)}${aliases ? ` (${aliases})` : ''}`,
        sort: item.sort ?? 0,
      };
    })
    .sort((a, b) => a.sort - b.sort);

  return (
    <>
      {items.map(item => (
        <FormControlLabel
          key={item.key}
          control={
            <Checkbox
              id={item.value}
              checked={field?.value?.includes(item.value)}
              onChange={(e) => {
                const newValue = e.target.checked
                  ? [...field?.value, item.value]
                  : field.value.filter((val) => val !== item.value);
                field.onChange(newValue);
                handleInputChange({ target: { name: field.name, value: newValue } });
              }}
            />
          }
          label={<Typography variant="body">{item.label}</Typography>}
        />
      ))}
    </>
  );
};
