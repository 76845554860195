export const Pedigree4g = (dog) => {
  const getParents = (dog) => {
    let parents = [];
    if (dog.sire) {
      const sire = {
        name: dog.sire.name,
        _id: dog.sire._id,
        regNumber: dog.sire.regNumber || null,
        regType: dog.sire.regType || null,
        primaryImage: dog.sire.primaryImage || false,
        organizations: dog.sire.organizations || [],
        children: getParents(dog.sire),
      };
      parents.push(sire);
    }
    if (dog.dam) {
      const dam = {
        name: dog.dam.name,
        _id: dog.dam._id,
        regNumber: dog.dam.regNumber || null,
        regType: dog.dam.regType || null,
        primaryImage: dog.dam.primaryImage || false,
        organizations: dog.dam.organizations || [],
        children: getParents(dog.dam),
      };
      parents.push(dam);
    }
    return parents;
  };

  return {
    name: dog?.name || "",
    _id: dog?._id || null,
    regNumber: dog?.regNumber || null,
    primaryImage: dog?.primaryImage || false,
    organizations: dog?.organizations || [],
    children: getParents(dog),
  };
};
