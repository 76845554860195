import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

// MUI
import { Typography, Divider, Grid } from "@mui/material";


function useMetaTags(title, content) {
  useEffect(() => {
    // Set the page title
    document.title = title;

    // Create and append the meta tag for 'robots'
    const metaRobots = document.createElement("meta");
    metaRobots.name = "robots";
    metaRobots.content = content;
    document.head.appendChild(metaRobots);

    // Cleanup function to remove the meta tag when the component unmounts
    return () => {
      document.head.removeChild(metaRobots);
    };
  }, [title, content]); // Only re-run the effect if title or content changes
}

export default function NotFound() {
  // Use the custom hook to set meta tags
  useMetaTags("404 - Page Not Found", "noindex");

  const { t } = useTranslation();

  return (
    <>
      <Grid container direction={{ xs: "column", md: "row" }} spacing={5}>
        <Grid item xs={7}>
          <Divider textAlign="left" sx={{ mb: 2 }}>
            <Typography variant="h2">{t("Page not found")}</Typography>
          </Divider>
          <Typography variant="body1">{t("That page does not exist - did you follow a bad link?")}</Typography>
        </Grid>
      </Grid>
    </>
  );
}