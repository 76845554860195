import React from "react";
import { styled } from "@mui/material";

// Recoil
import { useRecoilValue } from "recoil";
import { loadingState } from 'recoil/globalStates';

// MUI
import { CircularProgress, Backdrop, Box } from "@mui/material";

const StyledCircularProgress = styled(CircularProgress)(({ theme }) => ({
  position: "absolute",
  left: "50%",
  top: "50%",
  transform: "translate(-50%, -50%)",
  zIndex: 99999,
}));

const StyledBlockCircularProgress = styled(CircularProgress)(({ theme }) => ({
  margin: 20,
  zIndex: 99999,
}));

const StyledBackdrop = styled(Backdrop)(({ theme }) => ({
  backgroundColor: "#0000005c",
  position: "fixed",
  width: "100%",
  height: "100%",
  top: "0",
  left: "0",
  zIndex: "9999",
}));

export function Loader() {
  return <StyledCircularProgress color="secondary" size={70} />;
}

export function LoaderBlock() {
  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        {" "}
        <Loader />
      </Box>
    </>
  );
}

export function LoaderOverlayMain() {
  const loading = useRecoilValue(loadingState);
  if (loading) return null; // don't conflict if someone else is using this
  return (
    <StyledBackdrop open={true}>
      <Loader />
    </StyledBackdrop>
  );
}

export function LoaderOverlay() {
  const loading = useRecoilValue(loadingState);
  return (
    <StyledBackdrop open={loading}>
      <Loader />
    </StyledBackdrop>
  );
}

export default Loader;
