import React, { useState } from "react";

import { useParams } from "react-router-dom";

import { Grid, Paper } from "@mui/material";

import VerifyForm from "components/verifyComponents/verifyForm";
import VerifySuccessComponent from "components/verifyComponents/verifySuccessComponent";
import { useRecoilValue } from "recoil";
import { verificationByIdState } from "recoil/privateStates";

export default function Verify() {
  const { _id } = useParams("_id");
  const [error, setError] = useState(false);

  const initVerification = useRecoilValue(verificationByIdState(_id));

  const [verification, setVerification] = useState(initVerification);

  return (
    <>
      <Paper sx={{ p: 4 }}>
        {verification?.verified ? (
          <Grid container>
            <VerifySuccessComponent verification={verification} />
          </Grid>
        ) : (
          <VerifyForm
            verification={verification}
            setVerification={setVerification}
            error={error}
            setError={setError}
          />
        )}
      </Paper>
    </>
  );
}
