import React from "react";
import { useTranslation } from "react-i18next";

// Recoil
import { snackBarState } from "recoil/globalStates";
import { selectedDocByIdState } from "recoil/adminStates";
import { useRecoilState, useRecoilValue } from "recoil";

// Routes
import { updateUser } from "api/adminRoutes";

// Components
import UserForm from "../forms/userForm";

import { AdminNotFound } from "components/adminDashboardComponents/adminNotFound";

function AdminUserView() {
  const { t } = useTranslation();
  const [snackbarState, setSnackbarState] = useRecoilState(snackBarState);

  const user = useRecoilValue(selectedDocByIdState("user"));

  const handleSubmit = (accessToken, updatedUser, id) => {
    const updateUserData = async (accessToken, updatedUser, id) => {
      await updateUser(id, updatedUser, { accessToken }).then((res) => {
        if (res.error) {
          setSnackbarState({
            message: res.message,
            severity: "error",
            open: true,
          });
        } else {
          setSnackbarState({
            message: t("updateSuccess", { docType: "User" }),
            severity: "success",
            open: true,
          });
          setTimeout(() => {
            window.location.reload(true);
          }, 1000);
        }
      });
    };
    updateUserData(accessToken, updatedUser, id);
  };

  return user ? (
    <UserForm action="edit" user={user} emitHandleSubmit={handleSubmit} />
  ) : (
    <AdminNotFound docType="User" />
  );
}

export default AdminUserView;
