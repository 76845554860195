import React, { Component } from "react";

// MUI
import { Avatar } from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";

import { imageServer } from "constants/values";

export default class DogAvatar extends Component {
  render() {
    const primaryImage = this.props.primaryImage;
    const dogId = this.props.dogId;
    const alt = this.props.alt;

    return (
      <>
        {primaryImage ? (
          <Avatar
            sx={{ bgcolor: "primary.main", width: 50, height: 50 }}
            alt={alt}
            src={imageServer + "/images/" + primaryImage}
            variant="rounded"
          />
        ) : (
          <Avatar
            sx={{ bgcolor: "primary.main", width: 50, height: 50 }}
            alt={alt}
            variant="rounded"
          >
            {alt ? alt?.charAt(0) : <CheckBoxOutlineBlankIcon />}
          </Avatar>
        )}
      </>
    );
  }
}
