import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

// Recoil
import { useRecoilState, useRecoilValue } from "recoil";
import { loadingState, snackBarState } from "recoil/globalStates";
import { selectedDocByIdState } from "recoil/adminStates";
import { attachmentsModState } from "recoil/adminStates";

// Routes
import { updateDog } from "api/adminRoutes";
import { addAttachment, deleteAttachment } from "api/privateRoutes";
// import { getAttachments } from "api/adminRoutes";
// import { getAttachments } from "api/publicRoutes";

// Components
import DogForm from "../forms/dogForm";
import { AdminNotFound } from "components/adminDashboardComponents/adminNotFound";

function AdminDogView() {
  const { t } = useTranslation();
  const [snackbarState, setSnackbarState] = useRecoilState(snackBarState);
  const [loading, setLoading] = useRecoilState(loadingState);
  const [attachmentsMod, setAttachmentsMod] = useRecoilState(attachmentsModState);
  const dog = useRecoilValue(selectedDocByIdState("dog"));

  const updateDogAttachments = async (accessToken, id) => {
    if (attachmentsMod.length > 0) {
      // Walk through array of attachments. If add is true, add attachment to database.
      // If delete is true, delete attachment from database.
      try {
        for (const attachment of attachmentsMod) {
          if (attachment.get("add") === "true") {
            attachment.set(`parentDocType`, `dog`);
            attachment.set(`parentDoc`, id);
            const res = await addAttachment(attachment, { accessToken });
            if (res.error) {
              return res;
            } else {
              attachment.set("fileName", res.fileName);
            }
          } else if (attachment.get("delete") === "true") {
            const res = await deleteAttachment(attachment, { accessToken });
            if (res.error) {
              return res;
            }
          }
        }

        const primaryImages = attachmentsMod.filter((attachment) => {
          return attachment.get("primary") === "true" && attachment.get("delete") !== "true";
        });
        if (primaryImages.length > 1) {
          return { error: true, message: "Multiple primary attachments" };
        } else if (primaryImages.length === 1) {
          return primaryImages[0].get("fileName");
        } else {
          return null;
        }
      } catch (error) {
        console.log("Failed to handle attachment:", error);
      }
    }
  };

  const handleSubmit = async (accessToken, updatedDog, id) => {
    setLoading(true);
    const attachmentResponse = await updateDogAttachments(accessToken, id);
    if (attachmentResponse?.error) {
      setSnackbarState({
        message: "Attachment not updated: " + attachmentResponse.message,
        severity: "error",
        open: true,
      });
      setLoading(false);
      return;
    }

    updatedDog.primaryImage = attachmentResponse;

    const updateResponse = await updateDog(id, updatedDog, { accessToken });
    if (updateResponse?.error) {
      setSnackbarState({
        message: "Dog not updated: " + updateResponse.message,
        severity: "error",
        open: true,
      });
      setLoading(false);
      return;
    }

    setLoading(false);
    window.location.reload(true);
  };


  return dog ? (
    
    <DogForm
      dog={dog}
      emitHandleSubmit={handleSubmit}
    />
  ) : (
    <AdminNotFound docType="Dog" />
  );
}

export default AdminDogView;
