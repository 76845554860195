import { selector, selectorFamily } from "recoil";
import {
  getAllUsers,
  getAllOrganizations,
  getBreederById,
  getBreederByQuery,
  getBreedersWithLitters,
  getCertificateById,
  getDbStats,
  getDogById,
  getDogByOrgRegNumber,
  getDogByRegNumber,
  getDogs,
  getGeneticTestTypeById,
  getGeneticTestTypes,
  getLitterById,
  getLitterBySiblingId,
  getLitters,
  getLittersByBreederId,
  getLittersByParentId,
  getOrganizationById,
  getOrganizationByPrefix,
  getOrganizationDogs,
  getOrganizationLitters,
  getPendingDogs,
  getResultProviderById,
  getResultproviders,
  getTreedogs,
} from "api/publicRoutes";
import { kruValuesState } from "./globalStates";
import { get } from "react-hook-form";

// STATISTICS FOR FRONT PAGE
export const dbStatsState = selector({
  key: "dbStatsState",
  get: async () => {
    const response = await getDbStats();
    if (response.error) {
      throw response;
    }
    return response.stats;
  },
});

// ORG BREEDERS FOR ORG PAGE
export const organizationBreedersState = selectorFamily({
  key: "organizationBreedersState",
    get: (org) => async ({get}) => {
      const options = { 
        sort: { name: 1 },
        populate: "organizations.organization",
      };
      const query =  {
          'organizations': {
            $elemMatch: {
              'organization': org._id,
              'displayBreeder': true
            }
          }
        };
   try {
      return await getAllUsers({ body: { options, query } });
    } catch (error) {
      if (error.isLoading) {
        throw error.promise;
        } else {
        throw error;
      }
    }
  },
});

// ORG DOGS FOR ORG PAGE
export const organizationDogsState = selectorFamily({
  key: "organizationDogsState",
  get: (org) => async ({get}) => {
    const options = {
      sort: { name: 1 },
      populate: "organizations.organization",
    };
    try {
      const response = await getOrganizationDogs({ org, options });
      return response;
    } catch (error) {
      if (error.isLoading) {
        throw error.promise;
        } else {
        throw error;
      }
    }
  },
});
      
// NEWEST DOGS FOR ORG PAGE
export const newestOrgDogsState = selectorFamily({
  key: "newestOrgDogsState",
  get: (org) => async ({get}) => {
    const options = { limit: 10, sort: { createdAt: -1 }, populate: "organizations.organization"};
    try {
      const response = await getOrganizationDogs({ org, options });
      return response;
    } catch (error) {
      if (error.isLoading) {
        throw error.promise;
        } else {
        throw error;
      }
    }
  },
});

// NEWEST DOGS FOR FRONT PAGE
export const newestDogsState = selector({
  key: "newestDogsState",
  get: async () => {
    const options = { limit: 10, sort: { createdAt: -1 } };
    const response = await getDogs({ options });
    if (response.error) {
      throw response;
    }
    return response;
  },
});

// ORG LITTERS FOR ORG PAGE
export const organizationLittersState = selectorFamily({
  key: "organizationLittersState",
  get: (org) => async ({get}) => {
    const options = {
      sort: { name: 1 },
      populate: "organizations.organization",
    };
    try {
      const response = await getOrganizationLitters({ org, options });
      return response;
    } catch (error) {
      if (error.isLoading) {
        throw error.promise;
        } else {
        throw error;
      }
    }
  },
});
      

// NEWEST LITTERS FOR FRONT PAGE
export const newestLittersState = selector({
  key: "newestLittersState",
  get: async () => {
    const options = { limit: 10, sort: { createdAt: -1 } };
    const response = await getLitters({ options });
    if (response.error) {
      throw response;
    }
    return response;
  },
});

// NEWEST DOGS FOR ORG PAGE
export const newestOrgLittersState = selectorFamily({
  key: "newestOrgLittersState",
  get: (org) => async ({get}) => {
    const options = {
      limit: 10,
      sort: { createdAt: -1 },
      populate: "sire dam organizations.organization sire.organizations.organization dam.organizations.organization"
    };
    try {
      return await getOrganizationLitters({ org, options });
      } catch (error) {
      if (error.isLoading) {
        throw error.promise;
        } else {
        throw error;
      }
    }
  },
});

// DOG BY ID OR REGNUMBER
export const currentDogState = selectorFamily({
  key: "currentDogState",
  get: (id) => async () => {
    const reqFunc = id.length < 12 ? getDogByRegNumber : getDogById;
    const response = await reqFunc(id);
    if (response.error) {
      throw response;
    }
    return response;
  },
});

// DOG BY ORG REGNUMBER
export const currentDogOrgState = selectorFamily({
  key: "currentDogOrgState",
  get: ({id, organization}) => async () => {
    const response = await getDogByOrgRegNumber({regNumber: id, organization: organization});
    if (response?.error) {
      throw response;
    }
    return response;
  },
});

// BREEDER BY ID
export const breederState = selectorFamily({
  key: "breederState",
  get: (id) => async () => {
    const options = { populate: "certificates.certificate" };
    const response = await getBreederById(id, { options });
    if (response.error) {
      throw response;
    }
    return response;
  },
});

// BREEDER BY BREEDERID
export const breederIdState = selectorFamily({
  key: "breederIdState",
  get: (id) => async () => {
    const options = { populate: "certificates.certificate" };
    const query = { "breederId": id };
    const response = await getBreederByQuery( { body: {options, query }});
    if (response.error) {
      throw response;
    }
    return response[0];
  },
});

// LITTER BY ID
export const litterByIdState = selectorFamily({
  key: "litterByIdState",
  get: (id) => async () => {
    const response = await getLitterById(id);
    if (response.error) {
      throw response;
    }
    return response;
  },
});

// GET LITTERS BY BREEDER ID
export const littersByBreederIdState = selectorFamily({
  key: "littersByBreederIdState",
  get: (id) => async () => {
    const response = await getLittersByBreederId(id);
    if (response.error) {
      throw response;
    }
    return response;
  },
});

// GET PARENTS LITTERS BY DOG ID
export const littersByParentIdState = selectorFamily({
  key: "littersByParentIdState",
  get:
    ({ id, sex }) =>
    async () => {
      const response = await getLittersByParentId(id, sex);
      if (response.error) {
        throw response;
      }
      return response;
    },
});

// GET LITTER BY SIBLINGS ID
export const littersBySiblingIdState = selectorFamily({
  key: "littersBySiblingIdState",
  get: (id) => async () => {
    const response = await getLitterBySiblingId(id);
    if (response.error) {
      throw response;
    }
    return response[0]?.puppies?.filter((puppy) => puppy._id !== id);
  },
});

// GET ALL ORGANIZATIONS
export const organizationsState = selector({
  key: "organizationsState",
  get: async () => {
    const response = await getAllOrganizations();
    if (response.error) {
      throw response;
    }
    return response;
  },
});

// Filter out organizations that have stripe information. Required for registration.
export const stripeOrganizations = selector({
  key: "stripeOrganizationsState",
  get: async ({ get }) => {
    const allOrganizations = get(organizationsState);
    return allOrganizations.filter((o) => !!o?.stripe?.account_id);
  },
});

// GET ORGANIZATION BY ID
export const getOrgByIdState = selectorFamily({
  key: "getOrgByIdState",
  get: (id) => async () => {
    const response = await getOrganizationById(id);
    if (response?.error) {
      throw response;
    }
    return response;
  },
});

// GET ORGANIZATION BY PREFIX
export const getOrgByPrefixState = selectorFamily({
  key: "getOrgByPrefixState",
  get: (prefix) => async () => {
    const response = await getOrganizationByPrefix(prefix);
    if (response?.error) {
      throw response;
    }
    return response;
  },
});

// GET CERTIFICATE BY ID
export const getCertByIdState = selectorFamily({
  key: "getCertByIdState",
  get: (id) => async () => {
    const response = await getCertificateById(id);
    if (response.error) {
      throw response;
    }
    return response;
  },
});

// GET RESULTPROVIDER BY ID
export const getResultProviderByIdState = selectorFamily({
  key: "getResultProviderByIdState",
  get: (id) => async () => {
    const response = await getResultProviderById(id);
    if (response.error) {
      throw response;
    }
    return response;
  },
});
// GET GENETIC TEST TYPE BY ID
export const getGeneticTestTypeByIdState = selectorFamily({
  key: "getGeneticTestTypeByIdState",
  get: (id) => async () => {
    const response = await getGeneticTestTypeById(id);
    if (response.error) {
      throw response;
    }
    return response;
  },
});

// POPULATE CERTIFICATES ARRAY
export const populatedCertsArrayState = selectorFamily({
  key: "populatedCertsArrayState",
  get:
    (certs) =>
    async ({ get }) => {
      if (!certs) {
        return "";
      }
      return certs.map((cert) => {
        return {
          ...cert,
          certificate: get(getCertByIdState(cert.certificate)),
        };
      });
    },
});

// POPULATE KRUVALUES ARRAY
export const populatedKruvalueArrayState = selectorFamily({
  key: "populatedKruvalueArrayState",
  get:
    (values) =>
    async ({ get }) => {
      const kruValues = get(kruValuesState);
      if (!values) {
        return "";
      }
      return values.map((value) => kruValues.find((v) => v._id === value));
    },
});

// GET ALL DOGS
export const allDogsState = selector({
  key: "allDogsState",
  get: async () => {
    const options = {
      limit: 0,
      populate: "organizations.organization",
    };
    const response = await getDogs({ options });
    if (response.error) {
      throw response;
    }
    return response;
  },
});

// GET ALL PENDING DOGS
export const allPendingDogsState = selector({
  key: "allPendingDogsState",
  get: async () => {
    const options = {
      limit: 0,
      populate: "organizations.organization",
    };
    const response = await getPendingDogs({ options });
    if (response.error) {
      throw response;
    }
    return response;
  },
});

// GET ALL TREEDOGS
export const allTreedogsState = selector({
  key: "allTreedogsState",
  get: async () => {
    const options = {
      limit: 0,
      populate: "organizations.organization certificates.certificate",
    };
    const response = await getTreedogs({ options });
    if (response.error) {
      throw response;
    }
    return response;
  },
});

// GET DOG BY ID
export const dogByIdState = selectorFamily({
  key: "dogByIdState",
  get:
    (id) =>
    ({ get }) => {
      const dogs = get(allDogsState);
      const found = dogs.find((c) => c._id === id);
      if (!found) {
        return null;
      }
      return found;
    },
});

// GET PENDING DOG BY ID
export const pendingDogByIdState = selectorFamily({
  key: "pendingDogByIdState",
  get:
    (id) =>
    ({ get }) => {
      const dogs = get(allPendingDogsState);
      const found = dogs.find((c) => c._id === id);
      if (!found) {
        return null;
      }
      return found;
    },
});

// GET TREEDOG BY ID
export const treedogByIdState = selectorFamily({
  key: "treedogByIdState",
  get:
    (id) =>
    ({ get }) => {
      const dogs = get(allTreedogsState);

      const found = dogs.find((c) => c._id === id);
      if (!found) {
        return null;
      }
      return found;
    },
});

// GET GENERIC DOG BY ID (dog of unknown type)
export const genericDogByIdState = selectorFamily({
  key: "genericDogByIdState",
  get:
    (id) =>
    ({ get }) => {
      const dogs = get(allDogsState).concat(get(allTreedogsState)).concat(get(allPendingDogsState));
      const found = dogs.find((c) => c._id === id);
      if (!found) {
        return null;
      }
      return found;
    },
});

// GET DOG BY OWNER ID
// FIXME filter on db side
export const dogsByOwnerIdState = selectorFamily({
  key: "dogsByOwnerIdState",
  get:
    (id) =>
    ({ get }) => {
      const dogs = get(allDogsState);
      return dogs.filter((dog) => dog.owners.some(owner => owner === id || owner._id === id));
    },
});

// FILTER DOGS BY GENDER
export const dogsBySexState = selectorFamily({
  key: "dogsBySexState",
  get:
    (sex) =>
    ({ get }) => {
      const dogs = get(allDogsState);
      return dogs.filter((dog) => dog.sex === sex);
    },
});

export const treedogsBySexState = selectorFamily({
  key: "treedogsBySexState",
  get:
    (sex) =>
    ({ get }) => {
      const dogs = get(allTreedogsState);
      return dogs.filter((dog) => dog.sex === sex);
    },
});

// GET ALL LITTERS
export const allLittersState = selector({
  key: "allLittersState",
  get: async () => {
    const options = { limit: 0 };
    const response = await getLitters({ options });
    if (response.error) {
      throw response;
    }
    return response;
  },
});

// GET ALL BREEDERS THAT HAVE LITTERS
export const allBreedersState = selector({
  key: "allBreedersState",
  get: async () => {
    const options = { populate: "" };
    const response = await getBreedersWithLitters({ options });
    if (response.error) {
      throw response;
    }
    return response;
  },
});

// GET ALL RESULT PROVIDERS
export const allResultProvidersState = selector({
  key: "allResultProvidersState",
  get: async () => {
    const options = {
      limit: 0,
    };
    const response = await getResultproviders({ options });
    if (response.error) {
      throw response;
    }
    return response;
  },
});

// GET ALL GENETIC TEST TYPES
export const allGeneticTestTypesState = selector({
  key: "allGeneticTestTypesState",
  get: async () => {
    const options = {
      limit: 0,
    };
    const response = await getGeneticTestTypes({ options });
    if (response.error) {
      throw response;
    }
    return response;
  },
});
