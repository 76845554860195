import React from "react";
import { useState } from "react";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";

//MUI
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import OutlinedInput from "@mui/material/OutlinedInput";
import { InputAdornment } from "@mui/material";

const StyledInput = styled(OutlinedInput)(({ theme }) => ({
  color: theme.palette.primary,
  backgroundColor: "#ffffff63",
  transition: "ease 0.3s",
  "&:hover .MuiOutlinedInput-notchedOutline": {
    border: "none",
  },
  "&:hover": {
    transition: "ease 0.3s",
    backgroundColor: "#ffffff7d",
  },
  "&:active": {
    transition: "ease 0.3s",
    backgroundColor: "#ffffff7d",
  },
}));

export default function StyledSearchField() {
  const { t } = useTranslation();
  const [searchQuery, setSearchQuery] = useState("");
  const handleClick = () => {
    window.location = `/search/?searchQuery=${searchQuery}`;
  };
  const handleChange = (e) => {
    setSearchQuery(e.target.value);
  };
  return (
    <StyledInput
      fullWidth
      size="small"
      type="text"
      name="searchQuery"
      value={searchQuery || ""}
      onChange={handleChange}
      onKeyDown={(event) => {
        if (event.key === "Enter") {
          handleClick();
        }
      }}
      placeholder={t("searchPlaceholder")}
      endAdornment={
        <InputAdornment position="end">
          <IconButton onClick={handleClick} sx={{ color: "#ffffff" }}>
            <SearchIcon />
          </IconButton>
        </InputAdornment>
      }
    />
  );
}
