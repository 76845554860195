import React, { useEffect, useState } from "react";
import Tree from "react-d3-tree";

// API
import { getDogWithPedigree } from "api/publicRoutes";

// DATA
// import { imageServer } from "constants/values";

// COMPONENTS
// import PlaceholderImage from "images/placeholder.png";

import { Typography } from "@mui/material";
import { Pedigree4g } from "./pedigreeDataTemplate";
import { useTranslation } from "react-i18next";

export default function PedigreeTable({
  generations = 4,
  emphasizeFirst = 1,
  imageInFirst = 1,
  dog: rootDog,
  organization,
  nodeSize,
  translate,
  zoom,
  renderCustomNodeElement,
}) {
  const { _id } = rootDog;
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [dog, setDog] = useState();

  useEffect(() => {
    if (!loading && _id != dog?._id) return setLoading(true);
    if (!loading || !_id) return;
    getDogWithPedigree(_id, { maxDepth: generations })
      .then((res) => setDog(res))
      .catch((error) => console.error(error))
      .finally(() => setLoading(false));
  }, [loading, rootDog?._id]);

  if (loading) return <Typography>Loading...</Typography>;

  const hasPedigree = !!dog?.sire || !!dog?.dam;
  const gens = groupByGeneration(dog, generations);
  const data = Pedigree4g(dog);

  return (
    <>
      {hasPedigree ? (
        <>
          <Tree
            pathFunc="step"
            orientation="horizontal"
            nodeSize={nodeSize}
            separation={{ siblings: 2, nonSiblings: 2 }}
            zoom={zoom}
            translate={translate}
            renderCustomNodeElement={renderCustomNodeElement}
            data={data}
          />
          {/* <Container>
            <Row
              generations={gens}
              emphasizeFirst={emphasizeFirst}
              imageInFirst={imageInFirst}
            />
          </Container> */}
        </>
      ) : (
        <Typography variant="caption" align="center">
          {t("noPedigree")}
        </Typography>
      )}
    </>
  );
}

function groupByGeneration(
  dog,
  generations,
  generationsArray = [],
  iteration = 0
) {
  if (iteration === generations) return generationsArray;
  if (!generationsArray[iteration]) generationsArray[iteration] = [];

  const sire = dog?.sire || {};
  const dam = dog?.dam || {};

  generationsArray[iteration].push(sire);
  generationsArray[iteration].push(dam);

  generationsArray = groupByGeneration(
    sire,
    generations,
    generationsArray,
    iteration + 1
  );
  generationsArray = groupByGeneration(
    dam,
    generations,
    generationsArray,
    iteration + 1
  );
  return generationsArray;
}
