import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

import { Typography, Grid, Divider, Paper, Button } from "@mui/material";
import { getCertificateById } from "api/publicRoutes";
import { Launch, WorkspacePremium } from "@mui/icons-material";

export default function CertificatesData({ userPerson }) {
  const { t } = useTranslation();
  const [certs, setCerts] = useState();

  useEffect(() => {
    let promiseArray = [];
    userPerson.certificates.forEach((c) => {
      promiseArray.push(getCertificateById(c.certificate));
    });
    Promise.all(promiseArray).then((vals) => {
      const mappedCerts = userPerson.certificates.map((c, i) => ({
        certificate: vals[i],
        issuingDate: c.issuingDate,
      }));
      setCerts(mappedCerts);
    });
  }, [userPerson]);

  return (
    <>
      <Grid item xs={12}>
        <Divider textAlign="left" sx={{ mt: 3 }}>
          <Typography
            variant="h2"
            sx={{ display: "flex", alignItems: "flex-start" }}
          >
            <WorkspacePremium sx={{ mr: 1 }} /> {t("yourCertificates")}
          </Typography>
        </Divider>
      </Grid>
      <Grid item xs={12}>
        {certs && certs.length > 0 ? (
          certs.map((cert, i) => (
            <Paper key={i} variant="outlined" sx={{ p: 2 }}>
              <Grid
                container
                spacing={3}
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item>
                  <Typography variant="body">
                    {cert?.certificate.name}
                  </Typography>
                </Grid>

                <Grid item>
                  <Typography variant="body">
                    {t("issuingDate")}{" "}
                    {dayjs(cert?.issuingDate).format("MM.DD.YYYY")}
                  </Typography>
                </Grid>
                <Grid item>
                  <Button
                    href={cert?.certificate.url}
                    target="_blank"
                    endIcon={<Launch />}
                  >
                    {t("website")}
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          ))
        ) : (
          <Typography variant="body">{t("noCertificates")}</Typography>
        )}
      </Grid>
    </>
  );
}
