import React from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

// Recoil
import { getOrgByPrefixState, organizationDogsState } from "recoil/publicStates";
import { useRecoilValue } from "recoil";

// MUI
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Typography, Paper } from "@mui/material";

// Components
import { GetDogColumns } from "components/datagridColumns/DogColumns";
import OrganizationInfoBox from "components/organizationComponents/OrganizationInfoBox";

export default function DogList() {
  const { t } = useTranslation();

  const { organizationPrefix } = useParams();
  const organization = useRecoilValue(getOrgByPrefixState(organizationPrefix));

  if (!organization) {
    return (
      <Paper square sx={{ p: 2 }}>
        <Typography variant="body">{t("No organization found with abbreviation")} <i>{organizationPrefix}</i></Typography>
      </Paper>
    );
  }

  const dogs = useRecoilValue(organizationDogsState(organization));

  const handleRowClick = (params) => {
    window.location = `/${organizationPrefix}/dog/${params.row._id}`;
  };

  return (
    <>
    <OrganizationInfoBox org={organization}/>
    <DataGrid
      autoHeight
      pageSize={50}
      getRowId={(row) => row._id}
      onRowClick={handleRowClick}
      rows={dogs}
      columns={GetDogColumns({ organization })}
      components={{ Toolbar: GridToolbar }}
      disableSelectionOnClick
    />
    </>
  );
}
