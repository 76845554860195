import React from 'react';
import { FormControlLabel, Radio, Typography } from '@mui/material';
import { getNameByLang } from 'constants/helpers';

export const CoatRadio = ({ category, kruValues, language }) => {
  const items = kruValues
    .filter(item => item.category === category && !item.aliasFor)
    .map(item => {
      const aliases = kruValues
        .filter(aliasItem => aliasItem.aliasFor === item._id)
        .map(aliasItem => getNameByLang(aliasItem, language))
        .join(", ");
      
      return {
        value: item._id,
        key: getNameByLang(item, language),
        label: `${getNameByLang(item, language).charAt(0).toUpperCase() + getNameByLang(item, language).slice(1)}${aliases ? ` (${aliases})` : ''}`,
        sort: item.sort ?? 0,
      };
    })
    .sort((a, b) => a.sort - b.sort);

  return (
    <>
      {items.map(item => (
        <FormControlLabel
          key={item.key}
          value={item.value}
          control={<Radio />}
          label={<Typography variant="body">{item.label}</Typography>}
        />
      ))}
    </>
  );
};
