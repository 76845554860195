import React from "react";

import { useRecoilValue } from "recoil";
import { selectedDocByIdState } from "recoil/adminStates";

import { AdminNotFound } from "components/adminDashboardComponents/adminNotFound";
import OrganizationForm from "../forms/organizationForm";

function AdminOrganizationView() {
  const org = useRecoilValue(selectedDocByIdState("organization"));

  return org ? (
    <OrganizationForm action="edit" org={org} />
  ) : (
    <AdminNotFound docType="Organization" />
  );
}

export default AdminOrganizationView;
