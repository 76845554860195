import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Controller, useFormContext, useWatch } from "react-hook-form";

// Recoil
import { useRecoilState, useRecoilValue } from "recoil";
import { accessTokenState } from "recoil/globalStates";
import {
  dogRegistrationState,
  userIsDamOwnerState,
  userIsSireOwnerState,
} from "recoil/registrationStates";
import { adminDogsBySexState, adminTreedogsBySexState } from "recoil/adminStates";

// MUI
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import {
  Divider,
  Typography,
  TextField,
  Autocomplete,
} from "@mui/material";

// API routes
import { checkIfUserIsOwner } from "api/privateRoutes";

export default function DogParents() {
  const { t } = useTranslation();
  const accessToken = useRecoilValue(accessTokenState);

  const {
    control,
    watch,
    formState: { errors },
  } = useFormContext();

  // DELETEME
  // const { dam, sire, verificationNeeded } = watch();

  const [userIsSireOwner, setUserIsSireOwner] =
    useRecoilState(userIsSireOwnerState);
  const [userIsDamOwner, setUserIsDamOwner] =
    useRecoilState(userIsDamOwnerState);

  const [formData, setFormData] = useRecoilState(dogRegistrationState);

  const [open, setOpen] = useState({
    sire: false,
    dam: false,
  });

  const femaleDogs = useRecoilValue(adminDogsBySexState("female"))
	.concat (useRecoilValue(adminTreedogsBySexState("female")));
  const maleDogs = useRecoilValue(adminDogsBySexState("male"))
	.concat (useRecoilValue(adminTreedogsBySexState("male")));

  const selectedSire = useWatch({ control, name: "sire" });
  const selectedDam = useWatch({ control, name: "dam" });

  useEffect(() => {
    if (selectedSire) {
      checkIfUserIsOwner(selectedSire._id, { accessToken }).then((res) => {
        setUserIsSireOwner(res);
      });
    }
  }, [selectedSire]);

  useEffect(() => {
    if (selectedDam) {
      checkIfUserIsOwner(selectedDam._id, { accessToken }).then((res) => {
        setUserIsDamOwner(res);
      });
    }
  }, [selectedDam]);

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} sx={{ mb: 2 }}>
          <Divider textAlign="left" sx={{ mb: 2 }}>
            <Typography variant="h2">{t("Dog's Parents")}</Typography>
          </Divider>
          <Typography variant="body">{t("If the sire/dam are in the database already, select them below. Otherwise, leave one or both blank.")}</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Divider textAlign="left" sx={{ mb: 4 }}>
            <Typography variant="h3">{t("sire")}</Typography>
          </Divider>
          <Controller
            name="sire"
            render={({ field: { onChange, ...props } }) => (
              <Autocomplete
                options={maleDogs}
                getOptionLabel={(option) => option?.name || ""}
                renderOption={(props, option) => (
                  <li {...props} key={option._id}>
                    {option.name} {option.regNumber}
                  </li>
                )}
                onOpen={() => {
                  setOpen({ ...open, sire: true });
                }}
                placeholder={t("sire")}
                isOptionEqualToValue={(option, value) =>
                  option._id == value?._id
                }
                autoComplete
                noOptionsText={t("noData")}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t("sire")}
                  />
                )}
                onChange={(e, data) => {
                  onChange(data);
                  setFormData({
                    ...formData,
                    ["sire"]: data
                  });
                }}
                value={formData.sire}
                {...props}
              />
            )}
            onChange={([, data]) => data}
            defaultValue={formData.sire}
            control={control}
          />

          { /* DELETE
          {userIsSireOwner !== null &&
            (userIsSireOwner ? (
              <Typography variant="body">{t("youAreDogsOwnerText")}</Typography>
            ) : (
              <>
                <Typography variant="body">
                  {t("youAreNotDogsOwnerText")}
                </Typography>
                <br />
                <br />
                <FormControl>
                  <FormLabel id="sireSignature">
                    {t("siresMatingCertificate")}
                  </FormLabel>
                  <Controller
                    name="verificationNeeded.sire"
                    control={control}
                    rules={{
                      required: true,
                    }}
                    defaultValue={verificationNeeded?.sire || "email"}
                    render={({ field }) => (
                      <RadioGroup {...field}>
                        <FormControlLabel
                          value="email"
                          control={<Radio />}
                          label={t("emailParentVerification")}
                        />
                        <FormControlLabel
                          value="attachment"
                          control={<Radio />}
                          label={t("attachmentParentVerification")}
                        />
                      </RadioGroup>
                    )}
                  />
                </FormControl>
              </>
            ))} */ }
        </Grid>

        <Grid item xs={12} sm={6}>
          <Divider textAlign="left" sx={{ mb: 4 }}>
            <Typography variant="h3">{t("dam")}</Typography>
          </Divider>
          <Controller
            name="dam"
            render={({ field: { onChange, ...props } }) => (
              <Autocomplete
                options={femaleDogs}
                getOptionLabel={(option) => option.name}
                renderOption={(props, option) => (
                  <li {...props} key={option._id}>
                    {option.name} {option.regNumber}
                  </li>
                )}
                onOpen={() => {
                  setOpen({ ...open, dam: true });
                }}
                placeholder={t("dam")}
                isOptionEqualToValue={(option, value) =>
                  option._id == value._id
                }
                autoComplete
                noOptionsText={t("noData")}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t("dam")}
                  />
                )}
                onChange={(e, data) => {
                  onChange(data);
                  setFormData({
                    ...formData,
                    ["dam"]: data
                  });
                }}
                {...props}
              />
            )}
            onChange={([, data]) => data}
            defaultValue={formData.dam || null}
            control={control}
          />
          { /* DELETE
          {userIsDamOwner !== null &&
            (userIsDamOwner ? (
              <Typography variant="body">{t("youAreDogsOwnerText")}</Typography>
            ) : (
              <>
                <Typography variant="body">
                  {t("youAreNotDogsOwnerText")}
                </Typography>
                <br />
                <br />
                <FormControl>
                  <FormLabel id="damSignature">
                    {t("damsMatingCertificate")}
                  </FormLabel>
                  <Controller
                    name="verificationNeeded.dam"
                    control={control}
                    rules={{
                      required: true,
                    }}
                    defaultValue={verificationNeeded?.dam || "email"}
                    render={({ field }) => (
                      <RadioGroup {...field}>
                        <FormControlLabel
                          value="email"
                          control={<Radio />}
                          label={t("emailParentVerification")}
                        />
                        <FormControlLabel
                          value="attachment"
                          control={<Radio />}
                          label={t("attachmentParentVerification")}
                        />
                      </RadioGroup>
                    )}
                  />
                </FormControl>
              </>
            ))} */ }
        </Grid>
      </Grid>
    </>
  );
}
