import React from "react";
import { useTranslation } from "react-i18next";

// MUI
import { Typography, Divider, Grid } from "@mui/material";
 
export default function Terms() {
  const { t } = useTranslation();
  return (
    <>
      <Grid container direction={{ xs: "column", md: "row" }} spacing={5}>
        <Grid item xs={7}>
          <Divider textAlign="left" sx={{ mb: 2 }}>
            <Typography variant="h2">{t("Terms of Use")}</Typography>
          </Divider>
          <Typography>
    <p>
      The Functional Dog Collaborative (“FDC”) owns and operates this website
      (“Website”). These Terms of Use are an agreement entered into by and
      between you (“User,” “you,” or “your”) and FDC. <b>By accessing and using
      this Website, you agree to each of the terms and conditions set forth
      herein, our Privacy Notice, and agree to abide by the FDC’s Bylaws,
      rules, regulations, procedures as set forth in the FDC Charter and
      Bylaws, rules, regulations, published policies and guidelines.</b> Additional
      terms and conditions applicable to certain areas the Website or as to
      certain transactions, services or products may be posted in those areas or
      in connection with a particular transaction, service or product and
      together with these Terms of Use, FDC Charter and Bylaws, rules,
      regulations, published policies, guidelines any other documents they
      incorporate by reference (collectively, the “Terms”), govern your access
      to and use of those areas and/or transactions, services or products and
      any other software, tools, features, or functionalities provided on or in
      connection thereof (collectively, our “Service”).
    </p>
    <h3>1. ACCEPTANCE OF TERMS</h3>
    <p>
      By accessing and/or using any part of our Service, you agree to be bound
      by these Terms. You represent that you are at least the age of majority in
      your state or province of residence. Except as expressly allowed by these
      Terms, you may only use our Service for your personal, non-commercial use.
      If you are using our Service on behalf of a company or other legal entity,
      then “you” also means such company or legal entity and you agree to be
      bound by these Terms. FDC reserves the right to modify these Terms at any
      time without prior notice. Your use of this site following any
      modification constitutes your agreement to be bound by the Terms as
      modified. You may use the Service for lawful purposes only. You may not
      use this Service for any purpose that is prohibited by law or regulations
      or to facilitate the violation of any law or regulation. FDC may, in its
      sole discretion, terminate these Terms and your access to all or any part
      of the Website or the Services at any time and for any reason without
      prior notice or liability.
    </p>
    <h3>2. PRIVACY NOTICE</h3>
    <p>
      We care about the privacy of our Users. You understand that by using the
      Services you acknowledge that you have read and understood our Privacy
      Notice (available at <a href="http://app.functionaldogdata.org/privacy">app.functionaldogdata.org/privacy</a>), which explains how your personal
      information is collected, used, and disclosed by FDC. You also understand
      and acknowledge that your personal information is collected, used,
      transferred to and processed in the United States.
    </p>
    <h3>3. USE OF OUR SERVICE</h3>
    <p>
      You will not, and will not permit anyone else to, directly or indirectly:
      (i) use any robot, spider, scraper, data mining tool, data gathering or
      extraction tool, or any other automated means, to access, collect, copy or
      record our Service or FDC Content (as defined below); (ii) reverse
      engineer, disassemble, decompile or otherwise attempt to discover the
      source code or structure, sequence and organization of all or any part of
      our Service (except as permitted by applicable law); (iii) rent, lease,
      resell, distribute or use our Service for timesharing, service bureau, or
      to aggregate purchases through our Service; (iv) copy, modify, alter or
      create derivative works of any part of our Service or FDC Content; (v)
      remove or alter any proprietary notices or labels on or in our Service or
      FDC Content; (vi) identify or refer to us or our Service in a manner that
      could reasonably imply an endorsement, relationship or affiliation with
      or sponsorship between you (or a third party) and us, without our prior
      express written consent; or (vii) engage in any activity not otherwise
      permitted by these Terms or our policies.
    </p>
    <h3>4. APPLICATIONS AND REGISTRATIONS</h3>
    <p>
      You agree that all information supplied by you when entering data,
      registering a dog or litter, or any other application found in our Service
      is accurate and complete. You agree that you will supply information in
      accordance with FDC Rules, Regulations &amp; Policies. You acknowledge that
      listings/registrations are not guaranteed, and that listing/registration
      fees are not refundable. You must have proper written authorization to
      submit data or a registration application on behalf of another person and
      will, upon request, be required to provide us with such written
      authorization. You acknowledge and agree that any misrepresentation
      submitted by you through any online application process may result in
      removal of the data or cancellation of the registration of the dog and its
      descendants or the litter and the members of the litter and their
      descendants. The submission of false information in any application
      submitted through our Service to register or transfer a dog or litter
      could result in the loss of your good standing with the FDC and the
      associated privileges.
    </p>
    <h3>5. YOUR ONLINE ACCOUNT</h3>
    <p>
      In order to use certain features of the website and to use certain
      services, you must create and register an account with FDC (“Account”) on
      our Service and provide certain information about yourself and verifying a
      username, password, a valid email address and other information as may be
      applicable and requested during the creation of the Account. You are
      responsible for maintaining the confidentiality of your Account and are
      fully responsible for all activities that occur under your Account, whether
      or not authorized by you. You agree to (a) never share your Account with
      anyone, (b) immediately notify us of any actual or suspected unauthorized
      use of your Account or any other breach of security, and (c) ensure that
      you exit from your Account at the end of each session. We will not be
      liable for any loss, damage or other liability arising from your failure
      to comply with this Section or from any unauthorized access to or use of
      your Account. We reserve the right to reject any registration or to
      terminate your Account and/or use of our Service in our sole discretion
      and without any liability to us. You represent and warrant that (1) all
      required information you submit is truthful, complete and accurate; (2) you
      will maintain the accuracy of the information updating any inaccurate
      information and (3) your use of the Website or Account does not and will
      not violate any of these Terms or the documents they incorporate by
      reference, or any applicable law or regulation. Your Account may be
      restricted or terminated for any reason, at FDC’s sole discretion.
    </p>
    <h3>6. LINKING POLICY</h3>
    <p>
      The FDC grants nonexclusive, limited permission to website owners to
      develop a link (“Permitted Link”) from the owner’s website (the “Linking
      Site”) to this Website, subject to the following terms and conditions:
      “Framing” any page of this website or altering the presentation or
      appearance in any way of any page of FDC’s website is prohibited.
      Permitted Links shall not be used or associated with any defamatory,
      scandalous, inflammatory, profane, pornographic, obscene, indecent or
      unlawful website or material; shall not be used in or associated with any
      manner that disparages or adversely affects the name, reputation and
      goodwill of FDC; shall not be used in or associated with any manner that
      falsely represents or misleads the public regarding FDC; and shall not be
      used in or associated with any manner which infringes or violates the
      rights of a third party.
    </p>
    <p>
      In no event shall FDC be liable for any content on the Linking Site or for
      any loss or damage resulting from use of a Permitted Link. The Linking
      Site, by its use, agrees to indemnify, defend and hold harmless FDC
      against all claims, actions or proceedings, including but not limited to
      expenses, costs and attorneys fees, arising out of or based on use of a
      Permitted Link and/or the Linking Site.
    </p>
    <p>
      FDC retains and reserves all rights in and to all FDC proprietary material,
      including but not limited to trademarks, service marks, logos and other
      marks, and copyrights appearing on the website. Except for the permission
      granted hereunder, which is limited solely to the right to link, nothing
      from the FDC website may be posted on a Linking Site or anywhere else
      without express written permission.
    </p>
    <p>
      FDC reserves the right to terminate permission granted hereunder at any
      time for any reason. Upon termination, all links to FDC’s website
      immediately shall be removed and all uses of FDC’s proprietary material
      shall immediately cease. AKC reserves the right to amend this at any time
      without prior notice.
    </p>
    <h3>7. DATA PLATFORM</h3>
    <p>
      If you use this website, you understand and agree that all the information
      that you input into the records will be stored on FDC servers or third
      party servers. You understand and agree that the FDC will have access to
      and the ability to use this information. This will include but is not
      limited to use for determining compliance with FDC Rules, Regulations and
      policies and contacting individuals to whom you have transferred a dog or
      puppy. In using the FDC’s data platform in relation to registered
      co-owned dogs and litters, you understand and agree that the records will
      be able to be accessed by all registered co-owners and that each registered
      co-owner will have the authority to enter and change the information. You
      understand and agree that both the registered sire owners and registered
      dam owners will be able to access the mating records for the particular
      mating between the two dogs and will be able to enter and change the
      information. You understand and agree that the information that you input,
      anonymized and minus personal identifying information, may be used for
      research purposes, including by researchers who are unaffiliated with FDC.
    </p>
    <h3>8. NO PROFESSIONAL ADVICE</h3>
    <p>
      Any content or information supplied by any of our employees or agents,
      whether by telephone, e-mail, letter, video chat, facsimile or other form
      of communication, is intended solely as general guidance on the use of our
      Service and our products, and does not constitute legal, tax, accounting
      or other professional advice. Individual situations and applicable laws
      vary by jurisdiction, and you are encouraged to obtain appropriate advice
      from qualified professionals in the applicable jurisdictions. We make no
      representations or warranties concerning any course of action taken by any
      person following or otherwise using the information offered or provided
      within or through our Service, and we will not be liable for any direct,
      indirect, consequential, special, exemplary or other damages that may
      result, including but not limited to economic loss, injury, illness or
      death.
    </p>
    <h3>9. ERRORS, INACCURACIES AND OMISSIONS.</h3>
    <p>
      Although we attempt to be as accurate as possible, occasionally there may
      be content or information on our Service that contains typographical
      errors, inaccuracies or omissions that may relate to product descriptions,
      pricing, promotions, offers, product shipping charges, transit times and
      availability. We reserve the right to correct any errors, inaccuracies or
      omissions, and to change or update information if any information in our
      Service or on any related website is inaccurate at any time without prior
      notice. Our Service may contain certain historical content or information.
      Historical content and information, necessarily, is not current and is
      provided for your reference only. No specified update or refresh date
      applied in our Service, or on any related website, should be taken to
      indicate that all content or information in our Service or on any related
      website has been modified or updated.
    </p>
    <h3>10. INFORMATION PROVIDED BY FUNCTIONAL DOG DATA</h3>
    <p>
      FDC does not endorse and is not responsible or liable for any breeder
      whose information it provides to you. The breeder, breed, and other
      information provided to you is for informational purposes only. You agree
      that should you use or rely on such information, FDC is not responsible or
      liable, indirectly or directly, for any damage or loss caused or alleged
      to be caused by or in connection with such use or reliance. Your dealings
      with any breeder, and any other terms, conditions, representations or
      warranties associated with such dealings, are between you and such breeder
      exclusively and do not involve FDC. You should make whatever investigation
      or consult other resources that you deem necessary or appropriate before
      hiring or engaging the breeder or selecting a particular breed or
      particular dog. FDC is not responsible for and has no liability with
      respect to any transaction between you and any breeder. Functional Dog
      Data is provided solely as a service to the public. FDC does not sell dogs
      and makes no warranty or guarantee as to the temperament, traits,
      personality, health, quality, parentage or any value of any dogs.
    </p>
    <p>
      You agree to defend, indemnify, and hold the FDC harmless from any claims,
      actions, or demands, including, without limitation, reasonable legal and
      accounting fees, arising or resulting from: (i) your breach of these Terms;
      (ii) your access to, use or misuse of Functional Dog Data; or (iii) your
      reliance on any breeder.
    </p>
    <h3>11. DMCA NOTICE</h3>
    <p>
      We comply with the Digital Millennium Copyright Act of 1998 (“DMCA”). If
      you believe that your copyrighted work has been copied in a way that
      constitutes copyright infringement and is accessible via our Service,
      please notify our Copyright Agent. For your complaint to be valid under
      the DMCA, you must provide the following information in writing: an
      electronic or physical signature of a person authorized to act on behalf
      of the copyright owner; Identification of the copyrighted work that you
      claim has been infringed; identification of the material that is claimed
      to be infringing and where it is located on our Service; Information
      reasonably sufficient to permit us to contact you, such as your address,
      telephone number, and/or e-mail address; a statement that you have a good
      faith belief that use of the material in the manner complained of is not
      authorized by the copyright owner, its agent, or law; and a statement, made
      under penalty of perjury, that the above information is accurate and that
      you are the copyright owner or are authorized to act on behalf of the
      owner.
    </p>
    <p>
      The above information must be submitted, either by postal mail or email, to
      the following Copyright Agent:
    </p>
    <p>
      Attn: The Functional Dog Collaborative<br />
      Address: 106 Langford Drive, Raymond, NH 03077 USA<br />
      Email: <a href="info@functionalbreeding.org">info@functionalbreeding.org</a>
    </p>
    <p>
      Upon receipt of a notification of claimed infringement as set forth above,
      FDC will remove or disable access to the copyrighted material and take
      reasonable steps to notify the person who posted the material that it has
      been removed or access to it has been disabled.
    </p>
    <h3>12. DISCLAIMER OF WARRANTIES</h3>
    <p>
      THE FDC MAKES NO EXPRESS OR IMPLIED WARRANTIES, REPRESENTATIONS OR
      ENDORSEMENTS WHATSOEVER WITH RESPECT TO THE SITE, THE SERVICE, THE PRODUCTS
      OR THE CONTENT. YOU EXPRESSLY AGREE THAT THE USE OF PRODUCTS PURCHASED
      THROUGH THIS WEBSITE ARE USED AT YOUR OWN RISK. FDC EXPRESSLY DISCLAIMS ALL
      WARRANTIES OF ANY KIND, EXPRESS, IMPLIED, STATUTORY OR OTHERWISE,
      INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY,
      FITNESS FOR A PARTICULAR PURPOSE, TITLE AND NON-INFRINGEMENT, WITH REGARD
      TO THE SITE, THE SERVICE, THE CONTENT, AND ANY PRODUCT OR SERVICE FURNISHED
      OR TO BE FURNISHED VIA THE SITE. FDC DOES NOT WARRANT THAT THE FUNCTIONS
      PERFORMED BY THE SITE OR THE SERVICE WILL BE UNINTERRUPTED, TIMELY, SECURE,
      FITNESS FOR A PARTICULAR PURPOSE, TITLE AND NON-INFRINGEMENT, WITH REGARD
      TO THE SITE, THE SERVICE, THE CONTENT, AND ANY PRODUCT OR SERVICE FURNISHED
      OR TO BE FURNISHED VIA THE SITE. FDC DOES NOT WARRANT THAT THE FUNCTIONS
      PERFORMED BY THE SITE OR THE SERVICE WILL BE UNINTERRUPTED, TIMELY, SECURE,
      OR ERROR-FREE, OR THAT DEFECTS IN THE SITE OR THE SERVICE WILL BE
      CORRECTED. FDC DOES NOT WARRANT THE ACCURACY OR COMPLETENESS OF THE
      CONTENT, OR THAT ANY ERRORS IN THE CONTENT WILL BE CORRECTED. THE SITE, THE
      SERVICE AND THE CONTENT ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS.
    </p>
    <p>
      YOUR ACCESS TO AND USE OF THE SERVICES (INCLUDING THEIR FUNCTIONS AND
        subsidiaries, affiliates, partners, officers, directors, agents,
        contractors, licensors, service providers, subcontractors, suppliers,
        interns and employees, from any claim or demand, including reasonable
        attorneys’ fees, made by any third party due to or arising out of your
        breach of these Terms or the documents they incorporate by reference, or
        your violation of any law or the rights of a third party.
      </p>
      

      <h3>14.  LIMITATION OF LIABILITY</h3>
<p>IN NO EVENT WILL THE FDC OR ANY OF OUR AFFILIATES, OR ANY PARTY INVOLVED IN CREATING, PRODUCING, OR DELIVERING THE SERVICE, THE PRODUCTS OR CONTENT BE LIABLE FOR ANY MANNER WHATSOEVER, INCLUDING, BUT NOT LIMITED TO ANY DIRECT, INCIDENTAL, CONSEQUENTIAL, SPECIAL, EXEMPLARY OR OTHER INDIRECT DAMAGES, ARISING OUT OF (1) THE USE OF OR INABILITY TO USE THE SITE, THE SERVICE, THE PRODUCTS, OR THE CONTENT, (2) ANY TRANSACTION CONDUCTED THROUGH OR FACILITATED BY THE SITE; (3) ANY CLAIM ATTRIBUTABLE TO ERRORS, OMISSIONS, OR OTHER INACCURACIES IN THE SITE, THE SERVICE, THE PRODUCTS, AND/OR CONTENT, (4) UNAUTHORIZED ACCESS TO OR ALTERATION OF YOUR TRANSMISSIONS OR DATA, OR (5) ANY OTHER MATTER RELATING TO THE SITE, THE SERVICE, THE PRODUCTS, OR THE CONTENT, EVEN IF THE FDC HAS BEEN ADVISED OF THE POSSIBILITIES OF SUCH DAMAGES. IF YOU ARE DISSATISFIED WITH THE SITE, THE SERVICE, THE CONTENT, OR THE TERMS OF THIS AGREEMENT, YOUR SOLE AND EXCLUSIVE REMEDY IS TO DISCONTINUE USING THE SITE. BECAUSE SOME STATES DO NOT ALLOW THE EXCLUSION OR LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, SOME OF THE ABOVE LIMITATIONS MAY NOT APPLY TO YOU. IN SUCH STATES, FDC’S LIABILITY IS LIMITED AND WARRANTIES ARE EXCLUDED TO THE GREATEST EXTENT PERMITTED BY LAW.</p>
<p>IN NO EVENT SHALL OUR TOTAL LIABILITY TO YOU FOR ALL DAMAGES, LOSSES, OR CAUSES OF ACTION ARISING UNDER THIS AGREEMENT EXCEED ONE HUNDRED DOLLARS ($100.00).</p>
<h3>15.  GOVERNING LAW.</h3>
<p>You agree that: (i) we will be deemed solely based in the State of New Hampshire; and (ii) our Service will be deemed a passive one that does not give rise to personal jurisdiction over us, either specific or general, in jurisdictions other than New Hampshire. These Terms will be governed by the internal substantive laws of New New Hampshire, without respect to its conflict of laws principles. The parties acknowledge that these Terms evidence a transaction involving interstate commerce. You and we agree that any Claim (as defined below) that is not subject to arbitration or cannot be heard in small claims court, will be resolved in the state or federal courts of New Hampshire, and that New Hampshire is the proper and exclusive forum for any such proceedings.</p>
<h3>16.  ADDITIONAL RESTRICTIONS.</h3>
<p>In addition to other restrictions as set forth in these Terms, you are restricted from using our Service or any Content: (a) for any unlawful purpose; (b) to solicit others to perform or participate in any unlawful acts; (c) to violate any international, federal, provincial or state regulations, rules, laws, or local ordinances; (d) to infringe upon or violate our intellectual property rights or the intellectual property rights of others; (e) to harass, abuse, insult, harm, defame, slander, disparage, intimidate, or discriminate based on gender, sexual orientation, religion, ethnicity, race, age, national origin, or disability; (f) to submit false or misleading information; (g) to upload or transmit viruses or any other type of malicious code that will or may be used in any way that will affect the functionality or operation of our Service or linked third-party websites or services; (h) to collect or track the personal information of others; (i) for any obscene or immoral purpose; or (j) to interfere with or circumvent the security features of our Service or linked third-party websites or services.
When registering a dog, the dog’s name may not include any obscenity and may not include words that are derogatory to any categories of people listed in section 16(e) above. 
</p>
<h3>17.  SEVERABILITY.</h3>
<p>In the event that any provision of these Terms is determined to be unlawful, void or unenforceable, such provision will nonetheless be enforceable to the fullest extent permitted by applicable law, and the unenforceable portion will be deemed to be severed from these Terms, such determination will not affect the validity and enforceability of any other remaining provisions.</p>
<h3>18.  TERMINATION</h3>
<p>The obligations and liabilities of the parties incurred prior to the termination date will survive the termination of this agreement for all purposes. These Terms are effective unless and until terminated by either you or us. You may terminate this agreement at any time by notifying us that you no longer wish to use our Service, or when you cease using our Service. If in our sole judgment you fail, or we suspect that you have failed, to comply with any term or provision of these Terms, we also may terminate this agreement at any time without notice and you will remain liable for all amounts due up to and including the date of termination.</p>
<h3>19.  CHANGES TO THESE TERMS</h3>
<p>Any new features or tools which are added to the current store will also be subject to these Terms. You can review the most current version of the Terms at any time on this page. We reserve the right, at our sole discretion, to update, change or replace any part of these Terms by posting updates and changes to our Service. It is your responsibility to check our Service periodically for changes. Your continued use of or access to our Service following the posting of any changes to these Terms constitutes acceptance of those changes.</p>
<h3>20.  NO WAIVER</h3>
<p>Our delay or failure to exercise or enforce any right or provision of these Terms will not constitute a waiver of such right or provision.</p>
<h3>21.  ENTIRE AGREEMENT</h3>
<p>These Terms and any policies or operating rules posted by us on our Service or in respect to our Service constitutes the entire agreement and understanding between you and us and govern your use of our Service, superseding any prior or contemporaneous agreements, communications and proposals, whether oral or written, between you and us (including, but not limited to, any prior versions of these Terms). Any ambiguities in the interpretation of these Terms will not be construed against us.</p>
</Typography>
        </Grid>
      </Grid>
      </>
     );
    }
