import React from "react";
import dayjs from "dayjs";
import i18next from "i18next";

import { Typography } from "@mui/material";

import { getLinkDisplay } from "constants/helpers";

export const litterColumns = [
  { field: "_id", headerName: "ID", hide: true },
  {
    field: "dateOfBirth",
    headerName: i18next.t("dob"),
    renderCell: (params) => (
      <Typography
        variant="body2"
        style={{
          textOverflow: "ellipsis !important",
          overflow: "hidden !important",
        }}
      >
        {dayjs(params.value).format("MM.DD.YYYY")}
      </Typography>
    ),
    width: 120,
  },
  {
    field: "dam",
    headerName: i18next.t("dam"),
    minWidth: 270,
    valueGetter: (params) => {
      return params?.row?.dam?.name
        ? params?.row?.dam?.name
        : i18next.t("notDisclosed");
    },
  },
  {
    field: "sire",
    headerName: i18next.t("sire"),
    minWidth: 270,
    valueGetter: (params) => {
      return params?.row?.sire?.name
        ? params?.row?.sire?.name
        : i18next.t("notDisclosed");
    },
  },
  {
    field: "breeder",
    headerName: i18next.t("breeder"),
    minWidth: 270,
    valueGetter: (params) => {
      return (
        params?.row?.breeder?.breedingPrefix ||
        params?.row?.breeder?.name ||
        params?.row?.breeder?.breederId ||
        i18next.t("noPermissionToDisplayInformation")
      );
    },
  },
  {
    field: "puppies",
    headerName: i18next.t("puppies"),
    minWidth: 270,
    valueFormatter: (params) => {
      if (params.value == null) {
        return "";
      }

      return params.value.length;
    },
  },
];
