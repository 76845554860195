import React from "react";
import { useTranslation } from "react-i18next";

// Recoil
import { useRecoilState, useRecoilValue } from "recoil";
import { treedogAdditionState } from "recoil/registrationStates";

// MUI
import { Stepper, Step, StepLabel, Box } from "@mui/material";

// Steps
import TreedogAdditionForm from "./steps/treedogAdditionForm";
import TreedogAttachments from "./steps/treedogAttachments";
import TreedogSummary from "./steps/treedogSummary";

export default function TreedogAddition({ errors }) {
  const { t } = useTranslation();
  const [formData, setFormData] = useRecoilState(treedogAdditionState);
  const activeStep = formData.activeStep;

  function currentStep() {
    switch (activeStep) {
      case 0:
        return <TreedogAdditionForm />;
      case 1:
        return <TreedogAttachments />;
      case 2:
        return <TreedogSummary />;
    }
  }

  return (
    <Box sx={{ p: 3 }}>
      <Stepper
        activeStep={activeStep}
        sx={{
          flexDirection: { xs: "column", sm: "row" },
          alignItems: { xs: "flex-start", sm: "center" },
        }}
      >
        <Step key="1">
          <StepLabel error={Object.keys(errors).length !== 0}>
            {t("additionForm")}
          </StepLabel>
        </Step>
        <Step key="2">
          <StepLabel>{t("attachments")}</StepLabel>
        </Step>
        <Step key="3">
          <StepLabel>{t("summary")}</StepLabel>
        </Step>
      </Stepper>
      <Box sx={{ mt: 6 }}>{currentStep()}</Box>
    </Box>
  );
}
