import React from "react";

// Routes
import { updateCompetitionResult } from "api/adminRoutes";

// MUI
import { Button, Grid } from "@mui/material";
import CompetitionResultForm from "../forms/competitionresultForm";
import { ArrowBack } from "@mui/icons-material";

import { snackBarState } from "recoil/globalStates";
import { selectedDocByIdState } from "recoil/adminStates";
import { useRecoilState, useRecoilValue } from "recoil";
import { useTranslation } from "react-i18next";
import { AdminNotFound } from "components/adminDashboardComponents/adminNotFound";

function AdminCompetitionResultView() {
  const { t } = useTranslation();
  const [snackbarState, setSnackbarState] = useRecoilState(snackBarState);

  const result = useRecoilValue(selectedDocByIdState("competitionResult"));

  const handleSubmit = (accessToken, updatedResult, id) => {
    const updateResultData = async (accessToken, updatedResult, id) => {
      await updateCompetitionResult(id, updatedResult, { accessToken }).then(
        (res) => {
          if (res.error) {
            setSnackbarState({
              message: res.message,
              severity: "error",
              open: true,
            });
          } else {
            setSnackbarState({
              message: t("updateSuccess", { docType: "Competition result" }),
              severity: "success",
              open: true,
            });
            setTimeout(() => {
              window.location.reload(true);
            }, 1000);
          }
        }
      );
    };
    updateResultData(accessToken, updatedResult, id);
  };

  return result ? (
    <CompetitionResultForm
      action="edit"
      result={result}
      emitHandleSubmit={handleSubmit}
    />
  ) : (
    <AdminNotFound docType="Competition result" />
  );
}

export default AdminCompetitionResultView;
