import React from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

// MUI
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Typography, Paper } from "@mui/material";

// Recoil
import { getOrgByPrefixState, organizationBreedersState } from "recoil/publicStates";
import { useRecoilValue } from "recoil";

// Local
import { breederColumns } from "components/datagridColumns/BreederColumns";
import OrganizationInfoBox from "components/organizationComponents/OrganizationInfoBox";

export default function BreederList() {
  const { i18n } = useTranslation();

  const { organizationPrefix } = useParams();
  const organization = useRecoilValue(getOrgByPrefixState(organizationPrefix));

  if (!organization) {
    return (
      <Paper square sx={{ p: 2 }}>
        <Typography variant="body">{t("No organization found with abbreviation")} <i>{organizationPrefix}</i></Typography>
      </Paper>
    );
  }

  const breeders = useRecoilValue(organizationBreedersState(organization));

  const handleRowClick = (params) => {
    window.location = `/${organizationPrefix}/breeder/${params.row.breederId}`;
  };

  return (
    <>

    <OrganizationInfoBox org={organization}/>
    <DataGrid
      autoHeight
      getRowId={(row) => row._id}
      onRowClick={handleRowClick}
      rows={breeders}
      columns={breederColumns}
      localeText={i18n.language === "fi" ? muiFi : null}
      components={{ Toolbar: GridToolbar }}
    />
    </>
  );
}
