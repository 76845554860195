import { FormControl, Grid, InputLabel, MenuItem, Select } from "@mui/material";
import React from "react";
import { Controller } from "react-hook-form";

// Get UI component for health test result based on the result.type discriminator

export function HealthtestComponent({ resultType, data, control }) {
  const numericalValues = [0, 1, 2, 3, 4];
  const hdFciValues = ["A", "B", "C", "D", "E"];
  const hdOfaValues = [
    "excellent",
    "good",
    "fair",
    "borderline",
    "mild",
    "moderate",
    "severe",
  ];
  const ocFkcValues = ["unaffected", "affected", "ambiguous"];
  const hauValues = ["normal", "murmur", "other"];
  const testiclesValues = ["normal", "one retained", "both retained", "other"];
  const diagnosisValues = ["diagnosed"];
  // ECVO Grades
  const ecvoHereditaryGrades = ["unaffected", "undetermined", "affected"];
  const ecvoGenerativeGrades = ["unaffected", "suspicious", "affected"];
  const ecvoIcaaGrades = ["unaffected", "affected"];
  const ecvoOtherGrades = ["unaffected", "undetermined/suspicious", "affected"];

  switch (resultType) {
    case "twoSidedNumerical":
      return (
        <>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth required>
              <InputLabel>Left side</InputLabel>
              <Controller
                name="result.left"
                control={control}
                defaultValue={
                  data?.result?.left !== null ? data?.result?.left : ""
                }
                rules={{
                  required: "Required",
                }}
                render={({ field }) => (
                  <Select {...field} fullWidth label="Left side">
                    {numericalValues.map((value) => (
                      <MenuItem key={value} value={value}>
                        {value}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth required>
              <InputLabel>Right side</InputLabel>
              <Controller
                name="result.right"
                control={control}
                defaultValue={
                  data?.result?.right !== null ? data?.result?.right : ""
                }
                rules={{
                  required: "Required",
                }}
                render={({ field }) => (
                  <Select {...field} fullWidth label="Right side">
                    {numericalValues.map((value) => (
                      <MenuItem key={value} value={value}>
                        {value}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </FormControl>
          </Grid>
        </>
      );
    case "singleNumerical":
      return (
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth required>
            <InputLabel>Result</InputLabel>
            <Controller
              name="result.grade"
              control={control}
              defaultValue={
                data?.result?.grade !== null ? data?.result?.grade : ""
              }
              rules={{
                required: "Required",
              }}
              render={({ field }) => (
                <Select {...field} fullWidth type="number" label="Result">
                  {numericalValues.map((value) => (
                    <MenuItem key={value} value={value}>
                      {value}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          </FormControl>
        </Grid>
      );
    case "hdFci":
      return (
        <>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth required>
              <InputLabel>Left side</InputLabel>
              <Controller
                name="result.left"
                control={control}
                defaultValue={data?.result?.left || ""}
                rules={{
                  required: "Required",
                }}
                render={({ field }) => (
                  <Select {...field} fullWidth label="Left side">
                    {hdFciValues.map((value) => (
                      <MenuItem key={value} value={value}>
                        {value}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth required>
              <InputLabel>Right side</InputLabel>
              <Controller
                name="result.right"
                control={control}
                defaultValue={data?.result?.right || ""}
                rules={{
                  required: "Required",
                }}
                render={({ field }) => (
                  <Select {...field} fullWidth label="Right side">
                    {hdFciValues.map((value) => (
                      <MenuItem key={value} value={value}>
                        {value}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </FormControl>
          </Grid>
        </>
      );
    case "hdOfa":
      return (
        <>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth required>
              <InputLabel>Left side</InputLabel>
              <Controller
                name="result.left"
                control={control}
                defaultValue={data?.result?.left || ""}
                rules={{
                  required: "Required",
                }}
                render={({ field }) => (
                  <Select {...field} fullWidth label="Left side">
                    {hdOfaValues.map((value) => (
                      <MenuItem key={value} value={value}>
                        {value}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth required>
              <InputLabel>Right side</InputLabel>
              <Controller
                name="result.right"
                control={control}
                defaultValue={data?.result?.right || ""}
                rules={{
                  required: "Required",
                }}
                render={({ field }) => (
                  <Select {...field} fullWidth label="Right side">
                    {hdOfaValues.map((value) => (
                      <MenuItem key={value} value={value}>
                        {value}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </FormControl>
          </Grid>
        </>
      );
    case "ocFkc":
      return (
        <>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth required>
              <InputLabel>Left side</InputLabel>
              <Controller
                name="result.left"
                control={control}
                defaultValue={data?.result?.left || ""}
                rules={{
                  required: "Required",
                }}
                render={({ field }) => (
                  <Select {...field} fullWidth label="Left side">
                    {ocFkcValues.map((value) => (
                      <MenuItem key={value} value={value}>
                        {value}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth required>
              <InputLabel>Right side</InputLabel>
              <Controller
                name="result.right"
                control={control}
                defaultValue={data?.result?.right || ""}
                rules={{
                  required: "Required",
                }}
                render={({ field }) => (
                  <Select {...field} fullWidth label="Right side">
                    {ocFkcValues.map((value) => (
                      <MenuItem key={value} value={value}>
                        {value}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </FormControl>
          </Grid>
        </>
      );
    case "hau":
      return (
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth required>
            <InputLabel>Result</InputLabel>
            <Controller
              name="result.grade"
              control={control}
              defaultValue={data?.result?.grade || ""}
              rules={{
                required: "Required",
              }}
              render={({ field }) => (
                <Select {...field} fullWidth label="Result">
                  {hauValues.map((value) => (
                    <MenuItem key={value} value={value}>
                      {value}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          </FormControl>
        </Grid>
      );
    case "testicles":
      return (
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth required>
            <InputLabel>Result</InputLabel>
            <Controller
              name="result.grade"
              control={control}
              defaultValue={data?.result?.grade || ""}
              rules={{
                required: "Required",
              }}
              render={({ field }) => (
                <Select {...field} fullWidth label="Result">
                  {testiclesValues.map((value) => (
                    <MenuItem key={value} value={value}>
                      {value}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          </FormControl>
        </Grid>
      );
    case "diagnosis":
      return (
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth required>
            <InputLabel>Result</InputLabel>
            <Controller
              name="result.grade"
              control={control}
              defaultValue={data?.result?.grade || ""}
              rules={{
                required: "Required",
              }}
              render={({ field }) => (
                <Select {...field} fullWidth label="Result">
                  {diagnosisValues.map((value) => (
                    <MenuItem key={value} value={value}>
                      {value}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          </FormControl>
        </Grid>
      );
    case "eyesEcvo":
      return (
        <>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth required sx={{ mb: 2 }}>
              <InputLabel>1. Persistent Pupillary Membrane (PPM)</InputLabel>
              <Controller
                name="result.ppm"
                control={control}
                defaultValue={data?.result?.ppm || ""}
                rules={{
                  required: "Required",
                }}
                render={({ field }) => (
                  <Select
                    {...field}
                    fullWidth
                    label="1. Persistent Pupillary Membrane (PPM)"
                  >
                    {ecvoHereditaryGrades.map((value) => (
                      <MenuItem key={value} value={value}>
                        {value}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </FormControl>
            <FormControl fullWidth required sx={{ mb: 2 }}>
              <InputLabel>2. PHTVL/PHPV</InputLabel>
              <Controller
                name="result.phtvlPhpv"
                control={control}
                defaultValue={data?.result?.phtvlPhpv || ""}
                rules={{
                  required: "Required",
                }}
                render={({ field }) => (
                  <Select {...field} fullWidth label="2. PHTVL/PHPV">
                    {ecvoHereditaryGrades.map((value) => (
                      <MenuItem key={value} value={value}>
                        {value}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </FormControl>
            <FormControl fullWidth required sx={{ mb: 2 }}>
              <InputLabel>3. Cataract (congenital)</InputLabel>
              <Controller
                name="result.cataractCon"
                control={control}
                defaultValue={data?.result?.cataractCon || ""}
                rules={{
                  required: "Required",
                }}
                render={({ field }) => (
                  <Select {...field} fullWidth label="3. Cataract (congenital)">
                    {ecvoHereditaryGrades.map((value) => (
                      <MenuItem key={value} value={value}>
                        {value}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </FormControl>
            <FormControl fullWidth required sx={{ mb: 2 }}>
              <InputLabel>4. Retinal Dysplasia (RD)</InputLabel>
              <Controller
                name="result.rd"
                control={control}
                defaultValue={data?.result?.rd || ""}
                rules={{
                  required: "Required",
                }}
                render={({ field }) => (
                  <Select
                    {...field}
                    fullWidth
                    label="4. Retinal Dysplasia (RD)"
                  >
                    {ecvoHereditaryGrades.map((value) => (
                      <MenuItem key={value} value={value}>
                        {value}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </FormControl>
            <FormControl fullWidth required sx={{ mb: 2 }}>
              <InputLabel>5. Hypoplastic-/Micro-papilla</InputLabel>
              <Controller
                name="result.hypoMicroPapilla"
                control={control}
                defaultValue={data?.result?.hypoMicroPapilla || ""}
                rules={{
                  required: "Required",
                }}
                render={({ field }) => (
                  <Select
                    {...field}
                    fullWidth
                    label="5. Hypoplastic-/Micro-papilla"
                  >
                    {ecvoHereditaryGrades.map((value) => (
                      <MenuItem key={value} value={value}>
                        {value}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </FormControl>
            <FormControl fullWidth required sx={{ mb: 2 }}>
              <InputLabel>6. Collie Eye Anomaly (CEA)</InputLabel>
              <Controller
                name="result.cea"
                control={control}
                defaultValue={data?.result?.cea || ""}
                rules={{
                  required: "Required",
                }}
                render={({ field }) => (
                  <Select
                    {...field}
                    fullWidth
                    label="6. Collie Eye Anomaly (CEA)"
                  >
                    {ecvoHereditaryGrades.map((value) => (
                      <MenuItem key={value} value={value}>
                        {value}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </FormControl>
            <FormControl fullWidth required sx={{ mb: 2 }}>
              <InputLabel>8. Irido-corneal Angle Abnormality (ICAA)</InputLabel>
              <Controller
                name="result.icaa"
                control={control}
                defaultValue={data?.result?.icaa || ""}
                rules={{
                  required: "Required",
                }}
                render={({ field }) => (
                  <Select
                    {...field}
                    fullWidth
                    label="8. Irido-corneal Angle Abnormality (ICAA)"
                  >
                    {ecvoIcaaGrades.map((value) => (
                      <MenuItem key={value} value={value}>
                        {value}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth required sx={{ mb: 2 }}>
              <InputLabel>11. Entropion / Trichiasis</InputLabel>
              <Controller
                name="result.entropion"
                control={control}
                defaultValue={data?.result?.entropion || ""}
                rules={{
                  required: "Required",
                }}
                render={({ field }) => (
                  <Select
                    {...field}
                    fullWidth
                    label="11. Entropion / Trichiasis"
                  >
                    {ecvoGenerativeGrades.map((value) => (
                      <MenuItem key={value} value={value}>
                        {value}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </FormControl>
            <FormControl fullWidth required sx={{ mb: 2 }}>
              <InputLabel>12. Ectropion / Macroblepharon</InputLabel>
              <Controller
                name="result.ectropion"
                control={control}
                defaultValue={data?.result?.ectropion || ""}
                rules={{
                  required: "Required",
                }}
                render={({ field }) => (
                  <Select
                    {...field}
                    fullWidth
                    label="12. Ectropion / Macroblepharon"
                  >
                    {ecvoGenerativeGrades.map((value) => (
                      <MenuItem key={value} value={value}>
                        {value}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </FormControl>
            <FormControl fullWidth required sx={{ mb: 2 }}>
              <InputLabel>13. Distichiasis / Ectopic cilia</InputLabel>
              <Controller
                name="result.distichiasis"
                control={control}
                defaultValue={data?.result?.distichiasis || ""}
                rules={{
                  required: "Required",
                }}
                render={({ field }) => (
                  <Select
                    {...field}
                    fullWidth
                    label="13. Distichiasis / Ectopic cilia"
                  >
                    {ecvoGenerativeGrades.map((value) => (
                      <MenuItem key={value} value={value}>
                        {value}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </FormControl>
            <FormControl fullWidth required sx={{ mb: 2 }}>
              <InputLabel>14. Corneal dystrophy</InputLabel>
              <Controller
                name="result.cornealDystrophy"
                control={control}
                defaultValue={data?.result?.cornealDystrophy || ""}
                rules={{
                  required: "Required",
                }}
                render={({ field }) => (
                  <Select {...field} fullWidth label="14. Corneal dystrophy">
                    {ecvoGenerativeGrades.map((value) => (
                      <MenuItem key={value} value={value}>
                        {value}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </FormControl>
            <FormControl fullWidth required sx={{ mb: 2 }}>
              <InputLabel>15. Cataract (non-congenital)</InputLabel>
              <Controller
                name="result.cataractNonCon"
                control={control}
                defaultValue={data?.result?.cataractNonCon || ""}
                rules={{
                  required: "Required",
                }}
                render={({ field }) => (
                  <Select
                    {...field}
                    fullWidth
                    label="15. Cataract (non-congenital)"
                  >
                    {ecvoGenerativeGrades.map((value) => (
                      <MenuItem key={value} value={value}>
                        {value}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </FormControl>
            <FormControl fullWidth required sx={{ mb: 2 }}>
              <InputLabel>16. Lens luxation (primary)</InputLabel>
              <Controller
                name="result.lensLuxationPrimary"
                control={control}
                defaultValue={data?.result?.lensLuxationPrimary || ""}
                rules={{
                  required: "Required",
                }}
                render={({ field }) => (
                  <Select
                    {...field}
                    fullWidth
                    label="16. Lens luxation (primary)"
                  >
                    {ecvoGenerativeGrades.map((value) => (
                      <MenuItem key={value} value={value}>
                        {value}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </FormControl>
            <FormControl fullWidth required sx={{ mb: 2 }}>
              <InputLabel>17. Retinal degeneration (PRA)</InputLabel>
              <Controller
                name="result.pra"
                control={control}
                defaultValue={data?.result?.pra || ""}
                rules={{
                  required: "Required",
                }}
                render={({ field }) => (
                  <Select
                    {...field}
                    fullWidth
                    label="17. Retinal degeneration (PRA)"
                  >
                    {ecvoGenerativeGrades.map((value) => (
                      <MenuItem key={value} value={value}>
                        {value}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth required sx={{ mb: 2 }}>
              <InputLabel>7. & 18. Other</InputLabel>
              <Controller
                name="result.other"
                control={control}
                defaultValue={data?.result?.other || ""}
                rules={{
                  required: "Required",
                }}
                render={({ field }) => (
                  <Select {...field} fullWidth label="7. & 18. Other">
                    {ecvoOtherGrades.map((value) => (
                      <MenuItem key={value} value={value}>
                        {value}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </FormControl>
          </Grid>
        </>
      );
    default:
      return null;
  }
}
