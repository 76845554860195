import React from "react";
import { useTranslation } from "react-i18next";
import { Controller, useFormContext } from "react-hook-form";

// Recoil
import { useRecoilState, useRecoilValue } from "recoil";
import { litterRegistrationState } from "recoil/registrationStates";
import { accessTokenState } from "recoil/globalStates";

// MUI
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import {
  Button,
  Divider,
  TextField,
  Typography,
  useTheme,
  useMediaQuery,
} from "@mui/material";

// Local
import { createHandleInputChange } from "constants/helpers";
import { addTmpAttachment } from "api/privateRoutes";

export default function LitterAttachments() {
  const theme = useTheme();
  const lessThanSmall = useMediaQuery(theme.breakpoints.down("sm"));
  const { t } = useTranslation();
  const { control } = useFormContext();
  const [formData, setFormData] = useRecoilState(litterRegistrationState);
  const { filePaths, puppies } = formData;
  const handleInputChange = createHandleInputChange(setFormData);
  const accessToken = useRecoilValue(accessTokenState);

  const attachmentGridRowProps = {
    spacing: 2,
    flexWrap: lessThanSmall ? "wrap" : "nowrap",
    justifyContent: "space-between",
  };

  const handleFileChange = async (e) => {
    const { name, files } = e.target;

    // Create objects with file metadata and content
    let newAttachments = [];
    for (const file of files) {
      
      // Create FormData object for attachment file
      // and save it to blob storage
      const newAttach = new FormData();
      newAttach.append("attachment", file);
      const url = await addTmpAttachment(newAttach, {accessToken});

      // Create attachment object to save attachment info
      // And store that in formData recoil object
      // including url and category = name
      newAttachments.push({
        url: url,
        category: name,
        name: file.name,
        type: file.type,
      });
    }

    setFormData((prevFormData) => ({
      ...prevFormData,
      attachments: {
        ...prevFormData.attachments,
        [name]: newAttachments,
      },
    }));

    // Handle file name display
    const path = !files.length
      ? t("noFileSelected")
      : files.length === 1
      ? `${files[0].name}`
      : `${files.length} ${t("filesSelected")}`;

    setFormData((prevFormData) => ({
      ...prevFormData,
      filePaths: {
        ...prevFormData.filePaths,
        [name]: path,
      },
    }));
  };

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} sx={{ mb: 2 }}>
          <Divider textAlign="left" sx={{ mb: 2 }}>
            <Typography variant="h2">{t("attachments")}</Typography>
          </Divider>
          <Typography variant="body">
          {t("Upload the attachments requested for registration. Attachments with health information go under \"Other attachments.\"")}
            <br />
            {t("You may upload a single image of each puppy, which will be used for their profile picture.")}
            <br />
          </Typography>
        </Grid>
        <Grid item xs={12} sx={{ mb: 2 }}>
          <Grid container
                spacing={attachmentGridRowProps.spacing}
                flexWrap={attachmentGridRowProps.flexWrap}
                justifyContent={attachmentGridRowProps.justifyContent}
                direction="column">
            {puppies && puppies.map((puppy, index) => (
              <Grid key={`images-${index}`}
                    container
                    spacing={attachmentGridRowProps.spacing}
                    flexWrap={attachmentGridRowProps.flexWrap}
                    justifyContent={attachmentGridRowProps.justifyContent}
                    alignItems="center">
                <Grid item xs={12} md={7} sx={{ display: 'flex', alignItems: 'center', flexBasis: '0', flexGrow: 1 }}>
                  {`${puppy.name} ${t("profile photo")}`}
                </Grid>
                <Grid item xs={12} md={4} sx={{ display: 'flex', alignItems: 'center', textAlign: 'left', flexBasis: '0', flexGrow: 1 }}>
                  {filePaths[`images-${index}`] || t("noFileSelected")}
                </Grid>
                <Grid item xs={12} md={4} sx={{ display: 'flex', alignItems: 'center', flexBasis: '0', flexGrow: 1 }}>
                  <Button variant="contained" component="label">
                    {t("selectFile")}
                    <input
                      hidden
                      accept="image/*"
                      name={`images-${index}`}
                      type="file"
                      onChange={handleFileChange}
                    />
                  </Button>
                </Grid>
              </Grid>
            ))}
          
            <Grid container
                  spacing={attachmentGridRowProps.spacing}
                  flexWrap={attachmentGridRowProps.flexWrap}
                  justifyContent={attachmentGridRowProps.justifyContent}
                  alignItems="center">
              <Grid item xs={12} md={7} sx={{ display: 'flex', alignItems: 'center', flexBasis: '0', flexGrow: 1 }}>
                {`${t("attachments")}`}
              </Grid>
              <Grid item xs={12} md={4} sx={{ display: 'flex', alignItems: 'center', textAlign: 'left', flexBasis: '0', flexGrow: 1 }}>
                {filePaths.misc || t("noFileSelected")}
              </Grid>
              <Grid item xs={12} md={4} sx={{ display: 'flex', alignItems: 'center', flexBasis: '0', flexGrow: 1 }}>
                <Button variant="contained" component="label">
                  {t("selectFile")}
                  <input
                    hidden
                    multiple
                    accept="image/*,.pdf"
                    name="misc"
                    type="file"
                    onChange={handleFileChange}
                  />
                </Button>
              </Grid>
            </Grid>

{/*
          {verificationNeeded?.sire === "attachment" && (
            <Grid
              container
              spacing={attachmentGridRowProps.spacing}
              flexWrap={attachmentGridRowProps.flexWrap}
              justifyContent={attachmentGridRowProps.justifyContent}
              alignItems="center"
            >
              <Grid item xs={12} md={7}>
                {`${t("siresMatingCertificate")} *`}
              </Grid>
              <Grid item xs={12} md={4}>
                {filePaths.sireCertificate || t("noFileSelected")}
              </Grid>
              <Grid item xs={12} md={4}>
                <Button variant="contained" component="label">
                  {t("selectFile")}
                  <input
                    hidden
                    multiple
                    accept="image/*,.pdf"
                    name="sireCertificate"
                    type="file"
                    onChange={handleFileChange}
                  />
                </Button>
              </Grid>
            </Grid>
          )}
          {verificationNeeded?.dam === "attachment" && (
            <Grid
              container
              spacing={attachmentGridRowProps.spacing}
              flexWrap={attachmentGridRowProps.flexWrap}
              justifyContent={attachmentGridRowProps.justifyContent}
            >
              <Grid item xs={12} md={7}>
                {`${t("damsMatingCertificate")} *`}
              </Grid>
              <Grid item xs={12} md={4}>
                {filePaths.damCertificate || t("noFileSelected")}
              </Grid>
              <Grid item xs={12} md={4}>
                <Button variant="contained" component="label">
                  {t("selectFile")}
                  <input
                    hidden
                    multiple
                    accept="image/*,.pdf"
                    name="damCertificate"
                    type="file"
                    onChange={handleFileChange}
                  />
                </Button>
              </Grid>
            </Grid>
          )}

           <Grid
            container
            spacing={attachmentGridRowProps.spacing}
            flexWrap={attachmentGridRowProps.flexWrap}
            justifyContent={attachmentGridRowProps.justifyContent}
          >
            <Grid item xs={12} md={7}>
              {`${t("puppiesMicrochipCertificates")} *`}
            </Grid>
            <Grid item xs={12} md={4}>
              {filePaths.microchipCert || t("noFilesSelected")}
            </Grid>
            <Grid item xs={12} md={4}>
              <Button variant="contained" component="label">
                {t("selectFiles")}
                <input
                  hidden
                  multiple
                  accept="image/*,.pdf"
                  name="microchipCert"
                  type="file"
                  onChange={handleFileChange}
                />
              </Button>
            </Grid>
            </Grid> */}
      </Grid>
        </Grid>

        <Grid item xs={12} sx={{ mb: 2 }}>
          <Divider textAlign="left" sx={{ mb: 2 }}>
            <Typography variant="h2">{t("Notes")}</Typography>
          </Divider>
          <Typography variant="body">
            {t("In the \"Notes\" field, include any additional information for the registrar, including any URLs for health information.")}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} sx={{ mb: 2 }}>
          <Controller
          name="additionalInfo"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <TextField
              {...field}
              label="Notes"
              multiline
              rows={4}
              fullWidth
              variant="outlined"
              onChange={(e) => handleInputChange(e, field)}
            />
          )}
        />
        </Grid>

      </Grid>
    </>
  );
}
