import React from "react";
import { useTranslation } from "react-i18next";

// Recoil
import { snackBarState } from "recoil/globalStates";
import { selectedDocByIdState } from "recoil/adminStates";
import { useRecoilState, useRecoilValue } from "recoil";

// Routes
import { updateLitter } from "api/adminRoutes";

// Local
import LitterForm from "../forms/litterForm";
import { AdminNotFound } from "components/adminDashboardComponents/adminNotFound";

function AdminLitterView() {
  const { t } = useTranslation();
  const [snackbarState, setSnackbarState] = useRecoilState(snackBarState);

  const litter = useRecoilValue(selectedDocByIdState("litter"));

  const handleSubmit = (accessToken, newLitter, id) => {
    // TODO handle multiple organizations
    const organization = newLitter.organizations[0];
    if (!organization) {
      throw new Error("No organization specified.");
    }
    newLitter.certificates.forEach((certificate) => {
      certificate.organization = organization._id;
    });

    updateLitter(id, newLitter, {
      accessToken: accessToken,
      pending: newLitter.__t === "Pending",
      options: { new: true },
    }).then((response) => {
      if (response.error) {
        setSnackbarState({
          message: response.message,
          severity: "error",
          open: true,
        });
      } else {
        setSnackbarState({
          message: t("updateSuccess", { docType: "Litter" }),
          severity: "success",
          open: true,
        });
        setTimeout(() => {
          window.location.reload(true);
        }, 1000);
      }
    });
  };

  return litter ? (
    <LitterForm
      litter={litter}
      formType={litter.__t === "Pending" ? "pendingLitter" : "litter"}
      action="edit"
      emitHandleSubmit={handleSubmit}
    />
  ) : (
    <AdminNotFound docType="Litter" />
  );
}

export default AdminLitterView;
