import { ArrowDropDown, Save } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Divider,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  InputLabel,
  Paper,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { Controller, useForm } from "react-hook-form";

// This part of user form is done as separate form-component so that these
// fields are not updated every time user is updated

export default function UserFormAdminComponent({
  initialUser,
  accessToken,
  emitHandleSubmit,
}) {
  const {
    control: control,
    watch: watch,
    handleSubmit: handleSubmit,
    formState: { isSubmitting, isDirty },
  } = useForm();

  const formdata = watch();

  const onSubmit = (data) => {
    emitHandleSubmit(accessToken, data, initialUser?._id);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid item xs={12}>
        <Divider sx={{ my: 8 }} />
        <Accordion>
          <AccordionSummary expandIcon={<ArrowDropDown />}>
            <Typography variant="h5" color="error">
              Admin
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Paper variant="outlined" sx={{ borderColor: "error.main", p: 2 }}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Typography variant="body" color="error">
                    This section is visible to admins only.
                    <br />
                    <br />
                    <b>
                      Do NOT change these values unless you are 100% certain you
                      know what you are doing and are authorized to do so!
                    </b>
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Controller
                    name="userId"
                    control={control}
                    defaultValue={initialUser?.userId || ""}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        fullWidth
                        label="Auth0 UserId"
                        helperText="This should generate automatically. Do not change if not necessary."
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormGroup>
                    <InputLabel>Verified?</InputLabel>
                    <FormControlLabel
                      control={
                        <Controller
                          name="isVerified"
                          control={control}
                          defaultValue={initialUser?.isVerified || false}
                          render={({ field }) => (
                            <Switch
                              {...field}
                              name="isVerified"
                              checked={field.value}
                            />
                          )}
                        />
                      }
                      label={formdata.isVerified ? "Yes" : "No"}
                    />
                    <FormHelperText>
                      Has DB user been connected with auth0 user (by default,
                      user should do this)
                    </FormHelperText>
                  </FormGroup>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Controller
                    name="access_token"
                    control={control}
                    defaultValue={initialUser?.access_token || ""}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        fullWidth
                        label="Access token"
                        helperText="This should generate automatically. Do not change if not necessary."
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={isSubmitting || !isDirty}
                    sx={{ mr: 2, float: "right" }}
                  >
                    <Save fontSize="small" /> Save changes
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          </AccordionDetails>
        </Accordion>
      </Grid>
    </form>
  );
}
