import React, { useState } from "react";
import { useTranslation } from "react-i18next";

// Recoil
import { adminAllUsersState, adminAllDogsState } from "recoil/adminStates";
import { snackBarState } from "recoil/globalStates";
import { useRecoilState, useRecoilValue } from "recoil";

// Components
import FormModal from "components/adminDashboardComponents/formModal";
import { GetDogColumns } from "components/adminDashboardComponents/adminDatagridColumns";

// MUI
import { Button, Divider, Grid, Stack, Typography } from "@mui/material";
import { ChevronRight } from "@mui/icons-material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";

function AdminAllDogs() {
  const { t } = useTranslation();
  const [modalOpen, setModalOpen] = useState(false);
  const [snackbarState, setSnackbarState] = useRecoilState(snackBarState);

  const dogs = useRecoilValue(adminAllDogsState);
  const users = useRecoilValue(adminAllUsersState);

  const handleRowClick = (params) => {
    window.location = `/admin/dog/${params.row._id}`;
  };

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Stack
            direction={{ xs: "column", sm: "row" }}
            justifyContent={{ xs: "center", sm: "space-between" }}
            alignItems="center"
            alignContent={"center"}
            spacing={{ xs: 2, sm: 0 }}
          >
            <Typography variant="h2">{t("Registered dogs")}</Typography>{" "}
            <Button
              variant="outlined"
              color="primary"
              endIcon={<ChevronRight />}
              href="/dog-registration"
            >
              {t("addregistereddog")}
            </Button>
          </Stack>
        </Grid>

        <Grid item xs={12}>
          <DataGrid
            autoHeight
            pageSize={50}
            onRowClick={handleRowClick}
            rows={dogs}
            getRowId={(row) => row._id}
            columns={GetDogColumns("dog")}
            components={{ Toolbar: GridToolbar }}
            disableSelectionOnClick
          />
        </Grid>
      </Grid>
    </>
  );
}

export default AdminAllDogs;
