import React from "react";
import { useTranslation } from "react-i18next";

// MUI
import { Paper, Typography } from "@mui/material";

import { DogCard } from "elements/DogCard";

// Recoil
import { littersBySiblingIdState } from "recoil/publicStates";
import { useRecoilValue } from "recoil";

export default function SiblingsInfo({ dog, organization }) {
  const { t } = useTranslation();

  const litter = useRecoilValue(littersBySiblingIdState(dog._id));

  return (
    <>
      {litter && litter.length > 0 ? (
        litter.map((dog) => <DogCard key={dog._id} dog={dog} organization={organization} />)
      ) : (
        <Paper sx={{ p: 2 }}>
          <Typography variant="body">{t("noSiblings")}</Typography>
        </Paper>
      )}
    </>
  );
}
