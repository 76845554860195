import React from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

// Recoil
import { breederState, litterByIdState, getOrgByPrefixState } from "recoil/publicStates";
import { useRecoilValue } from "recoil";

// MUI
import { Typography, Divider, Box, Grid, Paper } from "@mui/material";

// Components
import LitterInfoBox from "components/litterProfileComponents/LitterInfoBox";
import ParentsComp from "components/litterProfileComponents/ParentsComp";
import { DogCard } from "elements/DogCard";
import LitterBreederInfoBox from "components/litterProfileComponents/LitterBreederInfoBox";
import OrganizationCard from "elements/OrganizationCard";

export default function LitterProfile() {
  const { t } = useTranslation();
  const { id, organizationPrefix } = useParams();

  const organization = useRecoilValue(getOrgByPrefixState(organizationPrefix));

  if (!organization) {
    return (
      <Paper square sx={{ p: 2 }}>
        <Typography variant="body">{t("No organization found with abbreviation")} <i>{organizationPrefix}</i></Typography>
      </Paper>
    );
  }

  const litter = useRecoilValue(litterByIdState(id));
  const breeder = useRecoilValue(breederState(litter.breeder._id));

  return (
    <>
    <OrganizationCard organization={organization}/>

    <Grid container direction={{ xs: "column", sm: "row" }} spacing={5}>
      <Grid item xs={12} md={5}>
        <LitterInfoBox litter={litter} />
        <LitterBreederInfoBox breeder={breeder} organization={organization} />
      </Grid>
      <Grid item xs={12} md={7}>
        <Box sx={{ mb: 5 }}>
          <ParentsComp sire={litter?.sire} dam={litter?.dam} organization={organization}/>
        </Box>

        <Divider textAlign="left" sx={{ mb: 2 }}>
          <Typography variant="h3">{t("Puppies")}</Typography>{" "}
        </Divider>
        {litter.puppies?.length > 0 ? (
          litter.puppies?.map((dog, i) => <DogCard key={i} dog={dog} organization={organization} />)
        ) : (
          <Paper>{t("noPuppies")}</Paper>
        )}
      </Grid>
    </Grid>
    </>
  );
}
