import React, { useEffect, useState } from "react";
import {
  Controller,
  FormProvider,
  useFieldArray,
  useForm,
} from "react-hook-form";

import { useTranslation } from "react-i18next";

import { useAuth0 } from "@auth0/auth0-react";
import dayjs from "dayjs";

// Recoil
import { constSelector, useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
  accessTokenState,
  loadingState,
  snackBarState,
  userState,
} from "recoil/globalStates";
import {
  adminAllCertsState,
  adminAllOrgsState,
  adminAllUsersState,
  adminGetBreederByIdState,
  adminPendingDogsState,
} from "recoil/adminStates";
import { dogsBySexState, treedogsBySexState } from "recoil/publicStates";

// MUI
import {
  Alert,
  AlertTitle,
  Autocomplete,
  Button,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Delete, Add, Launch } from "@mui/icons-material";

// Local
import AttachmentList from "components/formComponents/attachmentList";
import PuppiesList from "components/formComponents/puppiesList";
import VerificationsList from "components/formComponents/verificationsList";

// Routes
import {
  registerLitter,
  deleteLitter,
} from "api/adminRoutes";

import ParentAutocomplete from "components/formComponents/parentAutocomplete";
import FormHeader from "components/adminDashboardComponents/formHeader";
import AcceptButton from "components/adminDashboardComponents/formRegistrationAcceptButton";
import FormButtons from "components/adminDashboardComponents/formButtons";

export default function LitterForm({
  litter: initialLitter,
  formType,
  action,
  emitHandleSubmit,
  handleModalClose
}) {
  const { isAuthenticated } = useAuth0();
  const currentUser = useRecoilValue(
    isAuthenticated ? userState : constSelector(null)
  );

  const { t } = useTranslation();
  const accessToken = useRecoilValue(accessTokenState);
  const [snackbarState, setSnackbarState] = useRecoilState(snackBarState);
  const setLoading = useSetRecoilState(loadingState);

  const users = useRecoilValue(adminAllUsersState);
  const femaleDogs = useRecoilValue(dogsBySexState("female")).
    concat(useRecoilValue(treedogsBySexState("female")));
  const maleDogs = useRecoilValue(dogsBySexState("male")).
    concat(useRecoilValue(treedogsBySexState("male")));

  const pendingdogs = useRecoilValue(adminPendingDogsState);

  const methods = useForm({ mode: "all" });
  const {
    control,
    watch,
    handleSubmit,
    setValue,
    formState: { errors, isSubmitting, isDirty },
  } = methods;

  const {
    append: appendCerts,
    fields: certFields,
    remove: removeCerts,
  } = useFieldArray({ control, name: "certificates" });

  const formdata = watch();
  const breeder = formdata.breeder;
  const certificates = formdata.certificates;

  const [litter, setLitter] = useState(initialLitter || { puppies: [] });
  const allCertificates = useRecoilValue(adminAllCertsState);
  const litterCertificates = allCertificates.filter(
    (cert) => cert.type === "litter"
  );

  // Initialize some form values from the litter object.
  useEffect(() => {
    setValue("certificates", litter?.certificates);
  }, [litter]);

  const [selectedBreeder, setSelectedBreeder] = useState(
    useRecoilValue(adminGetBreederByIdState(litter.breeder))
  );
  const [selectedSire, setSelectedSire] = useState(litter.sire);
  const [selectedDam, setSelectedDam] = useState(litter.dam);

  const organizations = useRecoilValue(adminAllOrgsState);

  // Handle form submit, call the parent components handleSubmit function that send the document to API patch
  const onSubmit = (data) => {
    data.__t = litter.__t;

    if (data.breeder) {
      data.breeder = data.breeder._id;
    }

    data.sire = data.sire?._id;
    data.dam = data.dam?._id;
    emitHandleSubmit(accessToken, data, litter?._id);
  };

  // Handle deletion, prompt for confirm before the api call is done,
  // after removal, redirect user to listing
  const handleDelete = () => {
    const confirmation = confirm(
      "Are you sure you want to delete this litter? This process can NOT be undone."
    );
    if (!confirmation) alert("Cancelled deletion.");

    deleteLitter(litter._id, { accessToken }).then((response) => {
      if (response.error) {
        setSnackbarState({
          message: response.message,
          severity: "error",
          open: true,
        });
      } else {
        alert(`${formType} ${response._id} has been removed.`);
        window.location.replace(`/admin/all${formType}s`);
      }
    });
  };

  const handleAccept = () => {
    if (
      confirm(
        "Are you sure you want to accept this litter? It is going to be published in the public database."
      )
    ) {
      setLoading(true);
      registerLitter(litter._id, litter.organizations[0].organization.prefix, { accessToken }).then((res) => {
        if (res.error) {
          setLoading(false);
          setSnackbarState({
            message: res.message,
            severity: "error",
            open: true,
          });
        } else {
          setLoading(false);
          alert("Litter has been accepted and transferred to public database.");
          window.location.replace(`/admin/all${formType}s`);
        }
      });
    } else {
      alert(`Cancelled accepting litter`);
    }
  };

  // add new object to certificates array in the form state
  const addCerts = () => {
    appendCerts({
      certificate: "",
      issuingDate: dayjs().toISOString(),
    });
  };

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid
            container
            direction="row"
            spacing={3}
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            <Grid item xs={12}>
              <FormHeader
                action={action}
                formType={formType}
                data={litter}
                handleModalClose={handleModalClose}
              />
              <AcceptButton
                action={action}
                formType={formType}
                pendingRegistration={litter.__t === "Pending"}
                handleAccept={handleAccept}
              />
            </Grid>
            {litter.__t !== "Pending" && (
              <IconButton
              href={`/${litter.organizations[0].organization.prefix}/litter/${litter._id}`}
              target="_blank"
            >
              <Launch />
            </IconButton>
              )}
            <Grid item xs={12} md={6}>
              <Controller
                name="name"
                control={control}
                defaultValue={litter?.name || ""}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label="Litter name"
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <Controller
                name="dateOfBirth"
                control={control}
                defaultValue={litter?.dateOfBirth || null}
                rules={{
                  required: "Required",
                }}
                render={({ field: { ref, name, ...field } }) => (
                  <DatePicker
                    {...field}
                    required
                    inputRef={ref}
                    label="Date of birth"
                    onChange={(e) => field.onChange(dayjs(e).toISOString())}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        required
                        name={name}
                        error={errors?.dateOfBirth && true}
                        helperText={errors && errors?.dateOfBirth?.message}
                      />
                    )}
                    disableFuture
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                name="organizations"
                onChange={([, data]) => data}
                defaultValue={litter.organizations || null}
                control={control}
                render={({ field: { onChange, ...props } }) => (
                  <Autocomplete
                    fullWidth
                    multiple
                    label="Organization"
                    renderOption={(props, option) => (
                      <li {...props} key={option._id}>
                        {option.name}
                      </li>
                    )}
                    options={organizations}
		  isOptionEqualToValue={(option, value) =>
		    option._id === (value.organization ? value.organization._id : value._id)
}
		  getOptionLabel={(option) => `${option.organization ? option.organization.name : option.name}`}
                    onChange={(e, data) => onChange(data)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Organization"
                        helperText={
                          currentUser.role === "admin"
                            ? "Only select one!"
                            : currentUser.role === "manager"
                            ? `NOTE! If ${formType} is removed from your organization, you will lose access to it`
                            : null
                        }
                      />
                    )}
                    {...props}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Divider textAlign="left" sx={{ my: 2 }}>
                <Typography variant="h5">Breeder</Typography>
              </Divider>
            </Grid>
            {breeder === "hidden" ? (
              <Grid item xs={12}>
                <Alert severity="info">
                  <AlertTitle>
                    This litter has breeder that is outside of your organization
                  </AlertTitle>
                  Managers can not edit breeder information if the breeder is
                  part of a different organization. Please contact the
                  administrator for more information.
                </Alert>
              </Grid>
            ) : (
              <>
                <Grid item xs={12} md={6}>
                  <Controller
                    name="breeder"
                    rules={{
                      required: "Required",
                    }}
                    render={({ field: { onChange, ...props } }) => (
                      <Autocomplete
                        fullWidth
                        required
                        options={users}
                        getOptionLabel={(option) =>
                          `${option.name} (${option.email})`
                        }
                        renderOption={(props, option) => (
                          <li {...props} key={option._id}>
                            {option.name} {" ("}
                            {option.email}
                            {") "}
                          </li>
                        )}
                        isOptionEqualToValue={(option, value) =>
                          option._id == value._id
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            required
                            label="Breeder"
                            InputProps={{
                              ...params.InputProps,
                            }}
                          />
                        )}
                        onChange={(e, data) => onChange(data)}
                        {...props}
                      />
                    )}
                    onChange={([, data]) => data}
                    defaultValue={selectedBreeder}
                    control={control}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography variant="h5">{t("Breeder preview")}</Typography>
                  <List>
                    {formdata.breeder && (
                      <ListItem secondaryAction={formdata.breeder.regNumber}>
                        <ListItemText
                          primary={`${formdata.breeder.name}`}
                          secondary={`${formdata.breeder.email} (${formdata.breeder._id})`}
                        />
                      </ListItem>
                    )}
                  </List>
                </Grid>
              </>
            )}

            <Grid item xs={12}>
              <Divider textAlign="left" sx={{ my: 2 }}>
                <Typography variant="h5">Parents</Typography>
              </Divider>
            </Grid>
            <Grid item xs={12} md={6}>
              <ParentAutocomplete
                label="Sire"
                name="sire"
                options={maleDogs}
                value={selectedSire}
                control={control}
              />
              <List sx={{ mt: 2 }}>
                <ListItem>
                  <Typography variant="h5">Sire preview</Typography>
                </ListItem>
                {formdata.sire && (
                  <ListItem>
                    <ListItemButton
                      href={`/admin/dog/${formdata.sire._id}`}
                      target="_blank"
                    >
                      <ListItemIcon>
                        <Launch />
                      </ListItemIcon>
                      <ListItemText
                        primary={`${formdata.sire?.name} ${formdata.sire?.regNumber || ""}`}
                        secondary={`${formdata.sire._id}`}
                      />
                    </ListItemButton>
                  </ListItem>
                )}
              </List>
            </Grid>
            <Grid item xs={12} md={6}>
              <ParentAutocomplete
                label="Dam"
                name="dam"
                options={femaleDogs}
                value={selectedDam}
                control={control}
              />
              <List sx={{ mt: 2 }}>
                <ListItem>
                  <Typography variant="h5">Dam preview</Typography>
                </ListItem>
                {formdata.dam && (
                  <ListItem>
                    <ListItemButton
                      href={`/admin/dog/${formdata.dam._id}`}
                      target="_blank"
                    >
                      <ListItemIcon>
                        <Launch />
                      </ListItemIcon>
                      <ListItemText
                        primary={`${formdata?.dam?.name} ${formdata.dam?.regNumber || ""}`}
                        secondary={`${formdata.dam._id}`}
                      />
                    </ListItemButton>
                  </ListItem>
                )}
              </List>
            </Grid>
            <Grid item xs={12}>
              <Divider textAlign="left" sx={{ my: 2 }}>
                  <Typography variant="h5">Puppies</Typography>
                </Divider>
              <PuppiesList
                litter={litter}
                formType={formType} />
            </Grid>

            {action === "edit" && litter.__t === "Pending" && litter.verifications && (
              <Grid item xs={12}>
                <Divider textAlign="left" sx={{ my: 2 }}>
                  <Typography variant="h5">Verifications</Typography>
                </Divider>
                <VerificationsList verifications={litter.verifications} />
              </Grid>
            )}

          {/* Certificates */}
          {action === "edit" && (
            <>
            <Grid item xs={12}>
            <Divider textAlign="left" sx={{ my: 2 }}>
              <Typography variant="h5">Certificates</Typography>
            </Divider>
          </Grid>
          <Grid item xs={12}>
            {certFields.length > 0 ? (
              certFields.map((field, i) => (
                <React.Fragment key={field.id}>
                  <Stack
                    direction={{ xs: "column", md: "row" }}
                    alignItems={"flex-start"}
                    spacing={2}
                    p={2}
                  >
                    <Typography variant="body">{i + 1}.</Typography>

                    <Controller
                      name={`certificates[${i}].certificate`}
                      control={control}
                      defaultValue={certificates[i]?.certificate || ""}
                      rules={{ required: "Certificate is required" }}
                      render={({ field: { onChange, ...props }, fieldState: { error } }) => (
                        <Autocomplete
                          fullWidth
                          freeSolo
                          label="Certificate"
                          options={litterCertificates}
                          isOptionEqualToValue={(option, value) => option._id == value}
                          getOptionLabel={(option) => (option.name ? option.name : "")}
                          onChange={(e, data) => onChange(data)}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              fullWidth
                              label="Certificate"
                              error={!!error}
                              helperText={error ? error.message : ""}
                              InputProps={{
                                ...params.InputProps,
                              }}
                            />
                          )}
                          {...props}
                        />
                      )}
                    />
                    <Controller
                      name={`certificates[${i}].issuingDate`}
                      control={control}
                      defaultValue={certificates[i]?.issuingDate || null}
                      render={({ field: { ref, name, onChange, ...field } }) => (
                        <DatePicker
                          {...field}
                          inputRef={ref}
                          label="Issuing date"
                          onChange={(date) => {
                            const formattedDate = dayjs(date).format('MM/DD/YYYY');
                            onChange(formattedDate);
                          }}
                          renderInput={(params) => (
                            <TextField {...params} fullWidth name={name} />
                          )}
                        />
                      )}
                    />

                    <IconButton
                      onClick={() => {
                        if (
                          confirm("Are you sure you want to remove this certificate?")
                        ) {
                          removeCerts(i);
                        }
                      }}
                    >
                      <Delete />
                    </IconButton>
                  </Stack>
                </React.Fragment>
              ))
            ) : (
              <Typography variant="body">No certificates</Typography>
            )}
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="outlined"
              startIcon={<Add />}
              onClick={addCerts}
              sx={{ float: "right", mt: 2 }}
            >
              Add certificate
            </Button>
          </Grid>
        </>
            )}

            {/* Attachments */ }
            {action === "edit" && (
              <Grid item xs={12}>
                <Divider textAlign="left" sx={{ my: 2 }}>
                  <Typography variant="h5">Attachments</Typography>
                </Divider>
                <AttachmentList parentDoc={litter} />
              </Grid>
            )}

            {/* Final buttons */ }
          <Grid item xs={12}>
              <FormButtons
                action={action}
                handleDelete={handleDelete}
                isSubmitting={isSubmitting}
                isDirty={isDirty}
                errors={errors}
              />
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </>
  );
}
