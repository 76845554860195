import React from "react";

// MUI
import { Paper, Modal, useTheme, useMediaQuery } from "@mui/material";

function FormModal(props) {
  const theme = useTheme();
  const lessThanSmall = useMediaQuery(theme.breakpoints.down("md"));
  const width = lessThanSmall ? "96%" : "70%";

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    height: "auto",
    maxHeight: "90%",
    overflowX: "hidden",
    width: width,
  };

  return (
    <div>
      <Modal open={props.open} onClose={props.handleClose}>
        <Paper sx={style}>{props.children}</Paper>
      </Modal>
    </div>
  );
}

export default FormModal;
