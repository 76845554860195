import * as React from "react";

import { List } from "@mui/material";

import ListLitterCard from "../usersBredDogsComponents/ListLitterCard";

export default function BredDogsList({ litters, handleSelection }) {
  return (
    <List
      sx={{
        maxHeight: { xs: "none", md: 600 },
        minWidth: 400,
        overflowY: "scroll",
        overflowX: "hidden",
      }}
    >
      <div>
        {litters?.map((litter, i) => (
          <ListLitterCard
            key={i}
            litter={litter}
            handleSelection={handleSelection}
          />
        ))}
      </div>{" "}
    </List>
  );
}
