import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

// Recoil
import { useRecoilState, useRecoilValue } from "recoil";
import { accessTokenState } from "recoil/globalStates";
import { dogRegistrationState } from "recoil/registrationStates";

// MUI
import {
  Box,
  Button,
  Divider,
  Fab,
  Grid,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Upload } from "@mui/icons-material";

// Local
import { createHandleInputChange } from "constants/helpers";
import { addTmpAttachment } from "api/privateRoutes";

export default function DogAttachments() {
  const theme = useTheme();
  const lessThanSmall = useMediaQuery(theme.breakpoints.down("sm"));
  const { t } = useTranslation();
  const [formData, setFormData] = useRecoilState(dogRegistrationState);
  const { filePaths } = formData;
  const { control } = useFormContext();
  const handleInputChange = createHandleInputChange(setFormData);
  const accessToken = useRecoilValue(accessTokenState);

  const handleFileChange = async (e) => {
    const { name, files } = e.target;

    // Create objects with file metadata and content
    let newAttachments = [];
    for (const file of files) {
      
      // Create FormData object for attachment file
      // and save it to blob storage
      const newAttach = new FormData();
      newAttach.append("attachment", file);
      const url = await addTmpAttachment(newAttach, {accessToken});

      // Create attachment object to save attachment info
      // And store that in formData recoil object
      // including url and category = name
      newAttachments.push({
        url: url,
        category: name,
        name: file.name,
        type: file.type,
      });
    }

    setFormData((prevFormData) => ({
      ...prevFormData,
      attachments: {
        ...prevFormData.attachments,
        [name]: newAttachments,
      },
    }));

    // Handle file name display
    const path = !files.length
      ? t("noFileSelected")
      : files.length === 1
      ? `${files[0].name}`
      : `${files.length} ${t("filesSelected")}`;

    setFormData((prevFormData) => ({
      ...prevFormData,
      filePaths: {
        ...prevFormData.filePaths,
        [name]: path,
      },
    }));
  };

  return (
    <>
      <Grid container>
      <Grid item xs={12} sx={{ mb: 2 }}>
          <Divider textAlign="left" sx={{ mb: 2 }}>
            <Typography variant="h2">{t("attachments")}</Typography>
          </Divider>
          <Typography variant="body">
            {t("Upload the attachments requested for registration. Attachments with health information go under \"Other attachments.\"")}
          </Typography>
        </Grid>

        <Grid container spacing={4} sx={{ mt: 4, mb: 4 }}>
          <Grid item xs={12} sm={6}>
            <Box display="flex" alignItems="center" justifyContent="space-between">
              <Box>
                <Typography variant="body1">
                  {t("dogsPedigree") + " (1)"}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {filePaths.pedigreeCert || t("noFileSelected")}
                </Typography>
              </Box>
              <Box>
                {lessThanSmall ? (
                  <Fab color="primary" size="small" component="label">
                    <Upload />
                    <input
                      hidden
                      accept="image/*,.pdf"
                      name="pedigreeCert"
                      type="file"
                      onChange={handleFileChange}
                    />
                  </Fab>
                ) : (
                  <Button variant="contained" component="label">
                    {t("uploadFile")}
                    <input
                      hidden
                      accept="image/*,.pdf"
                      name="pedigreeCert"
                      type="file"
                      onChange={handleFileChange}
                    />
                  </Button>
                )}
              </Box>
            </Box>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Box display="flex" alignItems="center" justifyContent="space-between">
              <Box>
                <Typography variant="body1">
                  {t("Test results and other attachments")}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {filePaths.misc || t("noFilesSelected")}
                </Typography>
              </Box>
              <Box>
                {lessThanSmall ? (
                  <Fab color="primary" size="small" component="label">
                    <Upload />
                    <input
                      hidden
                      multiple
                      accept="image/*,.pdf"
                      name="misc"
                      type="file"
                      onChange={handleFileChange}
                    />
                  </Fab>
                ) : (
                  <Button variant="contained" component="label">
                    {t("uploadFile")}
                    <input
                      hidden
                      multiple
                      accept="image/*,.pdf"
                      name="misc"
                      type="file"
                      onChange={handleFileChange}
                    />
                  </Button>
                )}
              </Box>
            </Box>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Box display="flex" alignItems="center" justifyContent="space-between">
              <Box>
                <Typography variant="body1">
                  {t("Images of dog")}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {filePaths.profileImage || t("noFilesSelected")}
                </Typography>
              </Box>
              <Box>
                {lessThanSmall ? (
                  <Fab color="primary" size="small" component="label">
                    <Upload />
                    <input
                      hidden
                      multiple
                      accept="image/*"
                      name="profileImage"
                      type="file"
                      onChange={handleFileChange}
                    />
                  </Fab>
                ) : (
                  <Button variant="contained" component="label">
                    {t("uploadFile")}
                    <input
                      hidden
                      multiple
                      accept="image/*"
                      name="profileImage"
                      type="file"
                      onChange={handleFileChange}
                    />
                  </Button>
                )}
              </Box>
            </Box>
          </Grid>
        </Grid>
        <Grid item xs={12} sx={{ mb: 2 }}>
          <Divider textAlign="left" sx={{ mb: 2 }}>
            <Typography variant="h2">{t("Notes")}</Typography>
          </Divider>
          <Typography variant="body">
            {t("In the \"Notes\" field, include any additional information for the registrar, including any URLs for health information.")}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} sx={{ mb: 2 }}>
          <Controller
          name="additionalInfo"
          control={control}
          defaultValue={formData.additionalInfo}
          render={({ field }) => (
            <TextField
              {...field}
              label="Notes"
              value={field.value}
              multiline
              rows={4}
              fullWidth
              variant="outlined"
              onChange={(e) => handleInputChange(e, field)}
            />
          )}
        />
        </Grid>
      </Grid>
    </>
  );
}
