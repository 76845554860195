import React from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

// MUI
import Typography from "@mui/material/Typography";
import {
  ListItemButton,
  Grid,
  Link,
  Paper,
} from "@mui/material";
import { styled } from "@mui/material/styles";

const StyledListButton = styled(ListItemButton)(({ theme }) => ({
  padding: 0,
}));

export default function OrganizationCard({ organization }) {
  let { t } = useTranslation();
  
  if (! organization) {
    return (
    <Paper square sx={{ p: 2 }}>
      <Typography variant="body">{t("No organization specified.")}</Typography>
    </Paper>
    );
  }

  const location = useLocation();
  const targetPath = `/organization/${organization.prefix}`;

  return (
    <Paper sx={{ mb: 2, p: 3 }}>
      <Grid
        container
        sx={{ mb: 2 }}
        alignItems="center"
        justifyContent="space-between"
        spacing={3}
      >
        <Grid item xs={12} sm={9}>
          { /* This is where org logo would go
          <Box
            sx={{ py: 1, height: 100 }}
            component="img"
            src={FDCLogo}
            alt="{organization.name} logo"
          />
          */ }
       <Typography variant="h1">
        {location.pathname === targetPath ? (
          organization.name
        ) : (
          <StyledListButton href={targetPath}>
            {organization.name}
          </StyledListButton>
        )}
      </Typography>
           <Typography variant="body1">
            {organization.url && <Link href={organization.url} style={{ textDecoration: 'none' }}>{organization.url}</Link>}
          </Typography>
  
        </Grid>
        </Grid>
        </Paper>
  );
}
