import React, { useEffect, useState, useContext } from "react";
import { createContext } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { useAuth0 } from "@auth0/auth0-react";

import { constSelector, useRecoilValue } from "recoil";
import { accessTokenState, userState } from "recoil/globalStates";

// MUI
import {
  Autocomplete,
  Button,
  Divider,
  Grid,
  IconButton,
  Paper,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import { ChevronRight } from "@mui/icons-material";

// Local
import {
  getAllDogs,
  getAllTreedogs,
  getDogById,
  getTreedogById,
  updateDog,
  updateTreedog,
} from "api/adminRoutes";

const AllSires = createContext();
const AllDams = createContext();

export default function AdminPedigreeTool(props) {
  const { t } = useTranslation();
  const { id } = useParams();
  const [dogId, setDogId] = useState(false);
  const dog = dogId || id;

// TODO tree dogs can be male or female now
  const [treeDogs, setTreeDogs] = useState([]);
  const [sires, setSires] = useState([]);
  const [dams, setDams] = useState([]);
  const accessToken = useRecoilValue(accessTokenState);

  useEffect(() => {
    getAllTreedogs({
      accessToken,
      query: { },
      options: { lean: true },
    }).then((response) => {
      setTreeDogs(
        response.error
          ? []
          : response.map((d) => {
              d.isDog = false;
              return d;
            })
      );
    });
  }, []);
  useEffect(() => {
    getAllDogs({
      accessToken,
      query: { sex: "male" },
      options: { lean: true },
    }).then((response) =>
      setSires(
        response.map((d) => {
          d.isDog = true;
          return d;
        })
      )
    );
  }, []);
  useEffect(() => {
    getAllDogs({
      accessToken,
      query: { sex: "female" },
      options: { lean: true },
    }).then((response) =>
      setDams(
        response.map((d) => {
          d.isDog = true;
          return d;
        })
      )
    );
  }, []);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Stack
          direction={{ xs: "column", sm: "row" }}
          justifyContent={{ xs: "center", sm: "space-between" }}
          alignItems="center"
          alignContent={"center"}
          spacing={{ xs: 2, sm: 0 }}
        >
          <Typography variant="h2">Pedigree tool</Typography>{" "}
          <Button
            variant="outlined"
            endIcon={<ChevronRight />}
            href="/admin/treedog-addition"
          >
            {t("Add new tree dog")}
          </Button>
        </Stack>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body">
          Create a pedigree for selected dog. Form updates automatically every
          time you add, change or remove a dog.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        {/* {error.error && <ErrorAlert msg={error.msg} />}
        {updateSuccessful && <SuccessAlert />} */}
      </Grid>

      <Grid item xs={12}>
        <AllDams.Provider value={[...dams, ...treeDogs]}>
          <AllSires.Provider value={[...sires, ...treeDogs]}>
            <RenderDog
              id={dog}
              onChange={({ _id = null }) => setDogId(_id)}
              maxDepth={4}
              type="dog"
              options={[...sires, ...dams]}
            />
          </AllSires.Provider>
        </AllDams.Provider>
      </Grid>
    </Grid>
  );
}

async function getDogOrTreeDog({ accessToken, id, isDog }) {
  const options = { lean: true };
  return isDog
    ? await getDogById(id, { accessToken, options })
    : await getTreedogById(id, { accessToken, options });
}

async function updateDogOrTreeDog({ accessToken, id, value, field, isDog }) {
  // Handle removal separately
  if (!value?._id) {
    const unset = { $unset: { [field]: `` } };
    return isDog
      ? await updateDog(accessToken, unset, id)
      : await updateTreedog(accessToken, unset, id);
  }

  let { isDog: type, _id } = value;
  type = type ? "dog" : "treedog";
  const update = { [field]: _id, [`${field}DocType`]: type };
  return isDog
    ? await updateDog(id, update, { accessToken })
    : await updateTreedog(id, update, { accessToken });
}

function RenderDog(props) {
  let { id, depth = 0, maxDepth, options = [], onChange = () => {} } = props;
  const { type, label = "", disabled = false } = props;
  const isDog = type == "dog";
  const [dog, setDog] = useState(false);
  const accessToken = useRecoilValue(accessTokenState);
  const sires = useContext(AllSires);
  const dams = useContext(AllDams);

  // Get own information from the admin API. Yes this is horrible for performance and networking but only admins use this tool so it doesn't matter. And as an added bonus it looks cool when the information cascades down the tree.
  useEffect(() => {
    if (!id || type == null) {
      if (!dog) return;
      return setDog(false);
    }
    getDogOrTreeDog({ accessToken, id, isDog }).then((dog) => setDog(dog));
  }, [id, type]);

  // Using dog.sire as the useState default doesn't work because React only uses the default value the first time the component is renderd. And dog.sire is always going to be undefined during the first render.
  // This way we can use a derived value (dog.sire) as a "default". And when a new sire is picked, the Autocomplete onChange handler sets the new id and it's used in place of the derived value.
  // If we are in dog.dam.sire for example, and the upsteam dog changes, the component is re-rendered anyway so we're back to using the derived value as the default.
  const [sireId, setSireId] = useState(null);
  const [damId, setDamId] = useState(null);
  const sire = sireId || dog?.sire;
  const dam = damId || dog?.dam;

  // Stop infinite recursion :3
  if (depth >= maxDepth) return <></>;
  depth++;

  const handleSireChange = (newSire) => {
    setSireId(newSire?._id);
    const thisDog = { accessToken, id, isDog };
    updateDogOrTreeDog({ ...thisDog, value: newSire, field: "sire" }).then(
      (updated) => {
        setDog(updated);
      }
    );
  };
  const handleDamChange = (newDam) => {
    setDamId(newDam?._id);
    const thisDog = { accessToken, id, isDog };
    updateDogOrTreeDog({ ...thisDog, value: newDam, field: "dam" }).then(
      (updated) => {
        setDog(updated);
      }
    );
  };

  // TODO: use i18n for this and all the other string values in this component.
  const empty = "";

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Stack direction="row" alignItems="center" sx={{ mt: 3 }}>
          <Typography variant="h3">
            {depth === 1 && "Select a dog"}
            {depth > 1 && `Add ${label}`}
          </Typography>
          <IconButton onClick={() => onChange({})}>
            <Tooltip title="Remove">
              <ClearIcon />
            </Tooltip>
          </IconButton>
        </Stack>
      </Grid>
      <Grid item xs={12}>
        <Autocomplete
          key={id}
          options={options}
          sx={{ flexGrow: 1 }}
          getOptionLabel={(option) =>
            `${option.name} (${option.regNumber || "no reg.number"})`
          }
          onChange={(event, value) => onChange(value)}
          size="small"
          disabled={disabled}
          renderInput={(params) => (
            <TextField
              placeholder={empty}
              label={`Select ${label}`}
              {...params}
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h5" sx={{ mt: 2 }}>
          Preview
        </Typography>
      </Grid>
      <Grid item xs={depth > 2 ? 12 : 6}>
        <TextField
          label="Dog's name"
          size="small"
          fullWidth
          value={dog?.name || empty}
          disabled
        ></TextField>
      </Grid>
      <Grid item xs={depth > 2 ? 12 : 6}>
        <TextField
          label="Reg. number"
          size="small"
          fullWidth
          value={dog?.regNumber || empty}
          disabled
        ></TextField>
      </Grid>
      <Grid item xs={12}></Grid>
      <Grid item xs={12}>
        {/* Controls */}
        <Stack
          direction="row"
          flexWrap="nowrap"
          flexGrow={1}
          sx={{ width: "100%" }}
        ></Stack>
        {/* Basic information */}
        <Stack
          direction={depth < 3 ? "row" : "column"}
          flexWrap={false}
          spacing={2}
          sx={{ width: "100%" }}
        ></Stack>
        {/* Parents */}
        <Stack
          direction={depth < 3 ? "row" : "column"}
          alignContent="center"
          alignItems="center"
          gap={2}
          mt={1}
          flexGrow={1}
          sx={{ width: "100%" }}
        >
          <RenderDog
            depth={depth}
            maxDepth={maxDepth}
            id={sire}
            options={sires}
            type={dog?.sireDocType}
            onChange={handleSireChange}
            label={`${label} sire`}
            disabled={!id}
          />
          <RenderDog
            depth={depth}
            maxDepth={maxDepth}
            id={dam}
            options={dams}
            type={dog?.damDocType}
            onChange={handleDamChange}
            label={`${label} dam`}
            disabled={!id}
          />
        </Stack>
      </Grid>
    </Grid>
  );
}
