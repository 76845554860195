import React from "react";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Controller, useFieldArray, useForm } from "react-hook-form";

import { useAuth0 } from "@auth0/auth0-react";

// Recoil
import {
  accessTokenState,
  userState,
} from "recoil/globalStates";
import { adminAllCertsState, adminAllOrgsState } from "recoil/adminStates";
import { constSelector, useRecoilValue } from "recoil";

import dayjs from "dayjs";

// MUI
import {
  Autocomplete,
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Add, Delete } from "@mui/icons-material";

// Local
import { deleteUser } from "api/adminRoutes";
import { citiesFi } from "constants/fiCities";
import { countries } from "constants/countries";
import { ROLES } from "constants/helpers";
import FormHeader from "components/adminDashboardComponents/formHeader";
import JsonPreviewWidget from "components/adminDashboardComponents/jsonPreviewWidget";
import FormButtons from "components/adminDashboardComponents/formButtons";
import UserFormAdminComponent from "components/adminDashboardComponents/userFormAdminComponent";

function UserForm({ action, user, emitHandleSubmit, handleModalClose }) {
  const formType = "user";
  const { isAuthenticated } = useAuth0();
  const currentUser = useRecoilValue(
    isAuthenticated ? userState : constSelector(null)
  );
  const accessToken = useRecoilValue(accessTokenState);

  const [initialUser, setInitialUser] = useState(user);

  const { t, i18n } = useTranslation();
  const language = i18n.language;

  const {
    control,
    watch,
    handleSubmit,
    setValue,
    formState: { errors, isSubmitting, isDirty },
  } = useForm();

  const formdata = watch();

  // Organizations handling
  const {
    append: appendOrgs,
    fields: orgFields,
    remove: removeOrgs,
  } = useFieldArray({ control, name: "organizations" });

  const organizations = formdata.organizations;
  const allOrganizations = useRecoilValue(adminAllOrgsState);
  const orgArray = initialUser?.organizations;

  // Push this into initialUser obj
  useEffect(() => {
    setValue("organizations", orgArray);
  }, [initialUser]);

  // add new object to organizations array in the form state
  const addOrgs = () => {
    appendOrgs({
      organization: "",
      role: "basic",
    });
  };

  // Certificates handling
  const {
    append: appendCerts,
    fields: certFields,
    remove: removeCerts,
  } = useFieldArray({ control, name: "certificates" });

  const certificates = formdata.certificates;
  const allCertificates = useRecoilValue(adminAllCertsState);

  // set the certs array from the initial user since useFieldArray doesnt allow
  // setting a default and useForm defaults dont allow variables as defaults
  useEffect(() => {
    setValue("certificates", initialUser?.certificates || []);
  }, [initialUser]);

  // add new object to certificates array in the form state
  const addCerts = () => {
    appendCerts({
      certificate: "",
      issuingDate: dayjs().toISOString(),
    });
  };

  // Country handling
  const [selectedCountry, setSelectedCountry] = useState(
    initialUser?.country
      ? countries.find((c) => c.code === initialUser.country)
      : null
  );

  const onSubmit = (data) => {
    // change country object to country code before sending
    if (data.country) {
      data.country = data.country.code;
    }

    emitHandleSubmit(accessToken, data, initialUser?._id);
  };

  const handleDelete = () => {
    if (
      confirm(
        `Are you sure you want to delete ${initialUser.name} ? This process can NOT be undone!`
      )
    ) {
      const deleteData = async () => {
        await deleteUser(initialUser._id, { accessToken }).then((res) => {
          if (res.error) {
            setSnackbarState({
              message: res.message,
              severity: "error",
              open: true,
            });
          } else {
            alert(`${res.name} has been removed.`);
            setTimeout((window.location = `/admin/allusers`), 3000);
          }
        });
      };
      deleteData();
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container direction="row" spacing={3} justifyContent="flex-start">
          <Grid item xs={12}>
            <FormHeader
              action={action}
              formType="user"
              data={initialUser}
              handleModalClose={handleModalClose}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="name"
              control={control}
              defaultValue={initialUser?.name || ""}
              rules={{
                required: "Required",
                minLength: 1,
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  required
                  label="First name and surname"
                  error={errors?.name}
                  helperText={errors?.name && errors?.name?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              name="email"
              control={control}
              defaultValue={initialUser?.email || ""}
              rules={{
                required: "Required",
                minLength: 1,
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  required
                  label="Email address"
                  error={errors?.email}
                  helperText={errors?.email && errors?.email?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              name="phone"
              control={control}
              defaultValue={initialUser?.phone || ""}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  label="Phone number"
                  error={errors?.phone}
                  helperText={errors?.phone && errors?.phone?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="address"
              control={control}
              defaultValue={initialUser?.address || ""}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  label="Address"
                  error={errors?.address}
                  helperText={errors?.address && errors?.address?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              name="postalCode"
              control={control}
              defaultValue={initialUser?.postalCode || ""}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  label="Postal code"
                  error={errors?.postalCode}
                  helperText={errors?.postalCode && errors?.postalCode?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              name="city"
              render={({ field: { onChange, ...props } }) => (
                <Autocomplete
                  fullWidth
                  freeSolo
                  label="City"
                  options={citiesFi}
                  getOptionLabel={(option) => option}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="City"
                      error={errors?.city}
                      helperText={errors?.city && errors?.city?.message}
                    />
                  )}
                  onChange={(e, data) => onChange(data)}
                  {...props}
                />
              )}
              onChange={([, data]) => data}
              defaultValue={initialUser?.city || null}
              control={control}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              name="country"
              render={({ field: { onChange, ...props } }) => (
                <Autocomplete
                  fullWidth
                  label="Country"
                  options={countries}
                  isOptionEqualToValue={(option, value) => option.code == value}
                  getOptionLabel={(option) =>
                    `${option["en"]} (${option.code})`
                  }
                  renderOption={(props, option) => (
                    <li {...props} key={option.code}>
                      {`${option["en"]} (${option.code})`}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Country"
                      InputProps={{
                        ...params.InputProps,
                      }}
                      error={errors?.country}
                      helperText={errors?.country && errors?.country?.message}
                    />
                  )}
                  onChange={(e, data) => onChange(data)}
                  {...props}
                />
              )}
              onChange={([, data]) => data}
              defaultValue={selectedCountry || null}
              control={control}
            />
          </Grid>

          <Grid item xs={12}>
            <Divider textAlign="left" sx={{ my: 2 }}>
              <Typography variant="h5">{t("Account")}</Typography>
            </Divider>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel>Role *</InputLabel>
              <Controller
                rules={{ required: "Required" }}
                name="role"
                control={control}
                defaultValue={initialUser?.role || "basic"}
                render={({ field: { ref, name, ...field } }) => (
                  <Select {...field} fullWidth required label="role">
                    <MenuItem value="basic">Basic</MenuItem>
                    <MenuItem value="manager">Manager</MenuItem>
                    {currentUser?.role === "admin" && (
                      <MenuItem value="admin">Admin</MenuItem>
                    )}
                  </Select>
                )}
              />
              <FormHelperText>
                {errors?.role && errors?.role?.message}
              </FormHelperText>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <Divider textAlign="left" sx={{ my: 2 }}>
              <Typography variant="h5">{t("Organizations")}</Typography>
            </Divider>
          </Grid>

          <Grid item xs={12}>
            {orgFields.length > 0 ? (
              orgFields.map((field, i) => (
                <React.Fragment key={field.id}>
                  <Stack
                    direction={{ xs: "column", md: "row" }}
                    alignItems={"flex-start"}
                    spacing={2}
                    p={2}
                  >
                    <Controller
                      name={`organizations[${i}].organization`}
                      control={control}
                      defaultValue={organizations[i]?.organization || ""}
                      render={({ field: { onChange, ...props } }) => (
                        <Autocomplete
                          fullWidth
                          freeSolo
                          label={t("Organization")}
                          options={allOrganizations}
                          isOptionEqualToValue={(option, value) =>
                            option._id == value
                          }
                          getOptionLabel={(option) =>
                            option.name ? option.name : ""
                          }
                          onChange={(e, data) => onChange(data)}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              fullWidth
                              label={t("Organization name")}
                              helperText={t(
                                "Enter name or choose from dropdown"
                              )}
                              InputProps={{
                                ...params.InputProps,
                              }}
                            />
                          )}
                          {...props}
                        />
                      )}
                    />

                    <Controller
                      name={`organizations[${i}].role`}
                      control={control}
                      defaultValue={organizations[i]?.role || "basic"}
                      render={({ field: { ref, name, ...field } }) => (
                        <FormControl fullWidth>
                          <InputLabel>{t("Role")}</InputLabel>
                          <Select {...field} fullWidth label={t("Role")}>
                           {Object.keys(ROLES)
                              .map((role) => (
                                <MenuItem
                                  key={role}
                                  value={role}
                                >
                                  {role}
                                </MenuItem>
                              ))}
                          </Select>
                        </FormControl>
                      )}
                    />

                                <FormGroup>
                      <Grid container alignItems="center" justifyContent="space-between">
                        <Grid item>
                          <InputLabel>Include in public list of breeders for this organization?</InputLabel>
                          <FormControlLabel
                            control={
                              <Controller
                                name={`organizations[${i}].displayBreeder`}
                                control={control}
                                defaultValue={initialUser?.organizations[i]?.displayBreeder || false}
                                render={({ field }) => (
                                  <Switch
                                    {...field}
                                    name={`organizations[${i}].displayBreeder`}
                                    checked={field.value}
                                  />
                                )}
                              />
                            }
                            label={formdata.permissions?.name ? "Yes" : "No"}
                          />
                        </Grid>
                        <Grid item>
                          <IconButton
                            onClick={() => {
                              if (confirm(t("Remove this organization?"))) {
                                removeOrgs(i);
                              }
                            }}
                          >
                            <Delete />
                          </IconButton>
                        </Grid>
                      </Grid>
                    </FormGroup>
                  </Stack>
                </React.Fragment>
              ))
            ) : (
              <Typography variant="body">{t("No organizations")}.</Typography>
            )}
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="outlined"
              startIcon={<Add />}
              onClick={addOrgs}
              sx={{ float: "right", my: 2 }}
            >
              Add organization
            </Button>
          </Grid>

          <Grid item xs={12}>
            <Divider textAlign="left" sx={{ my: 2 }}>
              <Typography variant="h5">Permissions</Typography>
            </Divider>
            <Typography variant="body">
              {
                "User's given permission to show specific info in the public database"
              }
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormGroup>
              <InputLabel>Name permission</InputLabel>
              <FormControlLabel
                control={
                  <Controller
                    name="permissions.name"
                    control={control}
                    defaultValue={initialUser?.permissions?.name || false}
                    render={({ field }) => (
                      <Switch
                        {...field}
                        name="permissions.name"
                        checked={field.value}
                      />
                    )}
                  />
                }
                label={formdata.permissions?.name ? "Yes" : "No"}
              />
            </FormGroup>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormGroup>
              <InputLabel>City permission</InputLabel>
              <FormControlLabel
                control={
                  <Controller
                    name="permissions.city"
                    control={control}
                    defaultValue={initialUser?.permissions?.city || false}
                    render={({ field }) => (
                      <Switch
                        {...field}
                        name="permissions.city"
                        checked={field.value}
                      />
                    )}
                  />
                }
                label={formdata.permissions?.city ? "Yes" : "No"}
              />
            </FormGroup>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormGroup>
              <InputLabel>Country permission</InputLabel>
              <FormControlLabel
                control={
                  <Controller
                    name="permissions.country"
                    control={control}
                    defaultValue={initialUser?.permissions?.country || false}
                    render={({ field }) => (
                      <Switch
                        {...field}
                        name="permissions.country"
                        checked={field.value}
                      />
                    )}
                  />
                }
                label={formdata.permissions?.country ? "Yes" : "No"}
              />
            </FormGroup>
          </Grid>
          <Grid item xs={12}>
            <Divider textAlign="left" sx={{ my: 2 }}>
              <Typography variant="h5">Breeder</Typography>
            </Divider>
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              name="breedingPrefix"
              control={control}
              defaultValue={initialUser?.breedingPrefix || ""}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  label="Breeder prefix or kennel name"
                  error={errors?.breedingPrefix}
                  helperText={
                    errors?.breedingPrefix && errors?.breedingPrefix?.message
                  }
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <Controller
              name="breederId"
              control={control}
              defaultValue={initialUser?.breederId || ""}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  label="Breeder ID"
                  disabled
                  helperText="Automatically generated, public field"
                />
              )}
            />
          </Grid>
          
          <Grid item xs={12}>
            <Divider textAlign="left" sx={{ my: 2 }}>
              <Typography variant="h5">Certificates</Typography>
            </Divider>
          </Grid>
          <Grid item xs={12}>
            {certFields.length > 0 ? (
              certFields.map((field, i) => (
                <React.Fragment key={field.id}>
                  <Stack
                    direction={{ xs: "column", md: "row" }}
                    alignItems={"flex-start"}
                    spacing={2}
                    p={2}
                  >
                    <Typography variant="body">{i + 1}.</Typography>

                    <Controller
                      name={`certificates[${i}].certificate`}
                      control={control}
                      defaultValue={certificates[i]?.certificate || ""}
                      render={({ field: { onChange, ...props } }) => (
                        <Autocomplete
                          fullWidth
                          freeSolo
                          label="Certificate"
                          options={allCertificates}
                          isOptionEqualToValue={(option, value) =>
                            option._id == value
                          }
                          getOptionLabel={(option) =>
                            option.name ? option.name : ""
                          }
                          onChange={(e, data) => onChange(data)}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              fullWidth
                              label="Certificate"
                              InputProps={{
                                ...params.InputProps,
                              }}
                            />
                          )}
                          {...props}
                        />
                      )}
                    />

                    <Controller
                      name={`certificates[${i}].issuingDate`}
                      control={control}
                      defaultValue={certificates[i]?.issuingDate || null}
                      render={({ field: { ref, name, ...field } }) => (
                        <DatePicker
                          {...field}
                          inputRef={ref}
                          label="Issuing date"
                          onChange={(e) =>
                            field.onChange(dayjs(e).toISOString())
                          }
                          renderInput={(params) => (
                            <TextField {...params} fullWidth name={name} />
                          )}
                        />
                      )}
                    />

                    <IconButton
                      onClick={() => {
                        if (confirm("Remove this certificate?")) {
                          removeCerts(i);
                        }
                      }}
                    >
                      <Delete />
                    </IconButton>
                  </Stack>
                </React.Fragment>
              ))
            ) : (
              <Typography variant="body">No certificates</Typography>
            )}
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="outlined"
              startIcon={<Add />}
              onClick={addCerts}
              sx={{ float: "right", my: 2 }}
            >
              Add certificate
            </Button>
          </Grid>

          <Grid item xs={12}>
            <JsonPreviewWidget formdata={formdata} />
          </Grid>

          <Grid item xs={12}>
            <FormButtons
              action={action}
              handleDelete={handleDelete}
              isSubmitting={isSubmitting}
              isDirty={isDirty}
              errors={errors}
            />
          </Grid>
        </Grid>
      </form>
      {currentUser?.role === "admin" && (
        <UserFormAdminComponent
          initialUser={initialUser}
          accessToken={accessToken}
          emitHandleSubmit={emitHandleSubmit}
        />
      )}
    </>
  );
}
export default UserForm;
