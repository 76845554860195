import React from "react";
import i18next from "i18next";

//MUI
import { Tooltip, Grow, Box, Typography } from "@mui/material";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";

export default function EmailVerifiedBadge() {
  return (
    <Tooltip
      TransitionComponent={Grow}
      TransitionProps={{ timeout: 600 }}
      title={
        <Box sx={{ p: 1 }}>
          <Typography variant="body" sx={{ fontWeight: 300 }}>
            {i18next.t("emailVerified")}
          </Typography>
        </Box>
      }
    >
      <CheckCircleIcon
        fontSize="small"
        color="primary"
        sx={{ verticalAlign: "top" }}
      />
    </Tooltip>
  );
}
