import React from "react";

import CertificateForm from "../forms/certificateForm";

import { selectedDocByIdState } from "recoil/adminStates";
import { useRecoilValue } from "recoil";
import { AdminNotFound } from "components/adminDashboardComponents/adminNotFound";

function AdminCertificateView() {
  const cert = useRecoilValue(selectedDocByIdState("certificate"));

  return cert ? (
    <CertificateForm action="edit" cert={cert} />
  ) : (
    <AdminNotFound docType="Certificate" />
  );
}

export default AdminCertificateView;
