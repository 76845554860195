import React from "react";
import { useTranslation } from "react-i18next";

import dayjs from "dayjs";

// MUI
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Card,
  CardActions,
  CardContent,
  Chip,
  Divider,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { ExpandMore } from "@mui/icons-material";

// Components
import { getCountryByCode, getKruValueLabel } from "constants/helpers";


export default function PendingDogsList({ pendingDogs }) {
  const theme = useTheme();
  const lessThanSmall = useMediaQuery(theme.breakpoints.down("md"));

  const { t, i18n } = useTranslation();
  const language = i18n.language;
  return (
    <Accordion defaultExpanded>
      <AccordionSummary expandIcon={<ExpandMore />}>
        <Typography variant="h3">
          {t("dogRegistrations")}
          <Chip
            color="primary"
            size="small"
            label={pendingDogs.length}
            sx={{ ml: 2 }}
          />
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        {pendingDogs.length > 0 ? (
          <>
            {lessThanSmall ? (
              pendingDogs.map((dog, i) => {
                return (
                  <Card key={i} variant="outlined" sx={{ mb: 2 }}>
                    <CardContent>
                      <Grid container>
                        <Grid item xs={12}>
                          <Typography variant="h5">{dog.name}</Typography>

                          <Typography variant="caption">
                            {dog.microchipNumber}
                          </Typography>
                          <Divider sx={{ my: 2 }} />
                        </Grid>
                        <Grid item xs={6}>
                          <Typography variant="caption">{t("dob")}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography variant="body">
                            {dayjs(dog.dateOfBirth).format("MM.DD.YYYY")}
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography variant="caption">{t("sex")}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography variant="body">{t(dog.sex)}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography variant="caption">
                            {t("countryOfOrigin")}
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography variant="body">
                            {getCountryByCode(dog.countryOfOrigin, language)}
                          </Typography>
                        </Grid>
                      </Grid>
                    </CardContent>
                    <Divider />
                    <CardActions sx={{ justifyContent: "center" }}>
                      <Typography variant="body">
                        Status: {t(dog.registrationStatus)}
                      </Typography>
                    </CardActions>
                  </Card>
                );
              })
            ) : (
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>{t("microchipNumber")}</TableCell>
                      <TableCell>{t("name")}</TableCell>
                      <TableCell>{t("dob")}</TableCell>
                      <TableCell>{t("sex")}</TableCell>
                      <TableCell>{t("countryOfOrigin")}</TableCell>
                      <TableCell>{t("color")}</TableCell>
                      <TableCell>{t("coat")}</TableCell>
                      <TableCell>{t("status")}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {pendingDogs.map((dog, i) => {
                      return (
                        <TableRow key={i}>
                          <TableCell>{dog.microchipNumber}</TableCell>
                          <TableCell>{dog.name}</TableCell>
                          <TableCell>
                            {dayjs(dog.dateOfBirth).format("MM.DD.YYYY")}
                          </TableCell>
                          <TableCell>{t(dog.sex)}</TableCell>
                          <TableCell>
                            {getCountryByCode(dog.countryOfOrigin, language)}
                          </TableCell>
                          <TableCell>
                            {dog.color.map((v, i) => {
                              return (
                                <div key={i}>
                                  {getKruValueLabel(v, language)}
                                  <br />
                                </div>
                              );
                            }) || "-"}
                          </TableCell>
                          <TableCell>
                            {dog.coat.map((v, i) => {
                              return (
                                <div key={i}>
                                  {getKruValueLabel(v, language)}
                                  <br />
                                </div>
                              );
                            }) || "-"}
                          </TableCell>
                          <TableCell>
                            {t(dog.registrationStatus) || "-"}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </>
        ) : (
          <Typography variant="body">{t("noDogRegistrations")}</Typography>
        )}
      </AccordionDetails>
    </Accordion>
  );
}
