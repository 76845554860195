export const muiFi = {
  columnHeaderSortIconLabel: "Järjestä",
  noRowsLabel: "Ei tuloksia.",
  noResultsOverlayLabel: "Ei tuloksia.",
  errorOverlayDefaultLabel: "Tapahtui virhe.",

  // Density selector toolbar button text
  toolbarDensity: "Rivikorkeus",
  toolbarDensityLabel: "Rivikorkeus",
  toolbarDensityCompact: "Kompakti",
  toolbarDensityStandard: "Perus",
  toolbarDensityComfortable: "Väljä",

  // Columns selector toolbar button text
  toolbarColumns: "Sarakkeet",
  toolbarColumnsLabel: "Valitse sarakkeet",

  // Filters toolbar button text
  toolbarFilters: "Suodata",
  toolbarFiltersLabel: "Näytä suodattimet",
  toolbarFiltersTooltipHide: "Piilota suodattimet",
  toolbarFiltersTooltipShow: "Näytä suodattimet",
  toolbarFiltersTooltipActive: (count) =>
    count !== 1
      ? `${count} aktiivista suodatinta`
      : `${count} aktiivinen suodatin`,

  // Quick filter toolbar field
  toolbarQuickFilterPlaceholder: "Hae...",
  toolbarQuickFilterLabel: "Haku",
  toolbarQuickFilterDeleteIconLabel: "Tyhjennä",

  // Export selector toolbar button text
  toolbarExport: "Vie",
  toolbarExportLabel: "Vie",
  toolbarExportCSV: "Lataa CSV-tiedostona",
  toolbarExportPrint: "Tulosta",
  toolbarExportExcel: "Lataa Excel-tiedostona",

  // Columns panel text
  columnsPanelTextFieldLabel: "Etsi saraketta",
  columnsPanelTextFieldPlaceholder: "Sarakkeen nimi",
  columnsPanelDragIconLabel: "Järjestä sarakkeet",
  columnsPanelShowAllButton: "Näytä kaikki",
  columnsPanelHideAllButton: "Piilota kaikki",

  // Filter panel text
  filterPanelAddFilter: "Lisää suodatin",
  filterPanelDeleteIconLabel: "Poista",
  filterPanelLinkOperator: "Looginen operaattori",
  filterPanelOperators: "Operaattorit",
  filterPanelOperatorAnd: "JA",
  filterPanelOperatorOr: "TAI",
  filterPanelColumns: "Sarakkeet",
  filterPanelInputLabel: "Arvo",
  filterPanelInputPlaceholder: "Suodatettava arvo",

  // Filter operators text
  filterOperatorContains: "sisältää",
  filterOperatorEquals: "on yhtä kuin",
  filterOperatorStartsWith: "alkaa",
  filterOperatorEndsWith: "loppuu",
  filterOperatorIs: "on",
  filterOperatorNot: "ei ole",
  filterOperatorAfter: "on jälkeen",
  filterOperatorOnOrAfter: "on tai on jälkeen",
  filterOperatorBefore: "on ennen",
  filterOperatorOnOrBefore: "on tai on ennen",
  filterOperatorIsEmpty: "on tyhjä",
  filterOperatorIsNotEmpty: "ei ole tyhjä",
  filterOperatorIsAnyOf: "mikä tahansa",

  // Filter values text
  filterValueAny: "mikä tahansa",
  filterValueTrue: "tosi",
  filterValueFalse: "epätosi",

  // Column menu text
  columnMenuLabel: "Valikko",
  columnMenuShowColumns: "Näytä sarakkeet",
  columnMenuFilter: "Suodata",
  columnMenuHideColumn: "Piilota tämä sarake",
  columnMenuUnsort: "Poista järjestys",
  columnMenuSortAsc: "Järjestä nousevasti",
  columnMenuSortDesc: "Järjestä laskevasti",

  // Total row amount footer text
  footerTotalRows: "Rivejä yhteensä:",

  // Total visible row amount footer text
  footerTotalVisibleRows: (visibleCount, totalCount) =>
    `${visibleCount.toLocaleString()} (${totalCount.toLocaleString()})`,

  // Boolean values
  booleanCellTrueLabel: "kyllä",
  booleanCellFalseLabel: "ei",
};
