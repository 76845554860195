/*
 * Helpers and constants
 */

export const domain = process.env.REACT_APP_FRONTEND;

const privateApi = process.env.REACT_APP_PRIVATE_API;
const privateApiVersion = process.env.REACT_APP_PRIVATE_API_VERSION;

const publicApi = process.env.REACT_APP_PUBLIC_API;
const publicApiVersion = process.env.REACT_APP_PUBLIC_API_VERSION;

function cleanArray(s) {
  if (s?.includes(undefined)) throw Error("API url contains undefined");
  return s.join("");
}

function cleanUrl(base, ...parts) {
  return `${base}/${parts
    .join("/")
    .replaceAll("\\", "/")
    .replaceAll("//", "/")
    .replace(/\/$/, "")}`;
}

export function publicUrl(strings, ...expressions) {
  return cleanUrl(
    publicApi,
    publicApiVersion,
    cleanArray(strings),
    cleanArray(expressions)
  );
}
export function privateUrl(strings, ...expressions) {
  return cleanUrl(
    privateApi,
    privateApiVersion,
    cleanArray(strings),
    cleanArray(expressions)
  );
}
export function adminUrl(strings, ...expressions) {
  return cleanUrl(
    privateApi, "admin",
    cleanArray(strings),
    cleanArray(expressions)
  );
}
export function specialUrl(strings, ...expressions) {
  return cleanUrl(
    privateApi, privateApiVersion, "special",
    cleanArray(strings),
    cleanArray(expressions)
  );
}

export function accessHeaders(accessToken, isJSON) {
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${accessToken}`,
  };
  if (!isJSON) delete headers[`Content-Type`];
  return { headers };
}

export function jsonBody(body) {
  return { body: JSON.stringify(body) };
}

// Constants for reload attempts
const MAX_RELOAD_ATTEMPTS = 2;
const RELOAD_ATTEMPTS_KEY = 'reloadAttempts';

// Helper functions for reload attempts
function getReloadAttempts() {
  return parseInt(localStorage.getItem(RELOAD_ATTEMPTS_KEY), 10) || 0;
}

function incrementReloadAttempts() {
  const attempts = getReloadAttempts() + 1;
  localStorage.setItem(RELOAD_ATTEMPTS_KEY, attempts);
}

function resetReloadAttempts() {
  localStorage.removeItem(RELOAD_ATTEMPTS_KEY);
}

// Function to handle requests
export function request({
  url,
  method = "POST",
  accessToken,
  body,
  raw = false,
}) {
  const isFormData = body instanceof FormData;
  let options = {
    method,
    ...accessHeaders(accessToken, !isFormData),
  };
  if (body) options.body = isFormData ? body : JSON.stringify({ ...body });
  if (raw) return fetch(url, options);

  return fetch(url, options)
    .then((response) => {
      if (!response.ok) {
        return response.json().then((error) => {
          if ((response.status === 401 && error.name === "InvalidTokenError") ||
              (response.status === 500 && error.message === "\"exp\" claim timestamp check failed")) {
            const attempts = getReloadAttempts();
            if (attempts < MAX_RELOAD_ATTEMPTS) {
              incrementReloadAttempts();
              window.location.reload();
            } else {
              resetReloadAttempts();
              window.location.href = `/login`;
            }
            return null;
          }
          throw error;
        });
      }
      resetReloadAttempts(); // Reset the counter on successful request
      return response.json();
    })
    .catch((error) => {
      // Handle other errors
      console.error("Request failed:", error);
      throw error;
    });
}