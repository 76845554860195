// React
import React from "react";
import { useTranslation } from "react-i18next";

import dayjs from "dayjs";

// Recoil
import { useRecoilValue } from "recoil";
import { kruValuesState,
         resultProvidersState,
         useAuthenticatedUser
        } from "recoil/globalStates";

// MUI
import { List, ListItem, ListItemText, Typography } from "@mui/material";

// Local
import { litterRegRules, dogRegRules } from "./rules";
import { countries } from "./countries";
import { Checkmark, Errormark } from "elements/BreederBadges";
import { getOrganizationById } from "api/publicRoutes";

export const ROLES = {
  basic: { power: 1 },
  breeder: { power: 5 },
  manager: { power: 10 },
  admin: { power: 9999 },
};

// Badges
// TODO: this should be in components
export const getBadge = (breeder) => {
  if (breeder) {
    if (breeder?.certificates?.length > 0) {
      return <Checkmark />;
    } else {
      return <Errormark />;
    }
  } else {
    return " ";
  }
};

// takes in array of dog objects and a boolean, sorts then according to the given
// boolean and return the sorted array
export function sortDogsByCreationDate(dogs, sortingAsc) {
  const sortedOwnedDogs = [...dogs];

  sortedOwnedDogs.sort(function compare(a, b) {
    var dateA = dayjs(a.createdAt);
    var dateB = dayjs(b.createdAt);
    return sortingAsc ? dateB - dateA : dateA - dateB;
  });
  return sortedOwnedDogs;
}

// Get ResultProvider by ID
export function getResultProvider(id) {
  const resultProviders = useRecoilValue(resultProvidersState);
  return resultProviders.find((provider) => provider._id === id);
}

// Get KruValue's label in selected language by id
export function getKruValueLabel(id, lang) {
  const kruValues = useRecoilValue(kruValuesState);
  if (! id) {
    return "";
  }

  const found = kruValues.find((value) => value._id === id);
  return found && getNameByLang (found, lang);
}

// Get KruValue's labels for an array in selected language by id
// Useful for arrays of KruValues, e.g. coat colors and coat types
// NOTE: kruValues needs to be passed as param since calling it in this
// function is going to cause rendering error
export function getKruValueLabels(array, lang, kruValues) {
  if (typeof array === "string") {
    array = Array(array);
  }
  if (array !== "") {
    let kruValueArray = array.map((val) => {
      const found = kruValues.find((value) => value._id === val);
      return found && found[lang].name;
    });
    return kruValueArray.join(", ");
  } else {
    return "";
  }
}

// Check if parent is not too young
export function checkAge(parent) {
  const dateParent = dayjs(parent.dateOfBirth);
  const dateToday = dayjs();
  const parentAge = dateToday.diff(dateParent, "month");

  if (parentAge < litterRegRules.parentAgeMonthsMin) {
    return { ok: false, age: parentAge };
  } else {
    return { ok: true, age: null };
  }
}

// Check if dog is not too young for registration
export function checkDogAge(dob) {
  const dateDog = dayjs(dob);
  const dateToday = dayjs();
  const dogAge = dateToday.diff(dateDog, "month");

  if (dogAge < dogRegRules.ageMonthsMin) {
    return { ok: false, age: dogAge };
  } else {
    return { ok: true, age: null };
  }
}

// Count the number of each gender to show in form UI
export function countFemales(puppies) {
  if (puppies) {
    const count = puppies.filter((pup) => pup.sex == "female");

    return count?.length;
  }
}
// Count the number of each gender to show in form UI
export function countMales(puppies) {
  if (puppies) {
    const count = puppies.filter((pup) => pup.sex == "male");

    return count?.length;
  }
}

export function getVerification(verifications, operation) {
  const found = verifications.find((e) => e.operation === operation);
  if (found) {
    return found.verified;
  }
}

export function getCountryByCode(code, lang) {
  if (code) {
    const country = code.toUpperCase();
    const found = countries.find((c) => c.code === country);
    if (!found) {
      return code;
    } else {
      return found[lang];
    }
  }
}

export async function getOrganizationNamesById(orgArray) {
  let promiseArray = [];
  orgArray.forEach((org) => promiseArray.push(getOrganizationById(org)));
  Promise.all(promiseArray).then((vals) => {
    const mappedValues = vals.map((v) => v.name);
    return mappedValues;
  });
}

export function getUserOrg(user) {
  if (user.organizations?.length == 1) {
    return user.organizations[0].organization;
  }
  return "";
}

// Return this organization+etc for this object,
// or undef if it is not in this org.
export function getOrgAndMore(obj, org) {

  if (org === null || org === undefined || obj.organizations == null) {
    return null;
  }

  return obj.organizations.find(o => o._id === org._id || o.organization?._id === org._id);
  // if none matched, we'll return undefined
}

export function compareRole(role, comparisonRole) {
  if (! role) { return false; }
  
  return ROLES[role].power >= ROLES[comparisonRole].power;
}
  
// Is the user a site-level manager or admin in this org?
export function getUserManagerPlusOrg(user, org) {
  const orgAndRole = getOrgAndMore(user, org);
  if (orgAndRole === null || orgAndRole === undefined) {
    return null;
  }
  return compareRole(orgAndRole.role, "manager");
}

// How much does the user need to pay to register a dog/litter?
export function getPaymentAmount(user, type, org) {
  // is user site admin? -> 0
  if (compareRole(user.role, "admin")) {
    return 0;
  }

  // If there's mo organization then you can't register a dog.
  if (org == null) {
    return null;
  }

  // is user in the organization specified? If not, error
  const orgAndRole = getOrgAndMore(user, org);
  if (orgAndRole == null) {
    throw new Error(
      "Must be a breeder in " + org.name +
        " to register a " + type +
        " with them."
    );
  }
  const userOrg = orgAndRole.organization;
  const userRole = orgAndRole.role;

  // Return amount
  return userOrg.pricing[type][userRole];
}

// Should we offer to let this user register a dog?
export const userCanRegister = ( org ) => {
  const user = useAuthenticatedUser();

  if (user === null) {
    return false;
  }

  // Site admin?
  if (compareRole(user.role, "admin")) {
    return true;
  }

  if (org === undefined) {
    if (compareRole(user.role, "manager")) { return true; }
  }

  // If the user is not in this org it will return null,
  // so this works as a boolean without an explicit comparison.
  const userAndRole = getOrgAndMore(user, org);
  if (compareRole(userAndRole?.role, "breeder")) {
    return true;
  }
  
  // Non-breeder member, or
  // rando who wandered in off the street and created an account?
  return false;
};

// Is this a registered, pending, or tree dog?
export function getDogType (dog) {
  if (dog.__t == "Registrable") {
    if (dog.pendingRegistration) { return "pendingdog"; }
    return "dog";
  }
  return "treedog";
}

// Extract specified organization+reg object from list of organizations
export function getOrgWithReg(organizations, org) {
  if (! organizations) {
    return null;
  }
  let orgWithReg = organizations.find(o => o.organization === org._id);
  if (! orgWithReg) {
    orgWithReg = organizations.find((o) => o.organization._id === org._id);
  }
  return orgWithReg;
}

export function getLinkDisplay (dog, org) {
  if (! org || ! dog || ! dog.organizations) {
    return null;
  }

  // We don't know if the organizations array is poplated or not,
  // so check both ID and object.
 let regNum = dog.organizations.find
  (o => o._id === org._id || o.organization?._id === org._id || o.organization === org._id)?.regNumber;

  return regNum ?
   { link: regNum, display: regNum, prefix: org.prefix, org: org._id || org } :
   { link: dog._id, display: "", prefix: org.prefix, org: org._id || org};
}

  // Try to get kruvalue name by lang, but fallback to en
export function getNameByLang (item, language) {
  if (!item) {
    throw new Error("getNameByLang: item is null");
  }
  return item[language]?.name || item.en.name;
};

export const createHandleInputChange = (setFormData) => {
  return (input, field) => {
    let newValue;
    const { name, type, value, checked, id } = input.target;

    setFormData((prevFormData) => {

      switch (type) {
        case 'checkbox':
          let oldArray = prevFormData[name];
          if (checked) {
            newValue = oldArray.includes(id) ? oldArray : oldArray.concat(id);
          } else {
            newValue = oldArray.filter((item) => item !== id);
          }
          break;
        case 'radio':
          newValue = checked ? value : null;
          break;
        default:
          newValue = value;
      }
    
      // Could make this more general by searching for just "["
      if (name.startsWith('puppies[')) {
        const match = name.match(/^puppies\[(\d+)\]\.(.+)$/);
        if (!match) {
          throw new Error(`Invalid puppy field name: ${name}`);
        }
        const puppyIndex = parseInt(match[1], 10); // Extract and parse the index
        const puppyField = match[2]; // Extract the field name
        const updatedPuppies = prevFormData.puppies.map((puppy, i) => {
          if (i === puppyIndex) {
            return {
              ...puppy,
              [puppyField]: newValue,
            };
          }
          return puppy;
        });

        return {
          ...prevFormData,
          puppies: updatedPuppies,
        };
      }

      return {
        ...prevFormData,
        [name]: newValue,
      };
    });

    // Call the field's onChange method to update the form state
    if (field && typeof field.onChange === 'function') {
      field.onChange(input);
    }
  };
};

//
// Breed description methods
//

export function getBreeds (kruValues, language) {
  return kruValues
    .filter((i) => i.category === "ancestry" && i._id.startsWith("breeds"))
    .sort((a, b) => getNameByLang(a, language).localeCompare(getNameByLang(b, language)));
}

export function getMixes(kruValues, language) {
  return kruValues
  .filter((i) => i.category === "ancestry" && i._id.startsWith("mixes"))
    .sort((a, b) => getNameByLang(a, language).localeCompare(getNameByLang(b, language)));
}

export function getTypes(kruValues, language) {
  return kruValues
  .filter((i) => i.category === "ancestry" && i._id.startsWith("types"))
    .sort((a, b) => getNameByLang(a, language).localeCompare(getNameByLang(b, language)));
}

export function getRegistries(kruValues, language) {
    return kruValues
      .filter((i) => i.category === "registries")
      .sort((a, b) => getNameByLang(a, language).localeCompare(getNameByLang(b, language)));
}
  
export function displayDeclaredTypes (dog, kruValues, language) {
  const { t, i18n } = useTranslation();
  const breeds = kruValues.filter((i) => i.category === "ancestry" && i._id.startsWith("breeds"));
  const ancestries = kruValues.filter((i) => i.category === "ancestry");

  if (dog.declaredTypes?.length > 1) {
    return (dog.declaredTypes.map((type) => (
      getNameByLang(ancestries.find((a) => a._id === type), language)))
      .join(" / ") + t(" type mix"));
  }

  if (dog.declaredTypes?.length == 1) {
    return getNameByLang(ancestries.find((a) => a._id === dog.declaredTypes[0]), language);
  }

  if (dog.regOther?.length > 0) {
    let deduppedReg = dog.regOther.filter((v, i, a) => a.findIndex(t => (t.regBreed === v.regBreed)) === i);
    // Filter out nulls
    deduppedReg = deduppedReg.filter((reg) => reg.regBreed);
    if (deduppedReg.length == 0) {
      return "";
    }
    return (deduppedReg.map((reg) => (
      getNameByLang(breeds.find((b) => b._id === reg.regBreed), language)))
      .join(" / "));
  }

  /* HERE: Implement saying something based on ancestry. WAITING on pedigree/genetic testing. */

  return "";
}

//
// Ancestry methods
//

const displayAncestry = (data) => {
let currentAncestry = {...data};

if (!currentAncestry || !currentAncestry.verification || currentAncestry.verification === "unverified") {
  return <p>{t("No ancestry defined.")}</p>;
}

// remove null breeds
currentAncestry.breeds = currentAncestry.breeds.filter((entry) => entry.breed);

return (
  <div>
    <List disablePadding>
      <ListItem>
        <>
        {currentAncestry.breeds.map((breed, index) => (
        <ListItemText key={`ancestrybreed.${index}`}
          primary={getNameByLang(breeds.find(b => b._id === breed.breed), language)}
          secondary={`${breed.percentage}%`}
        /> ))}
        </>
        </ListItem>
    </List>

    <Typography variant="caption" color="error">
      {ancestryError}
    </Typography>
  </div>
);
};

export const updateAncestry = (verification, data, index, formMethods) => {
  const { setValue, watch } = formMethods;
  const formdata = watch();

  let newAncestry = null;

  switch (verification) {
    case "extReg":
      newAncestry = updateExtRegAncestry(data, index, formMethods);
      break;
    case "pedigree":
      setPedigreeAncestry(data);
      break;
    case "geneticTest":
      setGeneticTestAncestry(data);
      break;
    default:
      setSnackbarState({
        message: t("Ancestry verification " + verification + " not recognized."),
        severity: "error",
        open: true,
      });
      break;
  }

  if (formdata.selectedAncestryVerification === verification) {
    setValue("ancestryError", validateAncestry(newAncestry));
    setValue("ancestry", newAncestry);
  }
};

// extRegAncestry: { breeds: [{ breed: String, percentage: Number }] }
const updateExtRegAncestry = (data, index, formMethods) => {
  const { setValue, watch } = formMethods;
  const formdata = watch();
  let newAncestry = {...formdata.extRegAncestry};
  if (data?.breed) {
    newAncestry.breeds[index] = {
      breed: data.breed._id,
      percentage: data.percentage,
    };
  } else {
    newAncestry.breeds.splice(index, 1);
  }
  setValue("extRegAncestry", newAncestry);
  return newAncestry;
};

export const regOtherToAncestry = (data) => {
  const extRegBreeds = data.map((reg) => reg.regBreed);
  const uniqueBreeds = [...new Set(extRegBreeds)];
  return {
    verification: "extReg",
    breeds: uniqueBreeds.map((b) => {
      return {
        breed: b,
        percentage: 100,
      };
    })
  };
};

const validateAncestry = (ancestry) => {
  // If ancestry has no breeds, return : "Ancestry must have at least one breed."
  if (!ancestry || !ancestry.breeds || ancestry.breeds.length === 0) {
    return t("Ancestry must have at least one breed.");
  }

  // remove null breeds
  let ancestryBreeds = ancestry.breeds.filter((entry) => entry.breed);
  if (ancestryBreeds.length === 0) {
    return t("Ancestry must have at least one breed.");
  }

  // if the same breed is entered twice, then compare breed.percentage.
  // If the percentages are the same, filter out the duplicate breed.
  // If the percentages are different, return "Duplicate breed with different percentages."
  let ancestryBreedIds = ancestryBreeds.map((entry) => entry.breed);
  let uniqueBreedIds = [...new Set(ancestryBreedIds)];

  if (uniqueBreedIds.length < ancestryBreedIds.length) {
    let duplicateBreedIds = ancestryBreedIds.filter((breed, index) => ancestryBreedIds.indexOf(breed) !== index);

    // Extract the items from ancestryBreeds that match each breed._id in duplicateBreedIds
    duplicateBreedIds.forEach(duplicateBreedId => {
      let duplicateBreeds = ancestryBreeds.filter((breed) => breed.breed._id === duplicateBreedId);
      let breedPercentages = duplicateBreeds.map((b) => b.percentage);
      if (new Set(breedPercentages).size > 1) {
        return t("Ancestry contains breed with different percentages: " + duplicateBreedId);
      }
    });

    // Filter ancestryBreeds to have only one instance of each breed._id
    // (we trust the percentages are the same per breed at this point
    // so it doesn't matter which we take)
    ancestryBreeds = ancestryBreeds.filter((entry, index, self) => {
      return self.findIndex((b) => b.breed === entry.breed) === index;
    });
  }

  // If any breed percentage is < 0 or > 100, return "Breed percentage must be between 0 and 100."
  let invalidPercentages = ancestryBreeds.filter((breed) => breed.percentage < 0 || breed.percentage > 100);
  if (invalidPercentages.length > 0) {
    return t("Breed percentage must be between 0 and 100: " +
      invalidPercentages.map((breed) => `${breed.breed} (${breed.percentage}%)`).join(", "));
  }

  // If percentages all total to more than 100, return "Ancestry breeds cannot sum to more than 100."
  let sumPercentages = ancestryBreeds.reduce((sum, breed) => sum + breed.percentage, 0);
  if (sumPercentages > 100) {
    if (ancestry.verification === "extReg") {
      return t("Cannot verify ancestry if dog is registered as different breeds: " + 
        ancestryBreeds.map((breed) =>
          getNameByLang(breeds.find(b => b._id === breed.breed), language)
        ).join(", ")
      );
    }
    return t("Ancestry percentages cannot sum to more than 100%. They sum to " + sumPercentages + "%.");
  }

  // No errors!
  return null;
};

