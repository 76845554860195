import React from 'react';
import { useRecoilValue } from 'recoil';

// MUI
import Typography from "@mui/material/Typography";

import { organizationBreedersState } from "recoil/publicStates";
import { BreederCard } from "elements/breederCard";

export default function OrganizationBreeders({ org }) {
  const breeders = useRecoilValue(organizationBreedersState(org));

  if (breeders.length === 0) {
    return <Typography variant="body">No breeders have been added yet.</Typography>;
  } 

  return (
    <>
      {breeders.map((breeder) => (
        <React.Fragment key={breeder._id}>
          <BreederCard breeder={breeder} organization={org} />
        </React.Fragment>
      ))}
    </>
  );
}
