import * as React from "react";
import i18next from "i18next";

import { DogCard } from "elements/DogCard";

import { Typography, Divider } from "@mui/material";

export default function ParentsComp({ sire, dam, organization }) {
  return (
    <>
      <Divider textAlign="left" sx={{ mb: 2 }}>
        <Typography variant="h3">{i18next.t("sire")}</Typography>
      </Divider>
      <DogCard dog={sire} organization={organization} />
      <Divider textAlign="left" sx={{ mb: 2 }}>
        <Typography variant="h3">{i18next.t("dam")}</Typography>
      </Divider>
      <DogCard dog={dam} organization={organization}/>
    </>
  );
}
