import React from "react";
import { useTranslation } from "react-i18next";

import {
  List,
  ListItem,
  ListItemText,
  Divider,
  Paper,
  Typography,
  TableBody,
  Button,
  Table,
  TableRow,
  TableCell,
  TableContainer,
  IconButton,
} from "@mui/material";

import LaunchIcon from "@mui/icons-material/Launch";

import { getBadge, getCountryByCode } from "constants/helpers";
import { useRecoilValue } from "recoil";
import { breederState } from "recoil/publicStates";

export default function LitterBreederInfoBox({ breeder, organization }) {
  const { t, i18n } = useTranslation();
  const language = i18n.language;
  const breederBadge = getBadge(breeder);
  const errorNoPrem = t("noPermissionToDisplayInformation");

  const breederData = useRecoilValue(breederState(breeder._id));

  return (
    <>
      <Divider textAlign="left" sx={{ mb: 2, mt: 4 }}>
        <Typography variant="h3">{t("breeder")}</Typography>{" "}
      </Divider>
      <Paper>
        <List>
          <ListItem
            secondaryAction={
              <>
                <Button
                  href={`/${organization.prefix}/breeder/${breeder.breederId}`}
                  endIcon={<LaunchIcon />}
                  size="small"
                  sx={{ display: { xs: "none", sm: "flex" } }}
                >
                  {t("breedersProfile")}
                </Button>
                <IconButton
                  color="primary"
                  href={`/${organization.prefix}/breeder/${breeder.breederId}`}
                  size="small"
                  sx={{ display: { xs: "flex", sm: "none" } }}
                >
                  <LaunchIcon />
                </IconButton>
              </>
            }
          >
            <ListItemText
              primary={
                <Typography variant="h5">
                  {breeder?.breedingPrefix || t("noBreederPrefix")}{" "}
                  {/* {breederBadge} */}
                </Typography>
              }
              secondary={<>ID: {breeder?.breederId}</>}
            />
          </ListItem>
          <Divider />
        </List>
        <TableContainer>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell variant="head">{t("name")}</TableCell>
                <TableCell>{breeder?.name || errorNoPrem}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell variant="head">{t("location")}</TableCell>
                <TableCell>
                  {breeder?.city}
                  {breeder?.country && breeder?.city ? `, ` : null}
                  {breeder?.country &&
                    getCountryByCode(breeder?.country, language)}
                  {!breeder?.country && !breeder?.city && errorNoPrem}
                </TableCell>
              </TableRow>
              { /* <TableRow>
                <TableCell variant="head">{t("certificates")}</TableCell>
                {breederData.certificates?.length > 0 ? (
                  breederData.certificates.map((cert, i) => (
                    <React.Fragment key={cert._id}>
                      <TableCell> {cert.certificate.name}</TableCell>
                    </React.Fragment>
                  ))
                ) : (
                  <TableCell colSpan={2}>{t("noCertificates")}</TableCell>
                )}
              </TableRow> */ }
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </>
  );
}
