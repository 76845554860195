import * as React from "react";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

import { getBadge } from "constants/helpers";

import { Typography, Grid, Divider } from "@mui/material";
import { VolunteerActivism } from "@mui/icons-material";

export default function BreederData({ userPerson }) {
  const { t } = useTranslation();
  const breederBadge = getBadge(userPerson);

  return (
    <>
      <Grid item xs={12}>
        <Divider textAlign="left" sx={{ mt: 3 }}>
          <Typography
            variant="h2"
            sx={{ display: "flex", alignItems: "flex-start" }}
          >
            <VolunteerActivism sx={{ mr: 1 }} /> {t("breederInformation")}
          </Typography>
        </Divider>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Typography variant="caption" display="block">
          {t("breederPrefix")}
        </Typography>

        <Typography variant="body">
          {userPerson?.breedingPrefix || t("noBreederPrefix")}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Typography variant="caption" display="block">
          {t("breederId")}
        </Typography>

        <Typography variant="body">
          {userPerson?.breederId || t("noBreederId")}
        </Typography>
      </Grid>
    </>
  );
}
